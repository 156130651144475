import { environment } from "src/environments/environment"

const _TEXT = {
  
  general: {
    error_value: 'ERROR',
    temperature: 'Temperature',
    humidity: 'Humidity',
    moisture: 'Moisture',

    interval: 'Interval',
    server_down: 'Server temporary down',
    forbidden: 'Forbidden',

    meters: 'Meters', 
    email: 'E-mail', 
    help: 'Help', 
    password: 'Password', 

    select_language: 'Select language', 

    first_name: 'First name', 
    last_name: 'Last name', 

    latest: 'Latest', 
    menu: 'Menu', 

    accept: 'Accept', 
    
    // time window
    realtime: 'Realtime', 
    history: 'History', 
    last: 'Last', 
    start_time: 'Start time', 
    end_time: 'End time', 
    method: 'Method', 

    // used in building sensor cards
    latest_temperature: 'Latest Temperature', 
    latest_humidity: 'Latest Humidity', 
    latest_emc: 'Latest Moisture',
    
    no_internet: 'No internet connection', 
    connecting: 'Connecting', 
    reconnecting: 'Reconnecting', 
    retry: 'Retry', 

    continue: 'Continue', 
    min: 'Min', 
    max: 'Max', 
    average: 'Average', 
    emc: 'Moisture', 
    sensor: 'Sensor', 
    serial: 'Serial', 
    alarm: 'Alarm', 
    saving: 'Saving..', 
    loading: 'Loading..', 
    ok: 'Ok',
    okay: 'Okay',
    no: 'No', 
    yes: 'Yes',
    cancel: 'Cancel', 
    create: 'Create', 
    devices: 'Devices', 
    alarms: 'Alarms', 
    building: 'Building', 
    buildings: 'Buildings', 
    checkout: 'Checkout', 
    add_device: 'Add device', 
    device_name: 'Device name', 
    crop: 'Crop', 
    crop_type: 'Crop type', 
    no_crop: 'No crop', 
    select_crop: 'Select Crop',
    settings: 'Settings', 
    label: 'Label', 
    time: 'Time', 
    comment: 'Comment', 

    battery: 'Battery', 
    signal: 'Signal',

    place_on_map: 'Place on map',
    activate_location: 'Activate location',

    // maybe belongs in settings
    is_required: '{{field}} is required', 
    no_logging: 'No logging', 
    logging: 'Logging',
    ambient_temperature: 'Ambient temperature',
    ambient_moisture: 'Ambient Moisture',
    alarm_threshold: 'Alarms threshold', 
    report_interval: 'Report interval', 
    select_reporting: 'Select reporting', 
    
    temperature_range: 'Temperature range',
    moisture_range: 'Moiture range',
    select_device: 'Select device',

    // measurement devices
    add_measurement: 'Add measurement', 
    edit_measurement: 'Edit measurement',
    measurement_map: 'Measurement map',
    take_photo: 'Take photo',
    
  },
  device: {
    loading_device: 'Loading device..',
    remove_device: 'Remove device',
    device_load_error: 'Failed to load device',
    no_subscription: "Device has no data subscription, checkout to activate device", 
  },
  menu: {
    add_new_device: 'Add new device',
    add_new_building: 'Add new building', 
    map_view: 'Map view',
    alarms: 'Alarms',
    billing: 'Billing', 
    refresh: 'Refresh', 
    about: 'About', 
    help: 'Help', 
  },
  profile: {
    send_telemetry_data_title: "Request telemetry data",
    send_telemetry_data_info: "Send telemetry report to e-mail",
    send_telemetry_data_success: "You will receive an e-mail within the next few minutes",
    send_telemetry_data_failed: "Failed to request telemetry report, please try again later",
    email_weekly_report: 'E-mail weekly report',
    show_ambient_as_humidity: 'Show ambient as humidity',
    advanced: 'Advanced',
    moisture_calibration: 'Moisture Calibration',
    activating: 'Activating Account...',
    activated: 'Account Activated', 
    activate_failed: 'failed to activate account', 
    reset_password: 'Reset Password', 
    new_password: 'New Password', 
    set_password: 'Set Password', 
    reset_failed: 'Failed to reset password, please try again later or contact support',
    reset_failed_title: 'Reset password failed', 
    reset_success_title: 'Reset password success', 
    reset_success: 'Password has been reset, you can now login with your new password', 
  
    my_profile: 'My Profile',
    email_notifications: 'E-mail Notifications', 
    change_password: 'Change Password',
    logout: 'Logout',
    login: 'Login', 
    register: 'Register', 
    not_a_member: 'Not a member yet', 
    sign_in: 'Sign-in',
    sign_up: 'Sign-up',
    already_registered: 'Already registered',
    delete_account: 'Delete Account', 
    current_password: 'Current Password', 
    change_password_failed: 'failed to change password', 
    
    delete_account_title: 'Request Delete Account',
    delete_account_confirm: 'Are you sure that you want to permanently delete your user-account and all data associated with it?', 
    delete_account_success: 'Your request have been received. You will receive a confirmation mail when your request for deletion have been processed. Please contact sales@supertech.dk for any question.', 
    delete_account_failed: 'Failed to send request. Please contact us directly on sales@supertech.dk', 

    forgot_password: 'Forgot Password', 
    forgot_password_help: 'Type your e-mail reset your password', 
    forgot_password_success: 'a reset-link has been sent to your e-mail. Check your mailbox.', 
    forgot_password_failed: 'Failed to send reset link',

    email_required: 'please provide your e-mail', 
    password_too_short: 'password must be at least {{length}} characters',
    first_name_required: 'set a first name', 
    last_name_required: 'set a last name', 
    tos_required: 'please accept terms of services', 

    login_failed: 'Failed to login', 
    activate_your_account: 'Activate your account', 
    account_not_activated_help: 'Check your e-mail to activate your account',
    resend_activation_link: 'Resend Activation Mail',
    send_activation_link_success: 'a activation link has been sent to {{email}}. Check your mailbox.',
    send_activation_link_failed: 'Failed to send activation link', 
  
    tos_agree: 'I agree to Terms & Conditions',
    tos_read: 'Read Terms & Conditions',
  },
  payment: {
    status: {
      paid: 'Paid',  
      not_paid: 'Not paid',
    },
    current_subscriptions: 'Current subscriptions',
    renews_on: 'Renews on {{date}}', 
    cancel_on: 'Cancel on {{date}}', 
    billed_every: 'Billed every', 
    manage_subscriptions: 'Manage subscriptions', 
    billing: 'Billing', 
    not_renewing_info: 'Will Cancel at end of term', 
    cancelled: 'Cancelled', 
    quantity_changed: 'Quantity is scheduled to update from {{quantity}} to {{scheduled}} at next renewal: {{date}}', 
  
    confirmed: 'Payment confirmed', 
    confirmed_info: 'An order confirmation has been sent to your email', 
    sub_total: 'Sub total', 
    total: 'Total', 

    unsubscribed_message: 'You have unsubscribed devices', 

    // MESSAGES
    activating: 'Activating...', 
    awaiting_payment: 'Awaiting payment', 
    trials_end_at: 'Trial ends {{date}}', 
    device_checkout_required: 'Device has no subscription, checkout to activate', 
    building_checkout_required: 'Building contains unsubscribed devices, checkout to activate',
    subscription_cancelled: 'subscription is cancelled', 
    cancel_scheduled: 'scheduled to cancel {{date}}', 

    // CHECKOUT
    checkout_failed: 'failed to checkout, please try again later or contact support', 
    billing_information: 'Billing Information', 
    billing_email: 'Billing E-mail', 
    country: 'Country', 
    select_country: 'Select Your Country', 
    iam_company: "I'm a Company", 
    company_name: 'Company Name', 
    vat_number: 'Vat Number', 
    select_payment_method: 'Select payment method', 
    checkout_notice: 'NOTE: claim all your devices before checkout to avoid multiple invoices', 
    accept_payment: 'Accept Payment', 
    choose_plan: 'Choose your plan', 
    per_unit_month: 'per unit a month', 

    card_method_title: 'Credit Card Payment', 
    card_method_info: 'get started immediatly', 
    bank_method_title: 'Bank Transfer Payment', 
    bank_method_info: 'only 3 years subscriptions are available', 

    checkout_redirect_header: 'Redirecting to secure checkout...',  
    checkout_redirect_info: 'If redirect is not working, please click the link below',  
    checkout_redirect_link: 'Open Checkout', 
  },
  settings: {
    use_building: 'Some settings are disabled while device is in a building. Use building-settings.', // 
    confirm_remove_device: 'Are you use you want to remove this device?', 
    confirm_remove_device_title: 'Confirm Remove Device', 
    confirm_remove_building_title: 'Confirm Remove Building', 
    confirm_remove_building: 'Are you use you want to remove this building?' 
  },
  building: {
    loading: 'Loading building...', 
    no_available_devices: 'no available devices', 
    temperature_sensor_map: 'Temperature Sensor Map', 
    assigned_devices: 'Assigned Devices', 
    moisture_sensor_map: 'Moisture Sensor Map', 
    humidity_sensor_map: 'Humidity Sensor Map', 
    create_building: 'Create Building', 
    building_name: 'Building Name', 
    select_sensors: 'Select cable sensors above to show history', 
    remove_device: 'Remove device from building', 
    remove_device_confirm: 'Are you sure you want to remove {{name}} from building ?', 
    edit_device_placements: 'Edit Device Placements', 
    add_device: 'Add Device To Building', 
    has_unsubscribed_devices: 'Building have unsubscribed devices, checkout to see building data', 
    has_no_devices: 'Building has no devices', 
    open_settings_to_add_devices: 'Open Settings to assign devices to building', 
    failed_to_load_building: 'failed to load building', 
    length: 'Building length', 
    width: 'Building width', 
    remove: 'Remove Building', 
    no_devices_assigned: 'No devices are assigned to building.', 
    add_device_help: 'Click Add Device to assign a device to this building', 
  },
  alarm: {
    clearing: 'Clearing Alarms...', 
    clear_all: 'Clear All', 
    clear: 'Clear Alarms', 
  },
  chart: {
    no_data: 'No data within the given timeframe',
    temperature_label: "Temp.", 
    moisture_label: "Moisture", 
    temperature_chart_name: 'Temperature History', 
    emc_chart_name: 'Moisture History', 
    humidity_chart_name: 'Humidity History', 
  },
  claim: {
    device_name: 'Device name', 
    secret_key: 'Secret key', 
    scan_qr: 'Scan QR code', 
    use_manual_code: "Use manual claim code", 
    claim_error: 'Failed to claim device', 
    not_valid_qr: 'not a valid device code', 
    no_qr_found: 'no qr code found', 
    claim_device: "Create Device", 
    claim_cpro_help: "1. Turn on device \
2. Navigate to Settings \
3. Select register \
4. Scan QR Code",
    claim_css_help: 'Locate your QR code on the back of the device'
  },
  permission: {
    camera_denied: "Access denied to Camera", 
    notifications_denied: "Access denied to Notifications", 
    location_denied: "Access denied to location", 
    unable_to_set_permission: "unable to update permission settings",
    open_application_settings: 'Open Application Settings', 
    update_permissions_help: 'to update permissions and click retry', 
    browser_denied_help: 'Please check your browser site settings and click retry', 
    allow_access: 'Allow'
  },
  signal: {
    unknown: 'Unknown',
    no_signal: 'No Signal', 
    marginal: 'Marginal', 
    ok: 'OK', 
    good: 'Good',
    excellent: 'Excellent', 
  },
  ble: {
    scanning: 'Scanning for Devices',
    sync_help: '1. Turn on device \
2. Select Sync',
    saving_measurements: "Saving Measurements",
    unknown_device: "Unknown device, please claim your device first",
    claim_device: "Claim Device",
    pair_device: "Pair Device",
    allow: "Allow",
    pairing: "Pairing",
    measuring: "Measuring",
    syncing: "Syncing",
    cancel_operation: "Cancel",
    complete: "Completed",
    close: "Close",
    measurements_available: "New measurement available",
    open_device_page: "Open Device Page",
    error: "ERROR",
    no_gps_location: "Please make sure GPS is available and accessible",

    // issues
    not_available_title: "Bluetooth not enabled",
    not_available_help: 'please enable bluetooth on your device',
    not_allowed_title: 'Bluetooth not allowed',
    not_allowed_help: 'Farmer App needs access to bluetooth',

    not_paired_title: 'Device not paired',
    not_paired_help: "",

    gps_not_allowed_title: 'Access to GPS',
    gps_not_allowed_help: 'Farmer App needs access to GPS to track measurement',
    gps_bg_not_allowed_title: 'Access to GPS in Background',
    gps_bg_not_allowed_help: 'Farmer App needs access to GPS to track measurement while in background',
    battery_optimized_title: 'Battery Optimized',
    battery_optimized_help: 'The app will lose connection to bluetooth when battery is optimized while screen is locked',
    disable_battery_optimization: 'Disable Battery Optimization'
  }
}

let _DEFAULT_TRANSLATIONS = {}
let _ALL_TRANSLATIONS: Record<string, string> = {}

function _UpdateTextKey (root: object, path: string[]) {
  for (var k in root) {
    let value = root[k]
    let key = [...path, k].join('.')
    
    
    if (typeof value == 'string') {
      _ALL_TRANSLATIONS[key] = value
      
      if (environment.key != 'live') {
        value = '[' + value + ']'
      }
      _DEFAULT_TRANSLATIONS[key] = value
      root[k] = key
    } else {
      _UpdateTextKey(value, [...path, k])
    }
  }
}
_UpdateTextKey(_TEXT, ['text'])
export const TEXT = _TEXT
export const DEFAULT_TRANSLATIONS = _DEFAULT_TRANSLATIONS

//console.log(DEFAULT_TRANSLATIONS)

export function validateTranslations (lang: string, translations: Record<string, string>) {
  let issues = []
  for (var k in translations) {
    if (k.startsWith('ag') || k.startsWith('email')) continue
    if (!_ALL_TRANSLATIONS[k]) {
      issues.push({lang: lang, key: k, scope: 'app'})
    }
  }
  for (var k in _ALL_TRANSLATIONS) {
    if (!translations[k]) {
      issues.push({lang: lang, key: k, scope: 'server'})
    }
  }
  return issues
}
