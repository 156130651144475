//import { AndroidPermissions } from "@ionic-native/android-permissions/ngx";
//import { Geolocation, PositionError } from "@ionic-native/geolocation/ngx";
//import { LocationAccuracy } from '@ionic-native/location-accuracy';
//import { OpenNativeSettings } from "@awesome-cordova-plugins/open-native-settings/ngx";

import { BackgroundGeolocation, BackgroundGeolocationConfig,
  BackgroundGeolocationResponse, BackgroundGeolocationEvents,
  BackgroundGeolocationCurrentPositionConfig } from '@awesome-cordova-plugins/background-geolocation/ngx';

import { Injectable } from "@angular/core";
import { Capacitor } from "@capacitor/core";
import { Platform, ToastController } from "@ionic/angular";
import { BehaviorSubject } from "rxjs";
import { onResume } from "../util";
import { AppState } from "../state";
import { Store } from "@ngrx/store";
import { AttributeService } from "./entity.service";
import { AttributeScope } from "../models/telemetry.model";

import * as Geo from '@capacitor/geolocation'
import { PermissionService } from './permissions.service';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';

export interface ILocation {
  coords?: {latitude: number, longitude: number, accuracy: number}, error?: number
  type?: string, ts?: number, date?: string
}

// GeolocationPosition is not available: https://stackoverflow.com/questions/65916073/angular-11-why-cant-the-compiler-find-geolocationposition-during-compiling
function parseLoc (loc: any, type: string): ILocation {
  let c = loc.coords
  return {
    coords: {
      latitude: c.latitude, longitude: c.longitude,
      accuracy: c.accuracy
    },
    type: type,
    ts: loc.timestamp, date: new Date(loc.timestamp).toString()
  }
}



@Injectable()
export class LocationService {
  onLocation = new BehaviorSubject<ILocation>({})
  userId

  // TODO: ..
  geoOptions: BackgroundGeolocationCurrentPositionConfig = {
    enableHighAccuracy: true,
    timeout: 3500,
    maximumAge: 20000,
  };
  bgConfig: BackgroundGeolocationConfig = {
    desiredAccuracy: 10,
    stationaryRadius: 5,
    distanceFilter: 5,
    debug: false, //  enable this hear sounds for background-geolocation life-cycle.
    stopOnTerminate: true, // enable this to clear background location settings when the app terminates
  };

  isInBackground: boolean

  permissions = {
    gps: false, gps_background: false
  }

  private async requestLocationPermissions () {
    if (!Capacitor.isNativePlatform()) {
      let p = new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          pos => {
            resolve('granted')
          },
          e => {
            resolve('denied')
          }
        )
      });
      await p
      return
    } else {
      let available = await this.diagnostic.isLocationAvailable()
      let enabled = await this.diagnostic.isLocationEnabled()
      if (!enabled) {
        return
      } else if (!available) {
        return
      } 
      await this.diagnostic.requestLocationAuthorization(
        this.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE, 
        this.diagnostic.locationMode.HIGH_ACCURACY
      )
    }
  }
  private async openLocationSettings () {
    let enabled = await this.diagnostic.isLocationEnabled()
    if (!enabled) {
      await this.permService.openLocationSettings()
    } else {
      await this.permService.openPermissionSettings()
    }
  }
  private async checkLocationPermissions () {

    if (!Capacitor.isNativePlatform()) {
      if (navigator?.permissions?.query) {
        let state = await navigator.permissions.query({
          name: 'geolocation'
        })
        return state.state == 'granted'
      }
      return false
    } else {
      let available = await this.diagnostic.isLocationAvailable()
      let enabled = await this.diagnostic.isLocationEnabled()
      if (!enabled || !available) {
        return false
      }
      let status = await this.diagnostic.getLocationAuthorizationStatus()
      return (
        status == this.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE || 
        status == this.diagnostic.permissionStatus.GRANTED
      )
    }
  }

  constructor (
    //private permissions: AndroidPermissions,
    //private nativeSettings: OpenNativeSettings,
    private backgroundGeolocation: BackgroundGeolocation,
    private store: Store<AppState>,
    private attributes: AttributeService,
    private permService: PermissionService,
    private platform: Platform,
    private diagnostic: Diagnostic
  ) {
    this.permService.registerPermission('GPS', {
      requestPermission: async () => await this.requestLocationPermissions(),
      checkPermission: async () => await this.checkLocationPermissions(),
      openSettings: async () => await this.openLocationSettings()
    })

    this.isInBackground = false
    
    this.platform.pause.subscribe(() => {
      this.isInBackground = true;
    });
    this.platform.resume.subscribe(() => {
      this.isInBackground = false;
    });

    this.store.select(state => state.auth?.userDetails?.id).subscribe(userId => {
      if (userId != this.userId) {
        this.userId = userId
        this.logLocation()
      }
    })
    this.onLocation.subscribe(x => {
      if (!x) return
      let userId = this.userId
      if (!x.coords?.latitude || !x.coords?.longitude || !userId) return
      this.attributes.saveEntityAttributes(
        userId, AttributeScope.SERVER_SCOPE, [
          {key: 'latitude', value: x.coords.latitude},
          {key: 'longitude', value: x.coords.longitude}
        ]
      ).toPromise()
    })
    this.onLocation.subscribe(x => {
      if (x?.coords) {
        localStorage.setItem('__LOCATION__', JSON.stringify(x))
      }
    })
    try {
      let data = localStorage.getItem('__LOCATION__')
      if (data) {
        let loc = JSON.parse(data)
        this.onLocation.next(loc)
      }
    } catch (err) {
      console.error('failed to load inital location')
    }

    this.permService.state.subscribe(x => {
      let justReceivedPermission = x.GPS.enabled && x.GPS.enabled != this.permissions.gps
      this.permissions = {
        gps: x.GPS.enabled, gps_background: x.GPS_BACKGROUND.enabled
      }
      if (justReceivedPermission) {
        this.getCurrentLocation()
      }
    })

    this.diagnostic.registerLocationStateChangeHandler(e => {
      this.permService.checkPermissions()
    })

    this.autoLogLocation()
    onResume().subscribe(x => this.logLocation())
  }

  async logLocation () {
    await this.getCurrentLocation()
  }

  async autoLogLocation () {
    let aday = 1000 * 60 * 60 * 24
    try {
      await this.logLocation()
    } catch (err) {
      console.error(err)
    }
    setTimeout(() => this.autoLogLocation(), aday)
  }

  get current () { return this.onLocation.getValue() }
  
  userErrorMessage () {
    if (!this.current.error) return ''
    else if (this.current.error == 1) return "gps location is blocked in app"
    return "unable to get gps location"
  }

  async requestWebPermission () {
    let state = await this.getWebPermissionState()
    if (state == 'granted' || state == 'prompt') {
      return true
    } else {
      return false
    }
  }

  async getWebPermissionState (): Promise<string> {
    if (navigator?.permissions?.query) {
      let state = await navigator.permissions.query({
        name: 'geolocation'
      })
      return state.state
    } else if (navigator.geolocation) {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          pos => {
            this.onLocation.next(parseLoc(pos, 'BROWSER'))
            resolve('granted')
            
          },
          e => {
            resolve('denied')
          }
        );
      })
    } else {
      return 'denied'
    }
  }
  
  emitError () {
    //if (err?.error == 1)
    //this.onLocation.next({error: 1})
  }
  /*emitLocation (loc: ILocation) {
    this.onLocation.next({
      coords: {latitude: coord.latitude, longitude: coord.longitude, accuracy: 1}
    })
  }*/

  async getCurrentLocationBackground () {
    if (!this.permissions.gps_background) return null

    let resp = await this.backgroundGeolocation.getCurrentLocation(this.geoOptions)
    let result: ILocation = {
      coords: {accuracy: resp.accuracy, latitude: resp.latitude, longitude: resp.longitude}
    }
    return result
  }

  async getCurrentLocation (force: boolean=false): Promise<ILocation | null> {
    // with force we'll try get the location anyways, permission might be ask-every-time
    if (!this.permissions.gps && !force) {
      console.warn('location access denied, cannot get current position')
      return null
    }
    
    let pos: ILocation | null = null
    try {
      if (Capacitor.isNativePlatform()) {
        pos = await this.getMobileLocation(force)        
      } else {
        pos = await this.getBrowserLocation()
      }
    } catch (err) {
      console.error(err)
      this.emitError()
      return null
    }
    this.onLocation.next(pos)
    return pos
  }

  async getMobileLocation (force: boolean = false) {
    if (this.isInBackground) {
      if (!force && !this.permissions.gps_background) {
        throw new Error('no gps background permission')
      }
      return await this.getCurrentLocationBackground()
    }
    if (!force && !this.permissions.gps) {
      throw new Error('no gps permission')
    }
    let pos = await Geo.Geolocation.getCurrentPosition()
    console.log('got location:', pos)
    return parseLoc(pos, 'BROWSER')
  }

  private async getBrowserLocation () {
    
    return new Promise<ILocation>((_resolve, _reject) => {
      if (window.navigator.geolocation) {
        window.navigator.geolocation.getCurrentPosition(
          (position) => {
            _resolve(parseLoc(position, 'BROWSER'))
          },
          (err) => {
            console.error('gps error', err)
            _resolve({error: err.code, type:'BROWSER'})
            //_reject('Geolocation: ' + err.message)
          }, { enableHighAccuracy: true, timeout: 8000, maximumAge: 0}
        );
      } else {
        _reject("Your browser doesn't support geolocation")
      }
    })
  }

  /*async checkPermissions () {
    if (Capacitor.isNativePlatform()) {
      let p = await Geo.Geolocation.checkPermissions()
      return p.location
    } else {
      let state = await this.getWebPermissionState()
      return state
    }
  }*/

  // https://enappd.com/blog/ionic-5-complete-guide-on-geolocation/141/#15f0
  /*async activateGPS () {
    if (Capacitor.isNativePlatform()) {
      let r = await Geo.Geolocation.requestPermissions()
      return r.location == 'granted'
    } else {
      return await this.requestWebPermission()
    }
  }*/
  
}
