import { Capacitor } from "@capacitor/core"
import { Platform, ToastController } from "@ionic/angular"
import { TranslateService } from "@ngx-translate/core"
import { BehaviorSubject, map } from "rxjs"
import { TEXT } from "../texts"
import { Component, EventEmitter, Injectable, Input, NgZone, Output } from "@angular/core"

import { NativeSettings, AndroidSettings, IOSSettings } from 'capacitor-native-settings';
import { onAuthenticationChange, onResume } from "../util"
import { Store } from "@ngrx/store"
import { AppState } from "../state"


type PERMISSION_TYPE = 
  'GPS' | 'NOTIFICATION' | 'GPS_BACKGROUND' | 
  'BATTERY_OPTIMIZED' | 'BLUETOOTH'

export interface IPermissionState {
  type: PERMISSION_TYPE
  requested: boolean
  checked: boolean
  enabled: boolean
}

export interface IPermissionHandler {
  requestPermission: () => Promise<void>
  checkPermission: () => Promise<boolean>
  openSettings: () => void
}
  
function defaultPermissionState (type: PERMISSION_TYPE): IPermissionState {
  return {enabled: false, requested: false, type: type, checked: false}
}

@Injectable()
export class PermissionService {
  state: BehaviorSubject<Record<string, IPermissionState>>
  text = TEXT

  handlers: Record<string, IPermissionHandler>
  isInBackground = false
  status: 'checking' | 'requesting' | 'ready' = 'ready'

  constructor (
    private toast: ToastController,
    private translate: TranslateService,
    private ngZone: NgZone,
    private store: Store<AppState>,
    private platform: Platform
  ) {
    
    this.handlers = {}
    
    this.state = new BehaviorSubject({
      'BATTERY_OPTIMIZED': defaultPermissionState('BATTERY_OPTIMIZED'),
      'BLUETOOTH': defaultPermissionState('BLUETOOTH'),
      'GPS': defaultPermissionState('GPS'),
      'GPS_BACKGROUND': defaultPermissionState('GPS_BACKGROUND'),
      'NOTIFICATION': defaultPermissionState('NOTIFICATION')
    })
    

    onAuthenticationChange(this.store).subscribe(x => {
      this.checkPermissions()
    })
    onResume().subscribe(async (resumed) => {
      this.reset()
    })

    this.reset()
  }

  registerPermission (type: PERMISSION_TYPE, handler: IPermissionHandler) {
    if (this.handlers[type]) {
      throw new Error('permission already registered: ' + type)
    }
    console.log('register permission type', type)
    this.handlers[type] = handler
    //this.state.next({...this.state.value, [type]: defaultPermissionState(type)})
    this.checkPermission(type)
  }

  reset () {
    let state = {...this.state.value}
    for (let k in state) {
      let p = state[k]
      p.checked = false
    }
    this.state.next(state)
    this.ngZone.run(() => {
      this.checkPermissions()
    })
  }

  async checkPermissions () {
    console.log('check all permissions')
    //let hnd: PERMISSION_TYPE
    for (let hnd in this.handlers) {
      try {
        // TODO: ..
        await this.checkPermission(hnd as PERMISSION_TYPE)
      } catch (err) {
        console.error(err)
      }
    }
  }

  async checkPermission (type: PERMISSION_TYPE) {
    let handler = this.handlers[type]
    let allowed = false
    try {
      allowed = await handler.checkPermission()
    } catch (err) {
      console.error('failed to check permission', type)
      console.error(err)
    }
    this.updateState(type, {checked: true, enabled: allowed})
    return allowed
  }

  async requestPermission (type: PERMISSION_TYPE, force=false) {
    let handler = this.handlers[type]
    let state = this.state.value[type]
    let allowed = await this.checkPermission(type)
    if (allowed) {
      return console.warn(type, 'already allowed no need to request')
    }
    
    if (state.requested && !force) {
      return console.warn('permission have already been request', type)
    }
    try {
      await handler.requestPermission()
    } catch (err) {
      console.error(err)
    }
    allowed = await this.checkPermission(type)
    console.log('requsted permission', type, allowed)
    if (!allowed && force) {
      await this.manualRequest(type)
    }
    this.updateState(type, {requested: true})
    
  }

  updateState (type: PERMISSION_TYPE, values: Partial<IPermissionState>) {
    this.state.next({
      ...this.state.value,
      [type]: {...this.state.value[type], ...values}
    })
  }
  
  async openBluetoothSettings () {
    // https://www.npmjs.com/package/capacitor-native-settings
    if (Capacitor.isNativePlatform()) {
      await NativeSettings.open({
        optionAndroid: AndroidSettings.ApplicationDetails,
        optionIOS: IOSSettings.Bluetooth
      })
    }
  }

  async openPermissionSettings () {
    // https://www.npmjs.com/package/capacitor-native-settings
    if (Capacitor.isNativePlatform()) {
      await NativeSettings.open({
        optionAndroid: AndroidSettings.ApplicationDetails,
        optionIOS: IOSSettings.App
      })
    }
  }
  async openLocationSettings () {
    if (Capacitor.isNativePlatform()) {
      await NativeSettings.open({
        optionAndroid: AndroidSettings.Location,
        optionIOS: IOSSettings.LocationServices
      })
    }
  }
  async openBatteryOptimizationSettings () {
    if (Capacitor.isNativePlatform()) {
      // NOTE: AndroidSettings.BatteryOptimization does not work correctly
      await NativeSettings.open({
        optionAndroid: AndroidSettings.ApplicationDetails,
        optionIOS: IOSSettings.App
      })
    }
  }

  
  async manualRequest (type: PERMISSION_TYPE) {
    let message = ''
    if (type == 'GPS') {
      message = this.text.permission.location_denied
    } else if (type == 'NOTIFICATION') {
      message = this.text.permission.notifications_denied
    } else {
      message = this.text.permission.unable_to_set_permission
    }

    let buttons = []
    // TODO: this might get called before translation service is loaded
    setTimeout(async () => {
      if (Capacitor.isNativePlatform()) {
        buttons.push({
          side: 'end',
          text: this.translate.instant(this.text.permission.open_application_settings),
          handler: () => {
            let handler = this.handlers[type]
            if (handler) {
              handler.openSettings()
            } else {
              this.openPermissionSettings()
            }
            //this.activateGPS()
          }
        })
      } else {
        buttons.push({
          side: 'end',
          text: this.translate.instant(this.text.general.ok),
          role: 'cancel',
        })
      }
      
    
      let toast = await this.toast.create({
        message: await this.translate.instant(message),
        cssClass: 'notification-toast',
        duration: 5000, 
        position: 'top',
        buttons: buttons
      });
      await toast.present();
    }, 2000)
    //await toast.present();
  }
  

}



@Component({
  selector: 'permission-error',
  template: `
  <div>
    <div style="font-weight: bold;font-size: 14px;">{{message}}</div>
    <!--<div style="font-size: 14px;" *ngIf="isNative"><span class="settings-link" (click)="openAppSettings()">{{text.permission.open_application_settings|translate}}</span>{{text.permission.update_permissions_help|translate}}</div>
    <div style="font-size: 14px;" *ngIf="!isNative">{{text.permission.browser_denied_help|translate}}</div>
    -->
    <div style="font-size: 14px;">{{helpText}}</div>
    <button style="margin: 10px 0;" class="small-button" (click)="requestPermission()" [translate]="text.permission.allow_access"></button>
  </div>
  `,
  styles: [`
.settings-link {
  color: rgba(237, 109, 5);
  text-decoration: underline;
}
  `]
})
export class PermissionErrorMessage {
  @Input() message: string
  @Input() type: PERMISSION_TYPE
  @Input() helpText = ''
  @Output() onRetry = new EventEmitter()
  isNative = Capacitor.isNativePlatform()
  text = TEXT
  hasPermission = null

  constructor (
    private permissions: PermissionService,
    private zone: NgZone
  ) {}

  requestPermission () {
    this.zone.run(async () => {
      await this.permissions.requestPermission(this.type, true)
    })
  }
}

