import { Component, ElementRef, EventEmitter, Injectable, Input, NgZone, Output, Pipe, PipeTransform, ViewChild } from "@angular/core";
import { CropLabel, formatSinceNow, Index, LoadingState, onResume, TIME_UNIT } from "../util";
import { agTypes } from "../ag-types";
import { UnitService } from "../services/unit";

import qrcode from 'qrcode-generator'
import { Subscription } from "rxjs";
import { map, tap } from 'rxjs/operators'
import { ConnectionService, IConnectState } from "../services/device.service";

import { TEXT } from "../texts";
import { TranslateService } from "@ngx-translate/core";
import { CROP_TYPE, ICountry } from "../constant";
import { AppService } from "../app.component";
import { IonModal } from "@ionic/angular";
import { Item } from "./basic/typeahead";
import { ProfileService } from "./login-page/profile-page";
import moment from "moment-es6";



@Component({
  selector: 'select-country',
  template: `
<ion-item (click)="openModal()" id="select-country">
  <ion-label position="stacked">{{text.payment.country | translate}}</ion-label>
  <div style="margin: 10px 0 10px 0" >
    <span *ngIf="selectedCountry?.country">{{selectedCountry?.country}}</span>
    <span *ngIf="!selectedCountry?.country">{{text.payment.select_country | translate}}</span>
  </div>
</ion-item>
<ion-modal trigger="select-country" #modal>
  <ng-template>
    <app-typeahead
      class="ion-page"
      title="{{text.payment.select_country | translate}}"
      [items]="items"
      [selectedItems]=""
      (selectionChange)="onSelectionChanged($event)"
      (selectionCancel)="modal.dismiss()"
    ></app-typeahead>
  </ng-template>
</ion-modal>
  `
})
export class CountrySelect {
  @ViewChild('modal', { static: true }) modal!: IonModal;
  @Input('options') options: ICountry[]
  @Input('value') value: string
  @Output('onChange') onChange: EventEmitter<ICountry> = new EventEmitter<ICountry>()

  text = TEXT
  items: Item[] = []
  selectedCountry: ICountry

  openModal () {
    this.modal.present()
  }

  refresh () {
    this.items = this.options.map(x => {
      return {text: x.country, value: x.code, searchString: x.country.toLowerCase()}
    })
    this.selectedCountry = this.options.find(x => x.code == this.value)
  }

  ngOnInit () {
    this.refresh()
  }
  ngOnChange () {
    this.refresh()
  }
  
  onSelectionChanged(countryCode: string) {
    let country = this.options.find(x => x.code == countryCode)
    if (!country) return
    this.value = country.code
    this.selectedCountry = country
    this.onChange.emit(country)
    this.modal.dismiss()
  }
}

@Component({
  selector: 'qr-code',
  template: `
  <!--<button (click)="makeQr()">Make</button>-->
  <div>{{value}}</div>
  <div #container></div>
`
})
export class QrCodeGenerator {
  @Input() value: string
  @ViewChild('container') container: ElementRef<HTMLElement>

  makeQr () {
    if (!this.container) return
    if (!this.value) return this.container.nativeElement.innerHTML = '<div>no value</div>'
    var typeNumber = 4 as any;
    var errorCorrectionLevel = 'L' as any;
    var qr = qrcode(typeNumber, errorCorrectionLevel);
    qr.addData(this.value);
    qr.make();
    this.container.nativeElement.innerHTML = qr.createImgTag(4);
  }
  ngAfterViewInit () {
    this.makeQr()
  }
  ngOnChanges () {
    this.makeQr()
  }
}



@Component({
  selector: 'ag-connection-dialog',
  styles: [`

.container {
  width: 100%; height: 100%;
  pointer-events: fill;
  background: rgba(0.1,0.1,0.1,0.3);
}

.loader-container {
  position: absolute;
  left: 50%;
  top: 50%;
  background: white;
  box-shadow: 0 0 10px #6c6c6c;
  border: 1px solid lightgray;
  padding: 10px;
  transform: translate(-50%, -50%);
}
.loader-spinner {
  display: flex;
  margin-left: auto;
  margin-right: auto;
}
.api-connection {
  bottom: 10px;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  background: #5a5a5aeb;
  padding: 5px 10px;
  border-radius: 5px;
  color: #f6f6f6;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
}
  `],
  template: `
  <div class="container" *ngIf="show">
    <div class="loader-container" >
      <span *ngIf="!state.online">
        <div class="loader-message">{{text.general.no_internet|translate}}!</div>
      </span>
      <span *ngIf="state.connecting">
        <div class="loader-message">{{text.general.connecting|translate}}</div>
        <ion-spinner class="loader-spinner" name="dots"></ion-spinner>
      </span>
    </div>
  </div>
  <div class="api-connection" *ngIf="showConnecting">
  <ion-spinner class="loader-spinner" name="dots"></ion-spinner>
    {{text.general.reconnecting|translate}} 
  </div>
  `
  
})
export class AgConnectionDialog {

  constructor (
    private connection: ConnectionService,
    private ngZone: NgZone
  ) {}
  
  text = TEXT
  state: IConnectState = {
    online: false, connecting: false, connected: false, recovered: false
  }
  show = false
  showConnecting = false
  _subscriptions: Subscription[] = []
  listen (s: Subscription) { this._subscriptions.push(s) }
  ngOnDestroy () { this._subscriptions.map(s => s.unsubscribe()); this._subscriptions = [] }
  
  ngOnInit () {
    this.listen(this.connection.subscribe((state) => {
      this.updateVisibility()
    }))
    onResume().subscribe(() => this.updateVisibility())
  }

  updateVisibility () {
    this.ngZone.run(() => {
      let state = this.connection.connection.value
      this.state = state
      this.show = !state.online // || state.connecting
      this.showConnecting = state.connecting && !state.connected
    })
  }
}

@Component({
  selector: 'battery-level',
  template: `<svg 
  xmlns="http://www.w3.org/2000/svg" 
  id="color" enable-background="new 0 0 24 24" 
  viewBox="0 0 24 24">
    <path d="m19.25 19h-16.5c-1.516 0-2.75-1.233-2.75-2.75v-9.5c0-1.517 1.234-2.75 2.75-2.75h16.5c1.516 0 2.75 1.233 2.75 2.75v.25h.241c.965 0 1.75.785 1.75 1.75v5.5c0 .965-.785 1.75-1.75 1.75h-.241v.25c0 1.517-1.234 2.75-2.75 2.75z" fill="#607d8b"/>
    <rect rx="1" fill="lightgreen" x="2" y="6" [attr.width]="level" height="11" ></rect>
    <path d="m23.991 11.5h-1.5v2.75c0 .138-.112.25-.25.25h-.991c-.414 0-.75.336-.75.75v1c0 .689-.561 1.25-1.25 1.25h-16.5c-.689 0-1.25-.561-1.25-1.25v-4.75h-1.5v4.75c0 1.517 1.234 2.75 2.75 2.75h16.5c1.516 0 2.75-1.233 2.75-2.75v-.25h.241c.965 0 1.75-.785 1.75-1.75z" fill="#546d79"/>
    <path fill="rgb(0 0 0 / 10%)" d="m22.491 11.5h-20.991v4.75c0 .689.561 1.25 1.25 1.25h16.5c.689 0 1.25-.561 1.25-1.25v-1c0-.414.336-.75.75-.75h.991c.138 0 .25-.112.25-.25z"/>
    </svg>`
})
export class BatteryLevelComponent {
  @Input() percent: number

  get level () { 
    let result = 18 / 100 * Math.max(Math.min(this.percent, 100), 0)
    if (isNaN(result)) return 0
    return result
   }
}


export interface ISignalValue {
  value: number, scale: number, name: string
}
export function getSignalValue (x: number) {
  if (x == null) return {value: -1, scale: -1, name: TEXT.signal.unknown}
  let s = x + 2
  if (s < 2) {
    return {value: s, scale: 0, name: TEXT.signal.no_signal}
  } else if (s >= 2 && s <= 9) {
    return {value: s, scale: 1, name: TEXT.signal.marginal}
  } else if (s >= 10 && s <= 14) {
    return {value: s, scale: 2, name: TEXT.signal.ok}
  } else if (s >= 15 && s <= 19) {
    return {value: s, scale: 3, name: TEXT.signal.good}
  } else if (s >= 20) {
    return {value: s, scale: 4, name: TEXT.signal.excellent}
  } else {
    return {value: -1, scale: -1, name: TEXT.signal.unknown}
  }
}

@Component({
  selector: 'signal-strength',
  template: `<svg version="1.1" 
  id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
  viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<g *ngFor="let level of signalLevels; let i = index">
 <rect [attr.fill]="" [attr.x]="i * 100" [attr.y]="500 - ((i+1)*100)" [attr.height]="(i+1)*100" 
  style="fill:{{level ? '#25AE88' : 'rgb(220 220 220)'}};" width="71.86"/>
</g>
</svg>`
})
export class SignalStrengthComponent {
  @Input() value: ISignalValue

  

  get signalLevels () {
    let result = [0,0,0,0,0]
    return result.map((_,i) => {
      return i <= this.value.scale ? 1 : 0
      //let v = 100 / result.length * i
      //return v <= this.percent ? 1 : 0
    })
  }
}

@Component({
  selector: 'ag-map-marker',
  template: `
<svg #container xmlns="http://www.w3.org/2000/svg" version="1.1" id="svg4408" x="0" y="0" 
  viewBox="0 0 150 150" xml:space="preserve">
  <style>.st2{fill:{{color}} }.st4{fill:#333173}</style>
  <g id="layer1">
    <g id="path6881-3-5-5-1-8-4-4-7-8" transform="translate(-146.438 -276.028)" opacity=".892">
      <radialGradient id="SVGID_1_" cx="2190.864" cy="2461.432" r="49.901" gradientTransform="matrix(.6793 .0076 -.509 .5612 9.937 -1002.532)" gradientUnits="userSpaceOnUse">
        <stop offset="0"/><stop offset="1" stop-opacity=".188"/>
      </radialGradient>
      <path d="M285.6 388.5c10.3-12.4 4.4-22.4-14.4-22.4-18.9 0-42.4 10-53.9 22.4-16.8 18 .4 23.5-.2 35-.1 1.8 3.9 1.8 7 0 19.8-11.5 46.5-17 61.5-35" fill="url(#SVGID_1_)"/>      
    </g>
    <path id="path6881-3-5-5-1-8-4-4" class="st2" d="M124.7 69.1c-.9-27.5-22.3-49.8-49.8-49.8s-49 22.3-49.8 49.8c-1.3 40.1 30.7 52.2 44.7 78 2.2 4 8 4 10.1 0 14.1-25.8 46.1-37.9 44.8-78"/>
  </g>
  <g id="g4928">
    <circle id="path4978" class="st2" cx="74.9" cy="69.1" r="49.9"/>
    <g id="g4915">
      <path id="path6883-2-3-5-2-4-9-4-9" d="M74.8 106.4c-20.6 0-37.4-16.7-37.4-37.4 0-20.6 16.7-37.4 37.4-37.4 20.6 0 37.4 16.7 37.4 37.4s-16.7 37.4-37.4 37.4" fill="#fff"/>
    </g>
  </g>
  <foreignObject x="35" y="28" width="80" height="80">
   <ng-content></ng-content>>
  </foreignObject>
  <!--<image x="35" y="28" width="80" height="80" [attr.href]="icon" />-->
  
</svg>


`
})
export class MapMarker {
  @Input() name: string
  @Input() color = '#514f9d'
  @Output() onURL = new EventEmitter<string>()

  @ViewChild('container') container: ElementRef<SVGElement>

  constructor (private assets: AssetService) {}
  ngAfterViewInit () {
    let img = svg2img(this.container.nativeElement)
    this.assets.register(this.name, img)
  }
}


function svg2img(svg){
  var xml = new XMLSerializer().serializeToString(svg);
  var svg64 = btoa(xml); 
  var b64start = 'data:image/svg+xml;base64,';
  var image64 = b64start + svg64;
  return image64;
};

@Injectable()
export class AssetService {
  assets: Index<string> = {}

  register (name: string, url: string) {
    this.assets[name] = url
  }

  markerIcon (type: string, alarm: boolean) {
    return this.assets[type] || this.assets['default_marker']
  }
}


@Component({
  selector: 'ag-test-components',
  template: `
<ag-map-marker icon="/assets/svg/building_icon_filled.svg"></ag-map-marker>
  `
})
export class TestComponents {}


@Component({
  selector: 'ag-loading',
  template: `
    <div class="loader-container" [class]="{blocked: !inline, inline: inline}" *ngIf="show">
      <span *ngIf="isLoading">
        <div class="loader-message">{{message | translate}}</div>
        <ion-spinner class="loader-spinner" name="dots"></ion-spinner>
      </span>
      <span *ngIf="isFailed && showError">
        <div class="loader-message">{{loading.message | translate}}</div>
        <ion-button *ngIf="canRetry" (click)="do_retry()" style="text-transform: uppercase;">{{text.general.retry | translate}}</ion-button>
      </span>
    </div>`,
  styles: [`
.inline {
  background: none;
}
.blocked {
  background: #505050d9;
  color: white;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 18px;
  position: fixed !important;
}
.loader-container {
  z-index: 999999;
  position: absolute;
  left: 50%;
  top: 50%;
  padding: 10px;
  transform: translate(-50%, -50%);
}
.loader-spinner {
  display: flex;
  margin-left: auto;
  margin-right: auto;
}
  `]
})
export class AgLoadingComponent {
  @Input() loading: LoadingState
  @Input() message: string
  @Input() inline: boolean
  @Input() canRetry?: boolean = true
  @Input() showError?: boolean = true
  @Output() retry: EventEmitter<any> = new EventEmitter<any>()
  
  isFailed = false
  isLoading = false
  show = false
  text = TEXT
  
  do_retry () {
    this.retry.emit()
  }

  _subscriptions: Subscription[] = []
  listen (s: Subscription) { this._subscriptions.push(s) }
  ngOnDestroy () { this._subscriptions.map(s => s.unsubscribe()); this._subscriptions = [] }

  onUpdate () {
    this.isFailed = this.loading.is_failed()
    this.isLoading = this.loading.is_loading()
    this.show = !this.loading.is_success()
    
  }
  ngOnInit () {
    //this.show = true; this.isLoading = true; return; // this.isFailed = true; return;
    this.listen(this.loading.events.subscribe(() => {
      this.onUpdate()
    }))
    this.onUpdate()
  }

  ngOnChanges (changes) {
  }
}

@Pipe({name: 'cropLabel', pure: false})
export class CropLabelPipe implements PipeTransform {
  constructor (private translate: TranslateService, private app: AppService) {}
  transform(value: string) {
    //return this.app.translate(!value ? TEXT.general.no_crop : CropLabel(value))
    let key = !value ? TEXT.general.no_crop : CropLabel(value)
    return this.translate.get(key)
  }
}


@Pipe({name: 'sinceNow'})
export class SinceNowPipe implements PipeTransform {
  constructor (private translate: TranslateService) {}
  transform(value: number, exponent?: number): string {
    return formatSinceNow(this.translate.currentLang, value)
  }
}

@Pipe({
  name: 'sortLocale'
})
export class SortLocale implements PipeTransform {

  transform<T, P extends keyof T> (value: T[], name: P): T[] {
    return value.sort((a, b) => {
      return ('' + a[name]).localeCompare('' + b[name]); 
    });
  }
}

export interface ITimeValue {
  unit: TIME_UNIT, value: number
}
@Pipe({name: 'formatTime'})
export class FormatTimePipe implements PipeTransform {
  constructor (private translate: TranslateService) {}
  transform(value: ITimeValue, exponent?: number): string {
    //if (value.value == null || typeof value.value != 'number') return '-'
    return moment.duration(value.value, value.unit).humanize()
    // NOTE: does not work in older browsers
    // return new Intl.NumberFormat(this.translate.currentLang, { 
    // style: 'unit', unit: value.unit, unitDisplay: 'long' }).format(value.value)
  }
}


@Pipe({ name: 'reverse' })
export class ReversePipe implements PipeTransform {
  transform(value) {
    return value.slice().reverse();
  }
}


@Component({
  selector: 'ag-time-since',
  template: `
<span>{{value}}</span>
  `
})
export class AgTimeSince {
  @Input() ts: number

  constructor (
    private zone: NgZone,
    private translate: TranslateService
  ) {}

  value = '-'
  _interval
  _lang_subscription: Subscription
  ngOnInit () {
    this.zone.runOutsideAngular(() => {
      this._interval = setInterval(() => {
        this.update()
      }, 1000 * 61)
    })
    this._lang_subscription = this.translate.onLangChange.subscribe(x => {
      this.update()
    })
    this.update()
  }
  ngOnChanges (changes) {
    this.update()
  }
  ngOnDestroy () {
    if (this._interval) clearInterval(this._interval)
    if (this._lang_subscription) this._lang_subscription.unsubscribe()
  }
  update () { 
    this.zone.run(() => {
      if (!this.ts) this.value = '-'
      
      else this.value = formatSinceNow(this.translate.currentLang, this.ts) 
    })
  }
}

export type UNIT_TYPE = 'TEMPERATURE' | 'MOISTURE'

@Component({
  selector: 'ag-telemetry-value',
  template: `<span [style.color]="color">
  <span *ngIf="status != 'error'">{{value|number:"1.1-1"}}{{unit}}</span>
  <span *ngIf="status == 'error'" [translate]="text.general.error_value"></span>
</span>`
})
export class AgTelemetryValue {
  @Input() value: number
  @Input() type: UNIT_TYPE
  @Input() status: 'ok' | 'error' = 'ok'
  color
  unit
  text = TEXT

  ngOnInit () {
    this.status = this.status || 'ok'
    if (this.type == 'TEMPERATURE') {
      this.color = agTypes.colors.maxColor
      this.unit = '°'
    } else {
      this.color = agTypes.colors.minColor
      this.unit = '%'
    }
    if (this.status == 'error') {
      this.color = agTypes.colors.errorValueColor;
    }
  }
}

@Component({
  selector: 'ag-sensor-value',
  template: `
  <ion-icon class="icon" [ngStyle]="{fill: color}" src="{{icon}}" ></ion-icon>  
  <div class="flex-column ag-value-section">
    <span  *ngIf="status == 'error'" class="ag-value" [style.color]="errorColor" [translate]="text.general.error_value"></span>
    <span  *ngIf="status != 'error' && value != undefined" class="ag-value">{{value|number:"1.1-1"}}{{unitSymbol}}</span>
    <span  *ngIf="status != 'error' && value == undefined" class="ag-value">-</span>
    <div class="ag-value-label">
      <span *ngIf="prefix">{{prefix | translate}} </span>{{displayLabel | translate}}</div>
  </div>
  `,
  styles: [`

.ag-value-row {
  
}
.icon {
  width: 32px;
  height: 32px;
  margin: 0;
}
.ag-value {
  font-size: 24px;
}
.ag-value-label {
  font-size: 16px;
  max-width: 140px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}
.ag-value-section {
  margin-left: 5px;
}
  `]
})
export class AgSensorValue {
  @Input() prefix?: string
  @Input() type: 'TEMPERATURE' | 'MOISTURE'
  @Input() value: number | null
  @Input() cropType?: CROP_TYPE
  @Input() status?: 'ok' | 'error' = 'ok'
  
  // colors.minColor
  errorColor = agTypes.colors.errorValueColor
  color: string = ''
  unitSymbol: string = ''
  icon: string = ''
  displayLabel: string = ''
  text = TEXT

  constructor (
    private units: UnitService, private translate: TranslateService
  ) {}

  ngOnChanges (changes) {
    this.setup()
  }

  getDisplayLabel () {
    //if (this.label) return this.label
    if (this.type == 'TEMPERATURE') return TEXT.general.temperature
    else if (this.type == 'MOISTURE') {
      let cropName = CropLabel(this.cropType)
      return this.cropType ? cropName : TEXT.general.humidity
    } else {
      return '-'
    }
  }

  setup () {
    this.status = this.status || 'ok'
    if (this.type == 'TEMPERATURE') {
      this.color = agTypes.colors.maxColor
      this.unitSymbol = '°' // this.units.temperatureUnitSymbol()
      this.icon = "/assets/svg/iconset/ag-thermometer.svg"
    } else if (this.type == 'MOISTURE') {
      this.color = agTypes.colors.minColor
      this.unitSymbol = '%'
      this.icon = "/assets/svg/iconset/ag-moisture.svg"
    }
    let displayLabel = this.getDisplayLabel()
    if (displayLabel != this.displayLabel) {
      this.displayLabel = displayLabel
    }
  }

  ngOnInit () {
    this.setup()
  }
}




