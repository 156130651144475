import { EventEmitter, Injectable } from "@angular/core";
import { MoistureMeasurement, StorageService } from "./storage.service";
import { BehaviorSubject, catchError, combineLatest, debounceTime, distinctUntilChanged, filter, find, first, firstValueFrom, forkJoin, map, merge, scan, Subject, switchMap, takeWhile, throwError } from "rxjs";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { defaultHttpOptions } from "../models/auth.model";
import { Network } from '@capacitor/network';
import { LoadingState, MEASUREMENT_PROFILES } from "../util";
import { CROP_TYPE } from "../constant";
import { captureException } from "@sentry/angular";
import { ITelemetryData, TelemetryService, TimeseriesSubsciption } from "./telemetry";
import { AggregationType } from "../models/telemetry.model";
import { Device, Entity, EntityId, EntityType } from "../models/entity.model";
import { types } from "../types";
import { EntityModel, FarmerService } from "./device.service";
//import { FarmerMeasurementDevice } from "../components/super-pro/super-pro.model";
import { IMeasurementSettings } from "./types.service";
import { PhoneService } from "./phone.service";



export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export const CPRO_KEYS: (keyof SAMoistureMeasurement)[] = [
  "crop_name", "latitude", "longitude",
  "moisture_content", "temperature",
  "measurement", "comment", "ble_id"
]



function LoadMeasurementTelemetry (data: ITelemetryData) {
  let values: Record<string, SAMoistureMeasurement> = {}
  CPRO_KEYS.map(key => {
    if (data.data[key]) {
      data.data[key].map(v => {
        if (!values[v[0]]) {
          values[v[0]] = {
            timestamp: v[0]
          }
        }
        // TODO: ..
        // @ts-ignore
        values[v[0]][key] = v[1]
      })
    }
  })
  let items = Object.values(values)
      .map(x => LoadMeasurementItem(x))
      .filter(x => x != null).sort((m1, m2) => m2.timestamp - m1.timestamp)
  return items
}


function parseDeviceValue (value): number | null {
  let x = parseFloat(value)
  if (isNaN(x)) return null
  return x
}


function LoadMeasurementItem (data: SAMoistureMeasurement): SAMoistureMeasurement | null {
  if (data.measurement) {
    try {
      let pkgData = JSON.parse(data.measurement)
      Object.assign(data, pkgData)
    } catch (err) {
      captureException(err)
      return null
    }
  } else if (data.moisture_content != null || data.temperature != null) {
    if (!data.timestamp) {
      //console.warn('unable to load measurement', data)
      return null
    } 
    let ts = data.timestamp * 1000
    
    /*else if (!ts && data.time_of_transfer) {
      ts = parseInt(data.time_of_transfer) * 1000
    } else if (!ts) return null*/

    let result: SAMoistureMeasurement = {
      comment: data.comment, crop_name: data.crop_name as CROP_TYPE,
      latitude: parseDeviceValue(data.latitude), 
      longitude: parseDeviceValue(data.longitude),
      moisture_content: parseDeviceValue(data.moisture_content),
      temperature: parseDeviceValue(data.temperature),
      timestamp: data.timestamp, 
      ble_id: data.ble_id || null
    }
    /*if (result.latitude && result.longitude) {
      try {
        result.country = country_reverse_geocoding(
          result.latitude, result.longitude
        )
      } catch (err) {
        console.error(err)
      }
    }*/

    return result
  } else {
    //console.warn('cannot load measurement:', data)
  }
  return null
}





interface IMeasureSync {
  measurement: SAMoistureMeasurement
  status: 'registered' | 'sending' | 'complete'
  lastAttempt: Date
  error: Error | null
  accessToken: string
  uid: string
  timestamp: number
}


interface IMeasurementFilter {
  entityIds?: EntityId<EntityType.DEVICE>[]
}

/*
export interface IDeviceMeasurement {
  // TODO: get accessToken into measurement, so we more acurately can combine measurements and get device info
  // accessToken: string 
  moistureContent: number | null
  temperatureContent: number | null
  cropType: CROP_TYPE
  comment: string
  ts: number
  longitude: number | null
  latitude: number | null
  bleId: string | null
  country?: string
  calibrationOffset?: number | null
}
*/

export interface SAMoistureMeasurement {
  timestamp: number; // milliseconds
  
  status?: 'not-synced'
  ble_id?: string
  moisture_content?: number;
  crop_name?: string;
  calibration_offset?: number; // calibration
  temperature?: number; // temperature measurement

  meter_uuid?: string; // device id received from physical device
  device_id?: string; // device-id same as entity id
  
  measurement_uuid?: string; // uuidv4
  measurement_type?: 'avocado_moisture' | 'app_moisture';
  //measurement_meter_id: number; // id recevied from physical device, set -1 i guess
  description?: string; // any description
  comment?: string;
  image?: string;

  //location number type should be 32bit float for latitude and longitude
  longitude?: number;
  latitude?: number;

  //timestamp should be unix/epoch int
  time_of_transfer?: number; // seconds

  measurement?: string // JSON of SAMoistureMeasurement for backwards compatability
}

// TODO: replace with SAMoistureMeasurement ?
/*
export interface IMeasurementData {
  ts: number
  comment?: string
  calibration_offset?: string, 
  meter_uuid?: string
  crop_name?: string, 
  moisture_content?: string
  id?: string, 
  temperature?: string, 
  time_of_transfer?: string
  latitude?: string, 
  timestamp?: string, 
  type?: string
  longitude?: string, 
  measurement?: string
  bleId?: string
}
*/


/*
async function FetchMeasurements (http: HttpClient, deviceId: EntityId<EntityType.DEVICE>) {
  let keys = CPRO_KEYS
  //keys=['temperature']
  let aYearAgo = Math.round(new Date().getTime() - (1000 * 60 * 60 * 24 * 365))
  let nextDay = Math.round(new Date().getTime() + (1000 * 60 * 60 * 24))
  let url = `/api/plugins/telemetry/${deviceId.entityType}/${deviceId.id}/values/timeseries?keys=${keys.join(',')}&startTime=${aYearAgo}&endTime=${nextDay}`;
  // &limit=1000&agg=NONE&interval=1
  let data: any = await http.get(url).toPromise()
  let tel: ITelemetryData = {
    data: {}
  }
  for (var k in data) {
    tel.data[k] = []
    for (var value of data[k]) {
      tel.data[k].push([value.ts, value.value])
    }
  }
  
  return LoadMeasurementTelemetry(tel)
}*/


interface IMeasureDevice {
  id: string
  entity: Device
  accessToken: string
}



export class FarmerMeasurementDevice {  
  subscription: TimeseriesSubsciption
  latestSubscription: TimeseriesSubsciption
  measurements: BehaviorSubject<Record<string, SAMoistureMeasurement>> = new BehaviorSubject({})
  latest: BehaviorSubject<SAMoistureMeasurement | null> = new BehaviorSubject(null)
  loading = new LoadingState()

  get settings () { return this.model.settings }
  model: EntityModel<EntityType.DEVICE, IMeasurementSettings>
  
  constructor (
    public service: MeasurementService, 
    public state: IMeasureDevice
  ) {
    this.model = this.service.getModel(state.entity)
    this.subscribeLatest()
  }
  
  get entity () { return this.state.entity }

  latestMeasurements () {
    return this.measurements.pipe(
      map(x => {
        let result = Object.values(x)
        result.sort((a, b) => b.timestamp - a.timestamp)
        return result
      })
    )
  }

  subscribeLatest () {
    if (this.latestSubscription) return
    this.latestSubscription = this.service.subscribeLatest(this.state.entity.id, (measurement) => {
      this.latest.next(measurement)
    })
  }

  subscribeTelemetry () {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }

    this.subscription = this.service.subscribeMeasurements(this.state.entity.id, (measurements) => {
      this.addMeasurements(measurements, true)
      return true
    }, (isLoading) => {
      this.loading.loading(isLoading)
    })
  }

  addExternalMeasurement (measurement: SAMoistureMeasurement) {
    if (this.subscription) {
      this.subscription.addDataItem([measurement.timestamp, measurement])
    }
  }

  addMeasurements (measurements: SAMoistureMeasurement[], replace=false) {
    //let store = this.measurements.value

    let newMeasurements: Record<string, SAMoistureMeasurement> = {}
    if (!replace) {
      newMeasurements = this.measurements.value
    }
    let found = false
    measurements.map(m => {
      let key = m.timestamp
      if (!newMeasurements[key]) {
        newMeasurements[key] = m
        found = true
      }
    })
    if (found || replace) {
      this.measurements.next(newMeasurements)
    }
  }

  async saveSettings (settings: Partial<IMeasurementSettings>) {
    await this.model.saveSettings(settings)
  }

}


@Injectable()
export class MeasurementService {
  public ready: BehaviorSubject<boolean>;

  public syncState: BehaviorSubject<Record<string, IMeasureSync>>
  private retryRate: number
  private retrySync: Subject<boolean>
  private syncRequest: Subject<boolean>

  measurementDevices: BehaviorSubject<Record<string, FarmerMeasurementDevice>> = new BehaviorSubject({})

  constructor (
    private storage: StorageService,
    private http: HttpClient,
    private farmer: FarmerService,
    private telemetry: TelemetryService,
    private phone: PhoneService
  ) {
    this.retrySync = new Subject()
    this.syncRequest = new Subject()
    
    this.retryRate = 15 * 60 * 1000
    this.syncState = new BehaviorSubject({})
    this.ready = new BehaviorSubject<boolean>(false)
    storage.getDatabaseState().subscribe(x => {
      if (x && !this.ready.value) { this.initializeStorage() }
    })

    const isOnline$ = this.phone.networkReady.pipe(
      distinctUntilChanged() // only emit when online status changes
    );

    combineLatest([isOnline$, this.syncRequest.pipe(debounceTime(2000))]).pipe(
      filter(([isOnline]) => isOnline) // only proceed if online
    ).subscribe(([_, x]) => {
      this.uploadAllMeasurements();
    });

    this.retrySync.pipe(
      debounceTime(this.retryRate)
    ).subscribe(x => {
      this.syncRequest.next(true)
    })
    this.farmer.onRefresh.subscribe(x => {
      this.onDevices()
    })
    this.onDevices()
  }

  getMeasurementDevice (entityId: EntityId<EntityType.DEVICE>) {
    return this.measurementDevices.pipe(
      map(devices => {
        return Object.values(devices).find(x => x.entity.id.id == entityId.id)
      }),
      first(matchingDevice => !!matchingDevice)      
    )
  }

  getModelFromAccessToken (accessToken: string) {
    return Object.values(this.measurementDevices.value).find(x => x.state.accessToken == accessToken)
  }

  getModel (entity: Device) {
    return this.farmer.createModel(this.farmer.types.measurement, entity)
  }

  async onDevices () {
    let measurementDevices = this.farmer.listEntities().filter(x => MEASUREMENT_PROFILES.includes(x.type))
    
    let httpOptions = defaultHttpOptions(true, true, false)
    let models = Object.values(this.measurementDevices.value)

    let newDevices: Record<string, FarmerMeasurementDevice> = {}
    for (var entity of measurementDevices) {
      let model = models.find(x => x.entity.id.id == entity.id.id)
      if (!model) {
        let url = `/api/device/${entity.id.id}/credentials`
        let credentials: any = await this.http.get(url, httpOptions).toPromise()
        if (credentials.credentialsType != 'ACCESS_TOKEN') {
          console.warn('unknown credential type for device: ', entity.name)
          continue
        }
        let accessToken = credentials.credentialsId
        let state: IMeasureDevice = {
          accessToken: accessToken, entity: entity, id: accessToken
        }
        model = new FarmerMeasurementDevice(this, state)
        model.subscribeTelemetry()
        
        newDevices[state.id] = model
      } else {
        newDevices[model.state.id] = model
      }
    }

    // TODO: only update when changed and handle removed devices
    this.measurementDevices.next(newDevices)

  }
  
  subscribe(filter: IMeasurementFilter) {
    return this.measurementDevices.pipe(
      switchMap(devicesObj => {
        let devices = Object.values(devicesObj);
        if (filter.entityIds != null) {
          const filterIds = filter.entityIds.map(x => x.id);
          devices = devices.filter(x => filterIds.includes(x.entity.id.id));
        }
  
        return combineLatest(devices.map(device => device.measurements)).pipe(
          map(measurementsArray => {
            const mergedMeasurements = measurementsArray.reduce((acc, current) => ({
              ...acc,
              ...current
            }), {} as Record<string, SAMoistureMeasurement>);
  
            const allMeasurements = Object.values(mergedMeasurements);
            allMeasurements.sort((a, b) => b.timestamp - a.timestamp);
  
            console.log('Found', allMeasurements.length, 'measurements for', devices.length, 'devices');
            return allMeasurements;
          })
        );
      })
    );
  }

  clearCache () {
    this.storage.purgeDB()
  }

  async initializeStorage () {
    this.ready.next(true)
    this.storage.onMeasurementSaved.subscribe(x => {
      this.scheduleMeasurement(x)
    })
    this.scheduleLocalMoisturements()    
  }

  async listLocalMeasurements () {
    return await this.storage.getMeasurements()
  }

  async uploadAllMeasurements () {
    let records = this.syncState.value
    let models = Object.values(this.measurementDevices.value)
    for (var k in records) {
      let state = records[k]
      let shouldSync = state.status == 'registered' || (
        state.status == 'complete' && state.error != null
      )
      let model = models.find(x => x.state.accessToken == state.accessToken)
      if (model) {
        let measure = LoadMeasurementItem(state.measurement)
        if (measure) {
          measure.status = 'not-synced'
          model.addExternalMeasurement(measure)
          //model.addMeasurements([measure])
        }
      }
      if (!model) {
        state.status = 'complete'
        state.error = new Error('device not claimed')
      } else if (shouldSync) {
        state.status = 'sending'
        state.lastAttempt = new Date()
        this.uploadMeasurement(state).then(x => {
          state.status = 'complete'
          state.error = null
          this.syncState.next(this.syncState.value)
        }).catch(err => {
          state.status = 'complete'
          state.error = err
          this.syncState.next(this.syncState.value)
          this.retrySync.next(true)
        })
      }
    }
    this.syncState.next(this.syncState.value)
  }

  async uploadMeasurement (measure: IMeasureSync) {
    // NOTE: important to ignoreErrors, otherwise it might logout user on 401
    let httpOptions = defaultHttpOptions(true, true, false)
    let url = '/api/v1/' + measure.accessToken + '/telemetry/'

    let measurement = {...measure.measurement}
    let timestamp = measurement.timestamp
    delete measurement.status
    
    let body = {
      ts: timestamp,
      values: measurement
    } // measure.measurementToAgrolog()
    //throw new Error('skip syncing')
    try {
      await this.http.post(url, body, httpOptions).toPromise()
      this.storage.setAsUploadedToAgrolog(measure.uid)
      delete measure.status
    } catch (err) {
      throw err
    }
  }

  async postMeasurement (entity: Device, ts: number, measure: SAMoistureMeasurement | {image: string, status?}) {
    let httpOptions = defaultHttpOptions(true, true, false)
    let device = await firstValueFrom(this.getMeasurementDevice(entity.id))
    let url = '/api/v1/' + device.state.accessToken + '/telemetry/'

    let bodyMeasure = {...measure}
    delete bodyMeasure.status
    let body = {ts: ts, values: bodyMeasure}
    try {
      await this.http.post(url, body, httpOptions).toPromise()
      delete measure.status
    } catch (err) {
      console.error(err)
      throw err
    }
  }

  // TODO: get rid of MoistureMeasurement class
  scheduleMeasurement (measurement: MoistureMeasurement) {
    let m = measurement.getMeasurement()
    let measure: SAMoistureMeasurement = {
      ble_id: measurement.meter_uuid + ':' + measurement.measurement_meter_id,
      comment: measurement.comment,
      moisture_content: parseFloat(m.moisture_content),
      temperature: m.temperature,
      crop_name: measurement.crop_name,
      calibration_offset: m.calibration_offset,
      latitude: measurement.latitude,
      longitude: measurement.longitude,
      measurement_type: 'avocado_moisture',
      time_of_transfer: measurement.time_of_transfer,
      timestamp: Math.round(measurement.timestamp * 1000),
      status: 'not-synced'
    }
    
    if (measurement.getAccessToken() !== null) {
      let uid = measurement.measurement_uuid
      let accessToken = measurement.getAccessToken()
      let current = this.syncState[uid]
      let timestamp = measure.timestamp

      


      // schedule upload
      if (!current) {
        if (!timestamp || timestamp < 1) {
          //console.warn('measurement have no valid timestamp and will be ignored', measure)
          return
        }
        
        this.syncState.next({...this.syncState.value,
          [uid]: {
            measurement: measure, status: 'registered', lastAttempt: new Date(),
            error: null, accessToken: accessToken, uid: uid, timestamp: timestamp
          }
        })
        this.syncRequest.next(true)
      }
      
    }
  }

  scheduleLocalMoisturements () {
    this.storage.getMeasurements().then(measurements => {
      for (const measurement of measurements) {

        this.storage.measurementUploadedToAgrolog(measurement.measurement_uuid).then(res => {
          if (!res) {
            
            
            
            const moistureMeasurement = new MoistureMeasurement(measurement.meter_uuid, measurement.measurement_uuid,
              measurement.measurement_meter_id, measurement.moisture_content,
              measurement.crop_name, measurement.calibration_offset, measurement.temperature,
              Math.floor((new Date).getTime() / 1000), measurement.device_id, measurement.access_token);

              // Add different information
            if (measurement.comment !== undefined) {
              moistureMeasurement.setComment(measurement.comment);
            }

            if (measurement.picture !== undefined) {
              moistureMeasurement.setImage(measurement.picture);
            }
            
            // NOTE: do not try to guess the timestamp, if not present then the measurement is invalid
            moistureMeasurement.setTimestamp(measurement.timestamp);
            
            if (measurement.latitude) {
              moistureMeasurement.setLocation( measurement.longitude, measurement.latitude );
            }

            // Check if access token is set
            // console.log('Access token: ' + moistureMeasurement.getAccessToken());
            this.scheduleMeasurement(moistureMeasurement)
          }
        })
      }
    })
  }

  subscribeLatest (deviceId: EntityId<EntityType.DEVICE>, onData: (x: SAMoistureMeasurement) => void) {
    return this.telemetry.latest(deviceId, {keys: CPRO_KEYS}, {
      onData: (data) => {
        let items = LoadMeasurementTelemetry(data)
        if (items && items.length > 0) {
          onData(items[0])
        }
        return true
      }, 
      onLoading: () => {}
    })
  }

  subscribeMeasurements (deviceId: EntityId<EntityType.DEVICE>, 
    onData: (x: SAMoistureMeasurement[]) => boolean, onLoading: (isLoading: boolean) => void, latest=false) {
      // NOTE: subscription does sometimes not work, always fetch everything manually to begin with
      
      //let state: {measurements: SAMoistureMeasurement[]} = {measurements: []}
      function emitData (measurements: SAMoistureMeasurement[]) {
  
        //let allMeasurements = state.measurements.concat(measurements)
        let allMeasurements = measurements
        let uniqMeasurements: Record<any, SAMoistureMeasurement> = {}
        allMeasurements.map(x => {
          uniqMeasurements[x.timestamp] = x
        })
        let finalMeasurements = Object.values(uniqMeasurements)
        finalMeasurements.sort((a, b) => b.timestamp - a.timestamp)
        //state.measurements = finalMeasurements
        onData(finalMeasurements)
      }
      

      return this.telemetry.subscribe(deviceId, {
          agg: types.aggregation.none.value as AggregationType,
          //timeWindow: (1000 * 60 * 60 * 24 * 365 * 10),
          keys: CPRO_KEYS, interval: 1, limit: 100
        }, {
        onData: (data) => {
          let items = LoadMeasurementTelemetry(data)
          emitData(items)
          return true
        },
        onLoading: (isLoading) => onLoading(isLoading)
      })
  }

}
