import { AndroidPermissions } from "@awesome-cordova-plugins/android-permissions";

import { Injectable } from "@angular/core";
import {
  BluetoothLE,
  OperationResult,
} from "@awesome-cordova-plugins/bluetooth-le/ngx";
import { Platform } from "@ionic/angular";
import { LocationService } from "./location.service";
import { BehaviorSubject, Subscription } from "rxjs";
import { NgZone } from "@angular/core";
import { filter, switchMap, tap } from "rxjs/operators";
import { Capacitor } from "@capacitor/core";
import { MoistureMeasurement, StorageService } from "./storage.service";
import { PermissionService } from "./permissions.service";
import { Device } from '@capacitor/device';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx'
import { BackgroundMode } from '@anuradev/capacitor-background-mode'
import { MeasurementService } from "./measurement.service";
import { captureException } from "@sentry/angular";


/* GLOBAL SETTINGS */

const AVOCADO_SERVICE = "d9243705-d712-4230-8813-f9dea0e4fb5d";
const READ_SINGLE_MEASUREMENT_CHARACTERISTIC = "5f12937d-13c1-4dfa-8dc7-92527b7398c7";
const REQUEST_SYNC_CHARACTERISTIC = "98e9e6c0-bed7-47e8-8a5c-44bf4d8b7571";
const REQUEST_TIME_CHARACTERISTIC = "92429ce2-34e0-4100-bcb5-b7a4c30aedb9";
const SEND_TIME_CHARACTERISTIC = "46b6e55e-fc68-46df-a05f-70732ce392ad";
const REQUEST_LOCATION_CHARACTERISTIC = "b02408f6-d7a7-43e5-b1ce-39044310a117";
const SEND_LOCATION_CHARACTERISTIC = "f37022d3-2dae-4e9f-912f-633d7588c4ce";
const READ_MEASUREMENT_LIST_CHARACTERISTIC = "8ba08524-9f47-461b-a266-750dac372099";

const BLE_INIT_CONFIG = {
  request: true, statusReceiver: false, restoreKey: "dk.sagroline.moisture-connect"
}
const BLE_SCAN_CONFIG = {
  services: [AVOCADO_SERVICE], allowDuplicates: false
}
  

/* TYPES */

export type DeviceStatus = 'registered' | 'ready' | 'connected' | 'disconnecting' | 'disconnected' | 'connecting' | 'reading' | 'sending'

export interface IDevice {
  name: string, address: string, stored: boolean
}

interface IBleMeasurement {
  device_uid: string, // "244CAB0D1F8E"
  connection: {
    device_id: string // "0d8cafe0-1986-11ee-9dd7-1f669286e6ec",
    access_token: string // "h5nOhGEVbkrbK1vV25sM"
  }
  measurements: {
    id: number // 78,
    timestamp: number // 1730890727,
    data: {
      crop_name: string // "rye",
      longitude: number // 10.25155,
      latitude: number // 55.47615,
      calibration_offset: number // 0,
      temperature: number // 21.1,
      moisture_content: number // 16.6
    }
  }[]
}

type BleTaskType = 'SYNCING' | 'MEASURING'
type BluetoothTaskStatus = 'progress' | 'error' | 'success'

export type BluetoothTaskErrors = 'no_gps'

class BluetoothError extends Error {
  constructor (msg: BluetoothTaskErrors) {
    super(msg)
  }
}

export interface IBleTask<T extends BleTaskType> {
  type: T
  status: BluetoothTaskStatus
  timestamp: number
  error?: string
}

interface IMeasuringState extends IBleTask<'MEASURING'> {
  longitude?: number
  latitude?: number
}

interface ISyncingState extends IBleTask<'SYNCING'> {  
  measurements?: Set<number>
  completed?: Set<number>
  activeMeasurement?: number
  latestMeasurement?: MoistureMeasurement
}


export interface IDeviceStatus<T extends IBleTask<any>> {
  address: string, 
  status: DeviceStatus, 
  timestamp: Date
  task: T | null
}


export function isDeviceSyncing (x: IDeviceStatus<IBleTask<BleTaskType>>): x is IDeviceStatus<ISyncingState> {
  return x && x.task?.type == 'SYNCING'
}
export function isDeviceMeasuring (x: IDeviceStatus<IBleTask<BleTaskType>>): x is IDeviceStatus<ISyncingState> {
  return x && x.task?.type == 'MEASURING'
}

type BluetoothStatus = 'loading' | 'access' | 'initialized' | 'enabled' | 'ready' | 'writing'


type IScanStatus = 'starting' | 'scanning' | 'stopping' | 'stopped'
interface IScanningState {
  status: IScanStatus
  devices: IDevice[]
}

export interface IBluetoothState {
  status: BluetoothStatus
  isAllowed: boolean // IPermissionState | null
  isInitialized: boolean | null
  isReady: boolean
  isEnabled: boolean | null
  isAutoConnecting: boolean
  canUseBackgroundMode: boolean
  backgroundEnabled: boolean
  message: string | null

  scanning: IScanningState | null
}
 

export interface IDeviceModel {
  name: string
  mac_address: string
}



/* - UTILITY FUNCTIONS - */
export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}


function debug(...args) {
  /*let items = args.map(msg => {
    if (typeof msg == 'object') return JSON.stringify(msg)
    return msg
  })

  let prefix = '::'
  console.log(prefix + items.join(' '))
  */
}

// U16 to array buffer
function str2ab16(str: string) {
  const buf = new ArrayBuffer(str.length);
  const bufView = new Uint8Array(buf);
  for (let i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}



// Note that this function should only be used to send time
function str2ab32(time: number) {
  const buf = new ArrayBuffer(4);
  const dv = new DataView(buf);

  const timeAsHex = time.toString(16);
  const parts = timeAsHex.split(/(.{2})/).filter((O) => O);
  const timeAsHexReversed = parts.reverse().join("");

  dv.setUint32(0, parseInt(timeAsHexReversed, 16));

  return buf;
}

function remove_non_ascii(str) {
  if (str === null || str === "") {
    return false;
  } else {
    str = str.toString();
  }
  return str.replace(/[^\x20-\x7E]/g, "");
}



@Injectable({
  providedIn: "root",
})
export class BluetoothService {  
  devices: BehaviorSubject<IDevice[]> = new BehaviorSubject<IDevice[]>([])
  state = new BehaviorSubject<IBluetoothState>({
    isAllowed: false, isInitialized: false, status: 'loading',
    isAutoConnecting: false, isEnabled: false, isReady: false,
    scanning: null, canUseBackgroundMode: false, message: null,
    backgroundEnabled: false
  })
  
  scanSubscription: Subscription
  autoSubscription: Subscription
  connectSubscriptions = new Map<string, Subscription>()
  deviceStatus = new BehaviorSubject<Map<string, IDeviceStatus<IBleTask<any>>>>(new Map())
  allowedDevices: string[] = []

  constructor(
    private database: StorageService,
    private ngZone: NgZone,
    private ble: BluetoothLE,
    private location: LocationService,
    private platform: Platform,
    private permissions: PermissionService,
    private diagnostic: Diagnostic,
    private measurements: MeasurementService
  ) {
    if (Capacitor.isNativePlatform()) {
      this.setupForMobile()
    }
  }

  async cancelOperation () {
    let devices = Array.from(this.deviceStatus.value.values())
    for (let device of devices) {
      if (device.task?.status == 'progress') {
        await this.disconnectAndCloseDevice({address: device.address})
      }
    }
  }

  updateDeviceTask (addr: string, values: Partial<IBleTask<any>>) {
    let device = this.deviceStatus.value.get(addr)
    // TODO: handle null and other..
    let newDevice = {...device, task: {...device.task, ...values}}
    this.deviceStatus.value.set(addr, newDevice)
    this.deviceStatus.next(this.deviceStatus.value)
  }

  updateMeasurement (addr: string, values: Partial<IMeasuringState>) {
    //console.log('update measurement', addr, values)
    this.updateDeviceTask(addr, {...values, type: 'MEASURING'})
  }
  updateSyncing (addr: string, values: Partial<ISyncingState>) {
    this.updateDeviceTask(addr,  {...values, type: 'SYNCING'})
  }
  clearTask (addr: string) {
    let device = this.deviceStatus.value.get(addr)
    if (device.task?.status == 'progress') {
      this.updateDeviceTask(addr, {status: 'error', error: 'disconnect'})
    } else if (device.task != null) {
      let newDevice = {...device, task: null}
      this.deviceStatus.value.set(addr, newDevice)
      this.deviceStatus.next(this.deviceStatus.value)
    }
  }

  async isOldAndroid () {
    let deviceInfo = await Device.getInfo()
    return deviceInfo.androidSDKVersion <= 30
  }

  // PERMISSIONS
  private async checkBluetoothPermissions() {
    const platform = Capacitor.getPlatform()
    if (platform == "android") {
      let isOldAndroid = await this.isOldAndroid()
      if (isOldAndroid) {
        let r = await AndroidPermissions.checkPermission("android.permission.BLUETOOTH")
        return r.hasPermission
      }

      let canScan = await AndroidPermissions.checkPermission("android.permission.BLUETOOTH_SCAN")
      return canScan.hasPermission
    } else if (platform == 'ios') {
      let status = await this.diagnostic.getBluetoothAuthorizationStatus()
      return status == this.diagnostic.permissionStatus.GRANTED
    } else {
      return false
    }
  }

  private async requestBluetoothPermissions () {
    const platform = Capacitor.getPlatform()
    if (platform == 'android') {
      
      let isOldAndroid = await this.isOldAndroid()
      if (isOldAndroid) {
        await AndroidPermissions.requestPermission("android.permission.BLUETOOTH")
        return
      }
      
      const BLUETOOTH_PERMISSIONS = [
        //"android.permission.BLUETOOTH_ADMIN",
        //"android.permission.BLUETOOTH",
        "android.permission.BLUETOOTH_SCAN",
        "android.permission.BLUETOOTH_CONNECT"
      ]
      for (var k in BLUETOOTH_PERMISSIONS) {
        let p = BLUETOOTH_PERMISSIONS[k]
        try {
          await AndroidPermissions.requestPermission(p)
        } catch (err) {
          console.error('failed to request', p, err)
        }
      }
    } else if (platform == "ios") {
      await this.diagnostic.requestBluetoothAuthorization()
    }
  }
  private async requestDisableBatteryOp () {
    const platform = Capacitor.getPlatform()
    if (platform == "android") {
      await BackgroundMode.requestDisableBatteryOptimizations()
    }
  }
  private async checkBatteryOp () {
    const platform = Capacitor.getPlatform()
    if (platform != "android") {
      return true
    } 
    let r = await BackgroundMode.checkBatteryOptimizations()
    return r.disabled
  }
  private async openBatterySettings () {
    await this.permissions.openBatteryOptimizationSettings()
  }
  private async requestBackgroundLocationPermissions () {
    if (this.platform.is('android')) {
      let r = await AndroidPermissions.requestPermission("android.permission.ACCESS_BACKGROUND_LOCATION")
    } else {
      await this.diagnostic.requestLocationAuthorization('always', 'full')
    }
  }
  private async checkBackgroundLocationPermissions () {
    if (!Capacitor.isNativePlatform()) return true
    
    if (this.platform.is('android')) {
      let r = await AndroidPermissions.checkPermission("android.permission.ACCESS_BACKGROUND_LOCATION")
      return r.hasPermission
    } else {
      let status = await this.diagnostic.getLocationAuthorizationStatus()
      return status == this.diagnostic.permissionStatus.GRANTED
    }
  }

  // SETUP
  async setupForMobile() {
    this.permissions.registerPermission('BLUETOOTH', {
      requestPermission: async () => await this.requestBluetoothPermissions(),
      checkPermission: async () => await this.checkBluetoothPermissions(),
      openSettings: async () => await this.permissions.openPermissionSettings()
    })

    this.permissions.registerPermission('GPS_BACKGROUND', {
      requestPermission: async () => await this.requestBackgroundLocationPermissions(),
      checkPermission: async () => await this.checkBackgroundLocationPermissions(),
      openSettings: async () => await this.permissions.openPermissionSettings()
    })

    this.permissions.registerPermission('BATTERY_OPTIMIZED', {
      requestPermission: async () => await this.requestDisableBatteryOp(),
      checkPermission: async () => await this.checkBatteryOp(),
      openSettings: async() => await this.openBatterySettings()
    })

    await this.initialize()

    this.diagnostic.registerBluetoothStateChangeHandler(event => {
      this.updateBluetoothState(event)
    })

    if (this.platform.is('android')) {
      await BackgroundMode.disableWebViewOptimizations()
      //await BackgroundMode.enable()
    }
    
    this.permissions.state.subscribe(permissions => {
      // NOTE we can still sync without permissions.GPS.enabled
      this.updateState({
        isAllowed: permissions.BLUETOOTH.enabled,
        canUseBackgroundMode: this.platform.is('android') && permissions.BATTERY_OPTIMIZED.enabled
      })
      this.updateBackgroundMode()
    })

    this.state.subscribe(x => this.updateBackgroundMode())
    this.deviceStatus.subscribe(x => this.updateBackgroundMode())

    this.measurements.measurementDevices.subscribe(claimedDevices => {
      this.allowedDevices = Object.values(claimedDevices).map(x => {
        return x.entity.name
      })
      this.autoConnect()
    })

    this.devices.subscribe(devices => {
      this.autoConnect()
    })

    this.database.devices.subscribe(knownDevices => this.onKnownDevices(knownDevices))

    this.deviceStatus.subscribe(devices => {
      devices.forEach(device => {
        try {
          this.handleDevice(device)
        } catch (err) {
          console.error('handle device error:', err)
        }
      })
    })

    this.state.subscribe(state => this.handleState(state))

    this.platform.ready().then(() => {
      this.database.getDatabaseState().subscribe(ready => {
        this.updateState({isReady: ready})
      })
    })
  }

  async updateBluetoothState (event) {
    if (event == 'powered_on') {
      this.updateState({isEnabled: true})
    } else if (event == 'powered_off') {
      this.updateState({isEnabled: false})
    }
  }

  async initialize () {
    /*let isAvailable = await this.diagnostic.isBluetoothAvailable()
    this.updateState({
      isEnabled: isAvailable
    })*/
  }

  async forceReset () {
    await this.stopAutoConnect(true)
    await this.autoConnect()
  }

  async updateBackgroundMode () {
    // NOTE: i'm turning off background mode, it seems to make bluetooth unstable on android
    /*
    let state = this.state.value
    
    if (state.canUseBackgroundMode) {
      let pairedDevices = this.devices.value.filter(x => x.stored && this.allowedDevices.includes(x.name))
      let backgroundMessage: string | null = null
      this.deviceStatus.value.forEach(status => {
        let device = pairedDevices.find(x => x.address == status.address)
        if (!device) return

        if (isDeviceMeasuring(status)) {
          backgroundMessage = device.name + ' measuring'
        } else if (isDeviceSyncing(status)) {
          backgroundMessage = device.name + ' syncronizing'
        } else { 
          backgroundMessage = device.name + ' ' + status.status
        }
      })
      console.log('update background mode', backgroundMessage)
      
      //let isEnabled = await BackgroundMode.isEnabled()
      if (backgroundMessage) {
        if (!isEnabled.enabled) {
          console.log('enable background mode', isEnabled.enabled)
          await BackgroundMode.disableWebViewOptimizations()
          await BackgroundMode.enable()
        
        }
        if (backgroundMessage != state.message) {
          await BackgroundMode.setSettings({
            text: backgroundMessage
          })
        }
        this.updateState({message: backgroundMessage, backgroundEnabled: true})
      } else {
        console.log('disable background mode')
        await BackgroundMode.enableWebViewOptimizations()
        await BackgroundMode.disable()
        this.updateState({message: null, backgroundEnabled: false})
      }
    }*/
  }

  async handleDevice (device: IDeviceStatus<IBleTask<any>>) {

    if (device.status == 'disconnected') {
      this.clearTask(device.address)
    }

    if (isDeviceSyncing(device) && device.task.status == 'progress') {
      let task = device.task
      let measurements = task.measurements || new Set()
      let completed = task.completed || new Set()
      
      let allPending = Array.from(measurements).filter(x => !completed.has(x))
      
      let pendingMeasurement = task.activeMeasurement
      let latestMeasurement = task.latestMeasurement

      
      // if there's no pending measurement, then assume we're done
      if (allPending.length == 0) {
        console.log('no more pending measurements, sync complete!')
        this.updateSyncing(device.address, {
          activeMeasurement: null, latestMeasurement: null, status: 'success'
        })
        // request the last measurement if 
        let lastMeasurement = Math.max(...measurements)
        if (!completed.has(lastMeasurement)) {
          this.requestMeasurement(device.address, lastMeasurement)
        }
      } 
      // if there's no active requested measurement then request the first one
      else if (pendingMeasurement == null) {
        let scheduleNext = Math.min(...allPending)
        console.log('request next measurement:', scheduleNext)
        this.updateSyncing(device.address, {
          activeMeasurement: scheduleNext, 
          latestMeasurement: null
        })
        this.requestMeasurement(device.address, scheduleNext)
      } 
      // if we got a measurement, then request the next one
      else if (latestMeasurement != null) {
        completed.add(pendingMeasurement)

        let exist = await this.database.measurementAlreadyInDB(
          latestMeasurement.meter_uuid, latestMeasurement.measurement_meter_id
        )

        if (exist) {
          // if measurement already exist, it's safe to assume that we can stop syncing
          
          let inStorage = await this.database.listDeviceMeasurementIds(device.address)
          console.log('in storage ids', device.address, inStorage)
          for (let p of allPending) {
            let nextId = latestMeasurement.measurement_meter_id - p
            if (!inStorage.includes(nextId)) {
              console.warn('storage does not have id', nextId, 'last sync might have been interrupted, will sync all')
              this.updateSyncing(device.address, {
                activeMeasurement: null,
                latestMeasurement: null,
                completed: completed
              })
              return
            }
          }

          
          this.updateSyncing(device.address, {
            activeMeasurement: null, latestMeasurement: null, completed: completed,
            status: 'success'
          })

          let lastMeasurement = Math.max(...allPending)
          if (lastMeasurement != null) {
            console.log('measurement already exist, will complete syncing', lastMeasurement)
            this.requestMeasurement(device.address, lastMeasurement)
          }
        } else {
          console.log('measurement registered', latestMeasurement?.measurement_meter_id)
          this.updateSyncing(device.address, {
            activeMeasurement: null,
            latestMeasurement: null,
            completed: completed
          })
        }

      }
    }
  }

  updateDeviceStatus (addr: string, status: DeviceStatus) {
    this.ngZone.run(() => {
      console.log(addr, status)
      let data = this.deviceStatus.value
      let current = data.get(addr)
      let task = current?.task || null
      data.set(addr, {address: addr, status: status, timestamp: new Date(), task: task})
      this.deviceStatus.next(data)
    })
  }
  getDeviceStatus (addr: string) {
    return this.deviceStatus.value.get(addr) || null
  }
  
  onKnownDevices (devices: IDeviceModel[]) {
    let knownAddresses = devices.map(x => x.mac_address)
    devices.map(device => {
      let d = this.ensureDevice(device.name, device.mac_address)
    })
    let nextDevices = this.devices.value.map(device => {
      device.stored = knownAddresses.includes(device.address)
      return device
    })
    this.devices.next(nextDevices)

  }

  updateState(opt: Partial<IBluetoothState>) {
    this.ngZone.run(() => {
      let value = this.state.value
      let updates: Partial<IBluetoothState> = {}
      for (var key in opt) {
        if (value[key] != opt[key]) updates[key] = opt[key]
      }
      if (Object.keys(updates).length > 0) {
        this.state.next({ ...this.state.value, ...updates })
      }
    })
  }
  
  isReady () {
    let state = this.state.value
    return state.isAllowed && state.isReady && state.isEnabled && state.isInitialized
  }

  async handleState (state: IBluetoothState) {
    // check for storage-ready, bluetooth-allowed, bluetooth-enabled, bluetooth-initialized
    let isReady = this.isReady()
    
    
    if (!isReady) {
      await this.stopAutoConnect()
    } 
    
    if (!state.isReady) {
      return // storage not ready
    } else if (!state.isAllowed) {
      return // bluetooth not allowed
    } else if (!state.isEnabled) {
      // NOTE: do not try to enable bluetooth automatically, user might not want it enabled
      let state = await this.diagnostic.getBluetoothState()
      return this.updateBluetoothState(state)
    } else if (!state.isInitialized) {
      // bluetooth not initialized, try force-initialize
      return await this.ensureInitialized()
    } else {
      // if everything is ok, then connect
      // NOTE: trying to connect without being ready might cause a crash
      await this.autoConnect()
    }
  }

  async stopScanning () {
    let isScanning = await this.ble.isScanning()
    if (isScanning.isScanning) {
      await this.ble.stopScan()
      isScanning = await this.ble.isScanning()
    }
    if (isScanning.isScanning) {
      throw new Error('unable to stop scan')
    }
    this.updateState({ scanning: null })
    if (this.scanSubscription) {
      this.scanSubscription.unsubscribe()
    }
  }

  storeDevice (device: IDevice) {
    this.database.addDevice(device.address, device.name)
  }
  removeDevice (device: IDevice) {
    this.database.removeDevice(device.address)
    this.database.deleteDeviceMeasurements(device.address)
  }

  async startScanning (lookForDevice: string) {
    let deviceInfo = await Device.getInfo()
    if (deviceInfo.androidSDKVersion <= 30) {
      let p = await this.permissions.checkPermission('GPS')
      if (!p) {
        await this.permissions.requestPermission('GPS', true)

        let p = await this.permissions.checkPermission('GPS')
        if (!p) {
          throw new Error('GPS must be available to scan on SDK <= 30')
        }
      }
    }
    

    await this.ensureInitialized()
    await this.stopScanning()

    if (this.scanSubscription) {
      this.scanSubscription.unsubscribe()
    }
    
    let devices: IDevice[] = []
    this.updateState({scanning: {devices: devices, status: 'starting'}})
    // TODO: auto restart on error ?
    this.scanSubscription = this.ble.startScan(BLE_SCAN_CONFIG).subscribe({
      next: evt => {
        console.log('scan-status:', evt.status, evt)
        console.log('looking for device', lookForDevice)
        let status: IScanStatus = 'scanning'
        if (evt.status == 'scanStopped') {
          status = 'stopped'
        }

        if (evt.status == 'scanResult') {
          if (evt.name == lookForDevice) {
            let device = this.ensureDevice(evt.name, evt.address)
            let activeDevice = devices.find(x => x.address == device.address)
            if (!activeDevice) {
              activeDevice = device
              devices.push(activeDevice)
            }
            activeDevice.name = device.name
            activeDevice.stored = device.stored
            this.updateState({scanning: {devices: devices, status: status}})
            this.storeDevice(activeDevice)
            this.stopScanning()
          }
        }
      },
      error: err => {
        console.error('scan-error', err)
        this.updateState({ scanning: null })
        this.stopScanning()
        captureException(err)
      }
    })
  }

  private setDevices (devices: IDevice[]) {
    this.ngZone.run(() => {
      this.devices.next(devices)
    })
  }

  private ensureDevice (name: string, addr: string) {
    let current = this.devices.value.find(x => x.address == addr)
    if (!current) {
      let newDevice: IDevice = {
        name: name, address: addr, stored: false
      }
      this.setDevices([...this.devices.value, newDevice])
      return newDevice
    } else {
      if (name && current.name != name) {
        let newDevices = this.devices.value.map(device => {
          if (device.address == addr) return {...device, name: name}
          return device
        })
        this.setDevices(newDevices)
      }
    }
    return current
  }

  private async readDevice (device: { address: string }) {
    this.updateDeviceStatus(device.address, 'connecting')
    let discover = await this.ble.discover(device)
    if (discover.status == 'discovered') {
      this.updateDeviceStatus(device.address, 'reading')
      await this.startNotifications(device)
    }
  }

  private async _scheduleConnectDevice (device: IDevice) {
    // TODO: ensure that we don't get into an infinite loop with setTimeout
    if (!this.state.value.isAutoConnecting) {
      return console.warn('auto connect disabled, will not reschedule device connect')
    }
    if (!this.isReady()) {
      return console.warn('skipping auto reconnect, bluetooth is not ready')
    }
    await this.ensureInitialized()
    await this.ensureDeviceClosed(device)
    console.log(device.name, 'reschedule connection')
    window.setTimeout(() => {
      this.connectDevice(device)
    }, 1000)
  }

  private async connectDevice (device: IDevice) {
    let addr = device.address
    
    if (this.connectSubscriptions.has(addr)) {
      console.log(device.address, 'already connected')
      return this.connectSubscriptions.get(addr)
    }
    
    this.updateDeviceStatus(addr, 'ready')
    let connection = this.ble.connect({ address: addr, autoConnect: true }).pipe(
      tap(x => {
        this.updateDeviceStatus(addr, 'connected')
      }),
      tap(x => { 
        if (x.status == 'disconnected') throw new Error('DISCONNECTED') 
      }),
      filter(x => x.status == 'connected'),
      switchMap(x => this.readDevice(x))
    ).subscribe({
      next: (peripheral) => {
        debug('connect event:', peripheral)
      },
      error: err => {
        console.log(device.name, 'device disconnected')
        if (err.error == 'enable') {
          this.updateState({'isEnabled': false})
        } else {
          this._scheduleConnectDevice(device)
        }
        let sub = this.connectSubscriptions.get(addr)
        this.connectSubscriptions.delete(addr)
        if (sub) sub.unsubscribe()
        this.updateDeviceStatus(addr, 'disconnected')
      },
      complete: () => {
        this.updateDeviceStatus(addr, 'disconnected')
      }
    });

    this.connectSubscriptions.set(addr, connection)
    this.updateDeviceStatus(addr, 'connected')
    return connection
  }

  async disconnectDevice (device: IDevice, force=false) {
    if (!force && !this.connectSubscriptions.has(device.address)) {
      return
    }
    
    if (this.connectSubscriptions.has(device.address)) {
      this.connectSubscriptions.get(device.address).unsubscribe()
      this.connectSubscriptions.delete(device.address)
    }

    this.updateDeviceStatus(device.address, 'disconnecting')
    await this.disconnectAndCloseDevice(device)
    this.updateDeviceStatus(device.address, 'disconnected')    
  }

  async stopAutoConnect (force=false) {
    this.updateState({isAutoConnecting: false})
    if (this.autoSubscription) {
      this.autoSubscription.unsubscribe()
    }
    for (var device of this.devices.value) {
      await this.disconnectDevice(device, force)
    }
  }
  
  async autoConnect () {
    let isReady = this.isReady()
    if (!isReady) {
      console.warn('cannot connect to devices, bluetooth not ready')
      return await this.stopAutoConnect()
    }

    this.updateState({isAutoConnecting: true})
    this.autoSubscription = this.devices.subscribe(devices => {
      devices.map(device => {
        let isAllowed = this.allowedDevices.includes(device.name) && device.stored
        if (isAllowed) {
          this.connectDevice(device)
        } else {
          this.disconnectDevice(device)
        }
      })
    })
  }

  async ensureBluetoothEnabled () {
    let isEnabled = await this.ble.isEnabled()
    this.updateState({isEnabled: isEnabled.isEnabled})
    if (!isEnabled.isEnabled) {
      await this.ble.enable()
      let isEnabled = await this.ble.isEnabled()
      this.updateState({isEnabled: isEnabled.isEnabled})
      
      if (!isEnabled.isEnabled) {
        if (this.platform.is('android')) {
          this.diagnostic.switchToBluetoothSettings()
        } else {
          await this.permissions.openBluetoothSettings()
        }
      }

      return isEnabled.isEnabled
    }
    return isEnabled.isEnabled
  }

  async ensureInitialized () {
    console.log('ensure initialized')
    let isInitialized = await this.ble.isInitialized()
    this.updateState({isInitialized: isInitialized.isInitialized})
    if (!isInitialized.isInitialized) {
      this.ble.initialize(BLE_INIT_CONFIG).subscribe(
        (ble) => {
          console.log('initialize state:', ble.status)
          this.updateState({isInitialized: ble.status == 'enabled'})
        },
        (err) => {
          this.updateState({isInitialized: false})
        }
      );
      isInitialized = await this.ble.isInitialized()
      this.updateState({isInitialized: isInitialized.isInitialized})
      if (!isInitialized.isInitialized) {
        throw new Error('unable to initialize bluetooth')
      }
    }
  }

  handleDeviceError (err: any) {
    if (err.error === "isDisconnected") {
      // do nothing device was already disconnected
    } else if (err.error === 'neverConnected') {
      // okay, do nothing
    } else {
      captureException(err)
      throw err
    }
  }

  async ensureDeviceClosed(device: {address: string}) {
    return new Promise((resolve, reject) => {
      console.log(device.address, 'ensure closed')
      // NOTE: hack, sometimes it wouldn't complete close-device
      setTimeout(() => resolve(true), 3000)
      this._ensureDeviceClosed(device).then(r => resolve(r)).catch(err => reject(err))
    })
  }

  async _ensureDeviceClosed(device: { address: string }) {
    // NOTE: not sure what this is about
    if (this.platform.is('android')) {
      this.ble.disconnect(device)
      this.ble.close(device)
    }
    try {
      await this.ble.disconnect(device)
    } catch (err) {
      this.handleDeviceError(err)
    }
    try {
      await this.ble.close(device)
    } catch (err) {
      this.handleDeviceError(err)
    }
  }

  

  readingDevice: { address: string } | null = null
  async startNotifications(peripheral: { address: string }) {
    this.readingDevice = peripheral
    const address = peripheral.address
    let isConnected = await this.ble.isConnected({address: address})
    if (!isConnected.isConnected) {
      throw new Error('cannot start notification subscription, device is not connected')
    }
    
    // listen for request for current time from device
    this.ble.subscribe({
      address: peripheral.address,
      characteristic: REQUEST_TIME_CHARACTERISTIC,
      service: AVOCADO_SERVICE,
    }).subscribe((data) => {
        this.onRequestTimeStateChanged(data);
      }, (subscribeErr) => { this.onSubscriptionError(subscribeErr) }
    );

    // listen for request for current location from device
    this.ble.subscribe({
      address: peripheral.address,
      characteristic: REQUEST_LOCATION_CHARACTERISTIC,
      service: AVOCADO_SERVICE,
    }).subscribe((data) => {
      this.onRequestLocationStateChanged(data)
    }, (err) => { this.onSubscriptionError(err) }
    );

    // listen for request to syncronize
    this.ble.subscribe({
      address: peripheral.address,
      characteristic: REQUEST_SYNC_CHARACTERISTIC,
      service: AVOCADO_SERVICE,
    }).subscribe(
      (data) => {
        // NOTE: syncing is only success when the last measurement have been received
        this.onRequestSyncStateChanged(data).catch(err => {
          this.updateSyncing(address, {status: 'error'})
          this.handleDeviceError(err)
        })
      },
      (err) => { this.onSubscriptionError(err) }
    );

    // listen for result of measurements
    this.ble.subscribe({
      address: peripheral.address,
      characteristic: READ_SINGLE_MEASUREMENT_CHARACTERISTIC,
      service: AVOCADO_SERVICE,
    }).subscribe((data) => {
        this.onReadSingleMeasurement(data).then(measurement => {
          if (measurement != null) {
            this.updateSyncing(data.address, {latestMeasurement: measurement})
          }
        })
      }, (err) => { this.onSubscriptionError(err) }
    )
  }

  onSubscriptionError (err: Error) {

  }

  // Synchronize everything from Avocado to Guacamole
  async onRequestSyncStateChanged(syncRequestMessage: OperationResult) {
    if (
      typeof syncRequestMessage !== "undefined" &&
      typeof syncRequestMessage.value !== "undefined"
    ) {
      const bufString: string = remove_non_ascii(
        atob(syncRequestMessage.value)
      );
      if (bufString === "SYNC") {
        await this.setMTUAndReadMeasurementList(syncRequestMessage);
      }
    }
  }

  async setMTUAndReadMeasurementList(peripheral: OperationResult) {
    if (this.platform.is("android")) {
      try {
        await this.ble.mtu({address: peripheral.address, mtu: 460})
      } catch (err) {
        console.warn('failed set mtu on device', peripheral)
      }
    }
    await this.startReadingMeasurementList(peripheral);
  }

  // Everything regarding time below here
  async onRequestTimeStateChanged(timeRequestMessage: OperationResult) {
    if (typeof timeRequestMessage.value !== "undefined") {
      if (atob(timeRequestMessage.value).includes("GET_TIME")) {
        await this.sendTime(timeRequestMessage);
      } else if (atob(timeRequestMessage.value).includes('{"v":')) {
        // TODO: do we need this ?
        //const reqTimeEnabledMessage = atob(timeRequestMessage.value);
        //const reqTimeEnabledAsObj = JSON.parse(reqTimeEnabledMessage);
        
        //this.deviceVersion = reqTimeEnabledAsObj.v;
      }
    }
  }

  async sendTime(timeRequestMessage: OperationResult) {
    const nowAsArrayBuffer = str2ab32(Math.round(new Date().getTime() / 1000));
    const nowAsString = new Uint8Array(nowAsArrayBuffer);
    const now = this.ble.bytesToEncodedString(nowAsString);
    await this.ble.write({
      address: timeRequestMessage.address,
      characteristic: SEND_TIME_CHARACTERISTIC,
      service: AVOCADO_SERVICE,
      value: now,
    })
  }


  async onRequestLocationStateChanged(locationRequestMessage: OperationResult) {
    if (typeof locationRequestMessage.value !== "undefined") {
      if (atob(locationRequestMessage.value).includes("GET_LOC")) {
        let address = locationRequestMessage.address
        this.updateMeasurement(address, {status: 'progress'})
        try {
          await this.sendLocation(locationRequestMessage);
          this.updateMeasurement(address, {status: 'success'})
        } catch (err) {
          this.updateMeasurement(address, {status: 'error', error: err.message})
          this.handleDeviceError(err)
        }
      } else if (atob(locationRequestMessage.value).includes('{"v":')) {
        // TODO: do we need deviceVersion ?
        //const reqTimeEnabledMessage = atob(locationRequestMessage.value);
        //const reqTimeEnabledAsObj = JSON.parse(reqTimeEnabledMessage);
        //this.deviceVersion = reqTimeEnabledAsObj.v;
      }
    }
  }

  async sendLocation(locationRequestMessage: OperationResult) {
    // set default response to device
    const address = locationRequestMessage.address
    let buffer = str2ab16('{"latitude": ' + 0 + ', "longitude": ' + 0 + "}")
    try {
      let location = await this.location.getMobileLocation()
      const coords = location?.coords
      if (!coords) throw new Error('unable to get location')
      
      let [latitude, longitude] = [coords.latitude, coords.longitude]
      this.updateMeasurement(address, {latitude: latitude, longitude: longitude, status: 'progress'})

      let str = '{"latitude": ' + JSON.stringify(latitude) + ', "longitude": ' + JSON.stringify(longitude) + "}"
      buffer = str2ab16(str);
    } catch (err) {
      throw new BluetoothError('no_gps')
    } finally {
      console.log('send location:', buffer)
      const loctoSendAsUin8array = new Uint8Array(buffer);
      const loctoSend = this.ble.bytesToEncodedString(loctoSendAsUin8array);
      await this.writeLocation(loctoSend, locationRequestMessage);
    }
  }

  async writeLocation(location: string, locationRequestMessage: OperationResult) {
    await this.ble.write({
      address: locationRequestMessage.address,
      characteristic: SEND_LOCATION_CHARACTERISTIC,
      service: AVOCADO_SERVICE,
      value: location,
    })
  }

  async startReadingMeasurementList(syncRequestMessage: OperationResult) {
    const address = syncRequestMessage.address
    let measurementListString = await this.readMeasurementList(syncRequestMessage)
    
    // MeasurementList: { "data": [{ "Begin": 0 , "End": 25 } ,{ "Begin": 25 , "End": 35 }] }
    const measurementList = JSON.parse(measurementListString);

    let measurementIds = new Set<number>()
    for (let i = 0; i < measurementList.data.length; i++) {
      // gettings segments { "Begin": 0 , "End": 25 }
      for (let k = measurementList.data[i]["Begin"]; k < measurementList.data[i]["End"]; k++) {
        measurementIds.add(k);
      }
    }
    
    this.updateSyncing(address, {
      measurements: measurementIds, completed: new Set(), status: 'progress'
    })
  }

  async readMeasurementList(locationRequestMessage: OperationResult) {
    let buffer = await this.ble.read({
      address: locationRequestMessage.address,
      characteristic: READ_MEASUREMENT_LIST_CHARACTERISTIC,
      service: AVOCADO_SERVICE,
    })
    const data = new Uint8Array(
      this.ble.encodedStringToBytes(buffer.value)
    );
    let MeasurementList = "";
    for (let i = 0; i < data.length; i++) {
      MeasurementList = MeasurementList + String.fromCharCode(data[i])
    }
    return MeasurementList
  }

  async requestMeasurement (address: string, i: number) {
    const request = btoa(String.fromCharCode(i));
    await this.ble.write({
      address: address,
      characteristic: READ_SINGLE_MEASUREMENT_CHARACTERISTIC,
      service: AVOCADO_SERVICE,
      value: request
    })
  }

  async onReadSingleMeasurement(
    readSingleMeasurementRequestMessage: OperationResult
  ) {
    if (typeof readSingleMeasurementRequestMessage.value !== "undefined") {
      if (
        atob(readSingleMeasurementRequestMessage.value).includes("MEAS_READY")
      ) {
        return await this.readMeasurement(readSingleMeasurementRequestMessage);
      } else if (
        atob(readSingleMeasurementRequestMessage.value).includes('"device_uid"')
      ) {
        let address = readSingleMeasurementRequestMessage.address
        return await this.addMeasurementToDB(address, atob(readSingleMeasurementRequestMessage.value));
      }
    }
    return null
  }

  async readMeasurement(readSingleMeasurementRequestMessage: OperationResult) {
    let address = readSingleMeasurementRequestMessage.address
    let measurementAsB64 = await this.ble.read({
      address: address,
      characteristic: READ_SINGLE_MEASUREMENT_CHARACTERISTIC,
      service: AVOCADO_SERVICE,
    })
    const measurementAsString = atob(measurementAsB64.value);
    return await this.addMeasurementToDB(address, measurementAsString);
  }

  async addMeasurementToDB(address: string, measurementAsString: string) {
    const newMeasurement: MoistureMeasurement = this.parseMeasurement(
      remove_non_ascii(measurementAsString)
    );
    // NOTE: using address as meter_uuid, so it's always possible to get measurements from device-address
    newMeasurement.meter_uuid = address.replace(/\:/g, '')
    this.database.addMeasurement(newMeasurement);
    if (
      newMeasurement.timestamp != null &&
      newMeasurement.timestamp < 1546300800
    ) {
      // TODO: not sure what this is about
      //console.log("Timestamp is before 2019, therefore sending error via sentry");
    }
    return newMeasurement
  }

  parseMeasurement(measurementString: string) {
    const measurement: IBleMeasurement = JSON.parse(measurementString);

    let newMeasurement: MoistureMeasurement;
    
    newMeasurement = new MoistureMeasurement(
      measurement.device_uid,
      uuidv4(),
      measurement.measurements[0].id,
      measurement.measurements[0].data.moisture_content,
      measurement.measurements[0].data.crop_name,
      measurement.measurements[0].data.calibration_offset,
      measurement.measurements[0].data.temperature,
      Math.floor(new Date().getTime() / 1000),
      measurement.connection.device_id,
      measurement.connection.access_token
    );
    newMeasurement.setTimestamp(measurement.measurements[0].timestamp);
    if (
      measurement.measurements[0].data.longitude !== 0 &&
      measurement.measurements[0].data.longitude !== -1 &&
      measurement.measurements[0].data.longitude !== undefined
    ) {
      newMeasurement.setLocation(
        measurement.measurements[0].data.longitude,
        measurement.measurements[0].data.latitude
      );
    }
    return newMeasurement;
  }

  async disconnectAndCloseDevice(peripheral: { address: string }) {
    console.log(peripheral.address, 'disconnect and close')
    this.unsubscribeToAll(peripheral);

    const disconnParams = {
      address: peripheral.address,
    };
    try {
      await this.ble.disconnect(disconnParams)
      await this.closeDevice(peripheral);
    } catch (disconnectErr) {
      if (disconnectErr.error === "isDisconnected") {
        await this.closeDevice(peripheral);
      } else {
        this.handleDeviceError(disconnectErr)
      }
    }
  }

  async closeDevice(peripheral: { address: string }) {
    const closeParams = {
      address: peripheral.address,
    };
    try {
      await this.ble.close(closeParams)
      this.updateDeviceStatus(peripheral.address, 'disconnected')
    } catch (closeError) {
      this.handleDeviceError(closeError)
    }
  }

  unsubscribeToAll(peripheral: { address: string }) {
    const characteristics = [
      READ_SINGLE_MEASUREMENT_CHARACTERISTIC,
      REQUEST_SYNC_CHARACTERISTIC,
      REQUEST_TIME_CHARACTERISTIC,
      REQUEST_LOCATION_CHARACTERISTIC,
    ];

    for (const characteristic of characteristics) {
      this.ble.unsubscribe({
        address: peripheral.address,
        characteristic: characteristic,
        service: AVOCADO_SERVICE,
      }).then(
        (resp) => {
          console.log("Terminated " + JSON.stringify(resp) + " subscription");
        },
        (err) => {
          console.warn(
            "Could not terminate " + JSON.stringify(err) + " subscription"
          );
        }
      );
    }
  }
}

