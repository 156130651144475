//import 'leaflet/dist/leaflet.css';

import * as leaflet from 'leaflet'
import 'leaflet-providers';
import 'leaflet.markercluster'


import { Component, ElementRef, EventEmitter, Input, NgZone, Output, ViewChild } from '@angular/core';
import { Asset, Device, Entity } from 'src/app/models/entity.model';

import { CropLabel, entityURL, Index, LoadingState, makeMarkerURL, markerIcon, MEASUREMENT_PROFILES, MOISTURE_ICON, TelemetryKeyColor, TelemetryKeyLabel } from 'src/app/util';
import { EntityService } from 'src/app/services/entity.service';
import { ITelemetryValue } from 'src/app/models/telemetry.model';
import { FarmerService } from 'src/app/services/device.service';
import { LocationService } from 'src/app/services/location.service';
import { AlarmService } from 'src/app/services/alarm.service';
import { Subscription } from 'rxjs';
import { TEXT } from 'src/app/texts';
import { TranslateService } from '@ngx-translate/core';
import { SAMoistureMeasurement, MeasurementService } from 'src/app/services/measurement.service';
import moment from 'moment-es6';
import { PermissionService } from 'src/app/services/permissions.service';

const MEASUREMENT_TYPE = 'measurement'
const MARKER_ICON = makeMarkerURL(MOISTURE_ICON)

const DEFAULT_MARKER_ICON = '/assets/svg/spear_marker.svg'
export interface ILeafsetMarker {
  tooltip: string, type: string, icon: string,
  latitude: number, longitude: number
  moveable: boolean
}
export interface ILeafletOptions {
  latitude: number | null, longitude: number | null
  markers: Record<string, ILeafsetMarker> //{[key: string]: ILeafsetMarker}
}
export interface IMarkerEvent {
  id: string, latlng: leaflet.LatLng
}
interface ICoord {
  latitude: number, longitude: number
}

@Component({
  selector: 'agrolog-map',
  template: `
  <!--height: 100%; width: 100% position: relative;-->
<div style="display: flex; flex-grow: 1" >
  <!--width: 100%; height: 100%; -->
  <div style="background: black;" #mapElement (onMarkerMove)="markerMoved($event)"></div>      
  
  <div *ngIf="!map" style="position: absolute; left: 50%; top: 50%; transform: translate(-50%,-50%);">
    <div *ngIf="!permission">
      <button class="small-button" (click)="locate(true)">{{text.general.activate_location|translate}}</button>
    </div>
    <div *ngIf="permission && latestCoords == null && error">
      <button class="small-button" (click)="locate(true)">{{error}}</button>
    </div>
  </div>
  <div *ngIf="map && isReady" style="z-index: 99999">
    
    <ion-icon *ngIf="hasMarkers()" class="map-button" style="top: 10px; right: 10px; " (click)="fitView()" name="expand-outline"></ion-icon>
    <ion-icon class="map-button" style="right: 10px; bottom: 10px;z-index: 99999" (click)="locate(true)" name="locate"></ion-icon>
    <div *ngIf="canPlaceMarkers" style="height: 100%; position: absolute; z-index: 99999" [class.center]='!isMarkerPlaced'>
      <ion-icon class="map-button" 
        [class.centered]="!isMarkerPlaced"
        style="left: 10px; bottom: 10px;" (click)="placeMarker()" name="location"></ion-icon>
      <div class="button-text" *ngIf="!isMarkerPlaced">{{text.general.place_on_map|translate}}</div>
    </div>
  </div>
</div>
  `,
  styles: `
    :host {
      display: flex;
      flex-grow: 1;
    }
    .map-button {
      position: absolute; z-index: 9999;
      background: white;
      border-radius: 50px;
      width: 25px; height: 25px;
      padding: 5px;
    }
    .center {
      left: 50% !important;
      top: 50% !important;
    }
    .centered {
      transform: translate(-50%, -50%);
      left: 0 !important; top: 0 !important;
    }
    .button-text {
      background: white;
      padding: 5px;
      font-weight: bold;
      transform: translate(-50%, 20px);
      border-radius: 5px;
      text-transform: uppercase;
      font-size: 12px;
    }
  `
})
export class AgrologMap { 
  @Input() canPlaceMarkers: boolean = true
  @Input() markers: Record<string, ILeafsetMarker> = {}
  @Output('onMarkerMove') onMarkerMove: EventEmitter<IMarkerEvent> = new EventEmitter<IMarkerEvent>()
  @ViewChild('mapElement') mapElement: ElementRef<HTMLDivElement>;

  map: leaflet.Map
  isReady = false
  //hasInitialized = false
  permission: boolean = false
  //canShow = false
  //canPlaceMarker = false
  error: string | null = null
  latestCoords: ICoord
  isMarkerPlaced: boolean = true
  text = TEXT
  
  placeMarker () {
    let evt: IMarkerEvent = {
      id: '', latlng: this.getCenter()
    }
    this.onMarkerMove.emit(evt)
  }
  markerMoved (event) {
    
    //throw new Error('todo')
  }
  
  ngOnInit () {
    // NOTE: tab: false is needed on iphone
    
    //this.map.
    this.listen(this.location.onLocation.subscribe(loc => {
      if (loc?.coords) {
        this.latestCoords = loc.coords
      }
      if (loc?.error) {
        this.error = this.location.userErrorMessage()
      }
      this.refresh()
    }, err => {
      // TODO: ..
      //this.loading.error(this.location.userErrorMessage())
    }))
    this.permissionService.state.subscribe(perms => {
      this.permission = perms.GPS.enabled
      this.refresh()
    })
  }

  hasMarkers () { 
    return this.activeMarkers.length > 0
    //return this.markerGroup.getLayers().length > 0
  }

  refresh () {
    this.ngZone.run(() => {
      let hasMarker = Object.keys(this.markers).length > 0
      let firstMarker = false
      if (!this.isMarkerPlaced && hasMarker) {
        firstMarker = true
      }
      this.isMarkerPlaced = hasMarker
      
      let canShow = this.hasMarkers() || this.latestCoords != null
      if (!this.map && canShow) {
        this.createLeafletMap()
      }
      
      if (this.map && !this.isReady) {
        //if (false) {
        if (this.hasMarkers()) {
          this.fitView()
          this.isReady = true
        } else if (this.latestCoords) {
          this.setLocation(this.latestCoords)
          this.isReady = true
        } else if (this.permission) {
          this.location.getCurrentLocation()
        }
      } else if (this.map && firstMarker) {
        this.fitView()
      }
      this.invalidate()
    })
  }

  invalidate () {
    if (this.map) {
      this.map.invalidateSize()
      window.setTimeout(() => this.map.invalidateSize(), 1000)
    }
  }

  
  private markerGroup = leaflet.markerClusterGroup({
    maxClusterRadius: 20
  });
  private createLeafletMap () {
    let map = leaflet.map(this.el.nativeElement, {
      zoom: 18
    }) //, { "tap": false })
    let TOKEN = "pk.eyJ1IjoibWlra2VsamFucyIsImEiOiJja3F0ZmhxMXIwNjBjMnBxdWdqNnN2cjFlIn0.9vcUa3KYjdR3J7CXNr-l0Q"
    var satelliteLayer = leaflet.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
      attribution: '© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>',
      tileSize: 512,
      maxZoom: 18,
      zoomOffset: -1,
      id: 'mapbox/satellite-streets-v11',
      accessToken: TOKEN
    })
    satelliteLayer.addTo(map);
    this.markerGroup.addTo(map)
    satelliteLayer.on('tileerror', e => {
      console.error('TILE ERROR', e, e.error.message, e.error.name, e.error.stack)
      // TODO: the map sometimes freezes with tile error, this fixes it for some reason
      try {
        this.fitView()
      } catch (err) {
      }
    })
    this.map = map
    this.invalidate()
    console.log('leaflet initialized')
  }

  constructor (
    private location: LocationService,
    private el: ElementRef<HTMLElement>,
    private permissionService: PermissionService,
    private ngZone: NgZone
  ) {
  }

  _subscriptions: Subscription[] = []
  listen (s: Subscription) { this._subscriptions.push(s) }
  ngOnDestroy () { this._subscriptions.map(s => s.unsubscribe()); this._subscriptions = [] }
  
  ngOnChanges (changes) {
    let newMarkers = changes.markers?.currentValue
    if (newMarkers) {
      this.setMarkers(newMarkers)
    }
  }
  
  activeMarkers: leaflet.Marker<any>[] = []
  setMarkers (markers: Record<string, ILeafsetMarker>) {
    console.log('set markers', markers)
    // TODO: clearing all markers, should really only remove / add changes
    this.markerGroup.clearLayers()
    let newMarkers: leaflet.Marker<any>[] = []
    for (var k in markers) {
      let mopt = markers[k]
      let marker = leaflet.marker([mopt.latitude, mopt.longitude], {
        draggable: mopt.moveable
      })
      this.markerGroup.addLayer(marker)
      marker.on("dragend", (evt) => {
        let e = evt as any
        if (!e.target) return
        this.onMarkerMove.emit({id: e.target['_device_id'], latlng: e.target.getLatLng()})
      });
      let [width, height] = [50, 50]
      let icon = leaflet.icon({
        shadowSize: [0,0],
        shadowUrl: 'assets/svg/empty.svg',
        iconUrl: mopt.icon, 
        iconSize: [width, height],
        iconAnchor: [width / 2, height],
        popupAnchor: [0, -height]
      });
      marker['_device_id'] = k
      marker.setIcon(icon)
      
      if (mopt.tooltip) {
        marker.bindPopup(mopt.tooltip)
      }

      let pos = marker.getLatLng()
      if (pos.lat != mopt.latitude || pos.lng != mopt.longitude) {
        marker.setLatLng([mopt.latitude, mopt.longitude])
      }
      if (marker.getIcon()['iconUrl'] != mopt.icon) {
        let [width, height] = [50, 50]
        marker.setIcon(leaflet.icon({
          iconUrl: mopt.icon, 
          iconSize: [width, height],
          iconAnchor: [width / 2, height],
          popupAnchor: [0, -height]
        }));
      }
      newMarkers.push(marker)
    }
    this.activeMarkers = newMarkers
    this.refresh()
  }
  
  getCenter (): leaflet.LatLng {
    return this.map.getCenter()
  }


  fitView () {
    if (!this.hasMarkers()) {
      throw new Error('cannot fit view without markers')
    }
    let bounds: leaflet.LatLngBounds
    //let markers: any[] = this.markerGroup.getLayers()
    let markers = this.activeMarkers
    if (markers.length <= 0) throw new Error('no markers to fit to')
    Object.values(markers).map(marker => {
      if (!bounds) {
        bounds = leaflet.latLngBounds(marker.getLatLng(), marker.getLatLng())
      }
      bounds.extend(marker.getLatLng())
    })
    if (!bounds?.isValid()) throw new Error('fit bound is not valid')
    console.log('fit view', bounds)
    this.map.fitBounds(bounds)
    
    this.invalidate()
  }

  setLocation (loc: {latitude: number, longitude: number}) {
    console.log('set location..', loc)
    let pos = new leaflet.LatLng(loc.latitude, loc.longitude)
    this.map.setView(pos, 18)
  }

  async locate (force=false) { 
    console.log('locate', force, this.permission, this.latestCoords)
    if (force && !this.permission) {
      await this.permissionService.requestPermission('GPS', true)
    } else if (!this.permission) {
      return
    }
    
    // set last location first, getCurrentLocation might not respond
    if (this.latestCoords) this.setLocation(this.latestCoords)
    
    let loc = await this.location.getCurrentLocation(force)
    if (loc && loc?.coords) this.setLocation(loc.coords)
    else if (this.latestCoords) this.setLocation(this.latestCoords)
  }
  


  
}

type MAP_DISPLAY_TYPES = 'DEVICES' | 'MEASUREMENTS'

@Component({
  selector: 'devices-map',
  template: `<agrolog-map #map (onMarkerMove)="onMarkerMove($event)" [canPlaceMarkers]="canPlaceMarkers" [markers]="markers"></agrolog-map>`,
  styles: `
  :host {
    display: flex;
    flex-grow: 1;
  }
  `
})
export class DevicesMap {
  @Input() devices: Entity<any>[] = []
  @Input() canPlaceMarkers: boolean = true
  @Input() displayType: MAP_DISPLAY_TYPES = 'DEVICES'
  @Output() onMarkerMoved: EventEmitter<IMarkerEvent> = new EventEmitter()
  @ViewChild(AgrologMap) map:AgrologMap;

  text = TEXT
  markers: Record<string, ILeafsetMarker> = {}

  _subscriptions: Subscription[] = []
  listen (s: Subscription) { this._subscriptions.push(s) }
  ngOnDestroy () { this._subscriptions.map(s => s.unsubscribe()); this._subscriptions = [] }

  constructor (
    private farmer: FarmerService,
    private alarmService: AlarmService,
    public location: LocationService,
    private translate: TranslateService
  ) {}

  onMarkerMove (event: IMarkerEvent) {
    if (!event.id) {
      this.placeMarker(event)
    } else {
      let device = this.devices.find(d => d.id.id == event.id)
      if (device)
        this.saveLocation(device, event.latlng)
    }
    this.onMarkerMoved.emit(event)
  }

  async placeMarker (event: IMarkerEvent) {
    if (this.devices.length != 1) {
      throw new Error('cannot place marker for map with multiple devices')
    }
    if (!this.canPlaceMarkers) {
      throw new Error('place marker not allowed on this map')
    }
    let device = this.devices[0]
    let latlng = event.latlng
    if (device) {
      this.saveLocation(device, latlng)
      let newMarker = this.createMarker(device, latlng.lat, latlng.lng)
      if (newMarker) {
        this.markers = {...this.markers, [device.id.id]: newMarker}
      }
    }
  }

  saveLocation (device: Device, latlng: leaflet.LatLng) {
    this.farmer.getEntityModel(device).saveSettings({latitude: latlng.lat, longitude: latlng.lng})
  }

  createTooltip (device: Entity<any>, values: ITelemetryValue<number>[]) {
    function round (x: number) {
      if (!x) return '-'
      return Math.round(x * 100) / 100
    }
    let items = values.map(value => {
      let label = TelemetryKeyLabel(value.key).map(x => this.translate.instant(x)).join(' ')
      if (value.status == 'error') {
        let errLabel = this.translate.instant(TEXT.general.error_value)
        return `<b>${label}</b>
        <span style='color:#c0392b;font-weight:600'>${errLabel}</span>`
      }
      return `<b>${label}</b>
      <span style='color:${TelemetryKeyColor(value.key)};font-weight:600'> ${round(value.value)}</span>`
    })
    let tooltip = `
        <b><a href="${entityURL(device)}">${device.label}</a></b><br/>
        ${items.join('<br />')}
    `
    
    return tooltip
  }

  ngOnInit () {
    
    this.listen(this.alarmService.subscribe(alarms => {
      this.createDeviceMarkers()
    }))
    
    this.devices.map(device => {
      let model = this.farmer.getEntityModel(device)
      if (!model) return
      
      this.listen(model.subscribe(() => {
        this.createDeviceMarkers()
      }))
      model.load()
    })
  }

  createMarker (entity: Device, latitude: number, longitude: number) {
    let device = this.farmer.getEntityModel(entity)
    if (!device) return null
    let marker: ILeafsetMarker = {
      latitude: latitude, 
      longitude: longitude,
      moveable: true, 
      tooltip: '', type: device.entity.type, icon: DEFAULT_MARKER_ICON}
    if (marker.latitude && marker.longitude) {
      let hasAlarm = this.alarmService.listAlarms().find(x => x.originator.id == entity.id.id) != undefined
      let icon = markerIcon(device.entity.type, hasAlarm)
      let values = device.getValues()
      
      let leafletMarker: ILeafsetMarker = {
        type: device.entity.type, icon: icon,
        tooltip: this.createTooltip(device.entity, values),
        latitude: marker.latitude, longitude: marker.longitude,
        moveable: true
      }
      return leafletMarker
    }
    return null
  }

  createDeviceMarkers () {
    let newMarkers: Record<string, ILeafsetMarker> = {}
    this.devices.map(entity => {
      let device = this.farmer.getEntityModel(entity)
      if (!device) return

      let leafletMarker = this.createMarker(entity,
        parseFloat(device.settings.latitude as any), 
        parseFloat(device.settings.longitude as any)
      )
      if (leafletMarker) {
        newMarkers[device.entity.id.id] = leafletMarker
      }
    })

    this.markers = newMarkers
  }

}



@Component({
  selector: 'measurement-map',
  template: `
  <agrolog-map #map [markers]="markers" [canPlaceMarkers]="canPlaceMarkers"></agrolog-map>
  `,
  styles: `
  :host {
    display: flex;
    flex-grow: 1;
  }
  .map-button {
    position: absolute; z-index: 9999;
    background: white;
    border-radius: 50px;
    width: 25px; height: 25px;
    padding: 5px;
  }
  `
})
export class MeasurementMap {
  @Input() devices: Device[]
  @Input() canPlaceMarkers = false
  @ViewChild(AgrologMap) map: AgrologMap;

  loading = new LoadingState()
  //options: ILeafletOptions
  text = TEXT
  markers: Record<string, ILeafsetMarker> = {}
  _subscriptions: Subscription[] = []

  constructor (
    private translate: TranslateService,
    private measurements: MeasurementService
  ) {
  }


  listen (s: Subscription) { this._subscriptions.push(s) }
  ngOnDestroy () { 
    this._subscriptions.map(s => s.unsubscribe()); this._subscriptions = [] 
  }

  loadMeasurements (measurements: SAMoistureMeasurement[]) {
    
    let markers: Index<ILeafsetMarker> = {}
    let numInvalid = 0
    measurements.map(m => {
      if (!m.latitude || !m.longitude) {
        numInvalid += 1
        return // console.warn('measurement have no location')
      }
      let temperatureHtml = ''

      let temperatureText = this.translate.instant(this.text.general.temperature)
      let cropText = this.translate.instant(this.text.general.crop)
      let moistureText = this.translate.instant(this.text.general.moisture)

      if (m.temperature != null) {
        temperatureHtml = `
        <b>${temperatureText}:</b>
        <span style='color:${TelemetryKeyColor('temperature-')};font-weight:600'> ${m.temperature.toFixed(2)}%</span>
        <br/>
        `
      }

      let cropLabel = this.translate.instant(CropLabel(m.crop_name))
      let tooltip = `
        <b>${moment(m.timestamp).format("MMM DD YYYY - hh:mm")}</b>
        <br/>
        <b>${cropText}:</b>
        <span style='font-weight:600'> ${cropLabel}</span>
        <br/>
        <b>${moistureText}:</b>
        <span style='color:#3498db;font-weight:600'> ${m.moisture_content?.toFixed(2)}%</span>
        <br/>
        ${temperatureHtml}
        <span>${m.comment || ''}</span>
      `
      
      let marker: ILeafsetMarker = {
        latitude: m.latitude, longitude: m.longitude, moveable: !m.ble_id,
        tooltip: tooltip, type: MEASUREMENT_TYPE, icon: MARKER_ICON
      }
      markers[m.timestamp] = marker
    })
    if (numInvalid) {
      console.log(numInvalid, 'measurements without location')
    }
    this.markers = {...markers}
  }

  onMarkerMove () {}
  placeMarker () {}

  ngOnChanges (changes) {
    this.init()
  }

  ngOnInit () {
    this.init()
  }

  init () {
    let entityIds = this.devices.map(x => x.id)
    this.listen(
      this.measurements.subscribe({entityIds: entityIds}).subscribe(measures => {
        this.loadMeasurements(measures)
      })
    )
  }
}




@Component({
  template: `
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-back-button defaultHref="/"></ion-back-button>
      </ion-buttons>
      <ion-title mode="md" [translate]="text.menu.map_view"></ion-title>
      <!--<ion-buttons slot="end">
        <ion-button class="page-button" [class.active]="displayType == 'DEVICES'" (click)="showDevices()">Devices</ion-button>
        <ion-button class="page-button" [class.active]="displayType == 'MEASUREMENTS'" (click)="showMeasurements()">Measurements</ion-button>
      </ion-buttons>-->      
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <div class="flex flex-row" style="height: 100%" *ngIf="loaded">
      <devices-map  *ngIf="displayType == 'DEVICES'" [canPlaceMarkers]="false" [devices]="devices"></devices-map>
      <measurement-map class="flex flex-row" *ngIf="displayType == 'MEASUREMENTS'" [devices]="measurementDevices" ></measurement-map>
    </div>
  </ion-content>
  `,
  styles: `

.page-button {
  opacity: 0.7;
}
.active {
  opacity: 1.0;
  background: rgba(237, 109, 5, 0.8);
  color: #FEFEFE;
}
  `
})
export class DevicesMapPage {
  devices: (Device|Asset)[]
  measurementDevices: Device[]
  loaded = false
  displayType: MAP_DISPLAY_TYPES = 'DEVICES'
  text = TEXT

  constructor (
    private entityService: EntityService
  ) {}

  ngOnInit () {
    this.load()
  }

  ngOnChanges () {
  }

  showDevices () {
    this.displayType = 'DEVICES'
  }
  showMeasurements () {
    this.displayType = 'MEASUREMENTS'
  }

  async load () {
    let assets = await this.entityService.getCustomerAssets()
    let devices = (await this.entityService.getDevicesByType()).data
    this.devices = [...assets, ...devices]
    
    this.measurementDevices = devices.filter(device => {
      return MEASUREMENT_PROFILES.includes(device.type)
    })
    this.loaded = true
  }
}