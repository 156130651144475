
import { agTypes } from '../../ag-types'
import { LoadingState, makeMarkerURL, MOISTURE_ICON, removeNullValues } from '../../util'
import { Component, Input } from '@angular/core';
import { Device, EntityId, EntityType } from 'src/app/models/entity.model';
import { FarmerService } from 'src/app/services/device.service';
import { AlertController, ModalController, PopoverController } from '@ionic/angular';
import { ILeafletOptions, ILeafsetMarker, IMarkerEvent } from '../device-map/devices-map';
import { HttpClient } from '@angular/common/http';

import { Camera, CameraResultType } from '@capacitor/camera'; 
import * as leaflet from 'leaflet'
import { ActivatedRoute } from '@angular/router';

import { AttributeService } from 'src/app/services/entity.service';
import { LocationService } from 'src/app/services/location.service';

import { firstValueFrom, Subscription } from 'rxjs'

import { TEXT } from 'src/app/texts';
import { TranslateService } from '@ngx-translate/core';
import { DEFAULT_LOCALE, FARMER_CROPS } from 'src/app/constant';
import { MeasurementService, SAMoistureMeasurement, uuidv4 } from 'src/app/services/measurement.service';
import { BluetoothService, IDevice } from 'src/app/services/bluetooth.service';
import { TimeQuerySettings } from 'src/app/menus';


// TODO: .. don't know how to handle type for non-entity for map
export const MEASUREMENT_TYPE = 'measurement'
export const MARKER_ICON = makeMarkerURL(MOISTURE_ICON)



@Component({
  selector: 'measurement-map-page',
  template: `
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-back-button defaultHref="/"></ion-back-button>
      </ion-buttons>
      <ion-title mode="md" [translate]="text.general.measurement_map"></ion-title>
      <ion-buttons slot="end">
        <ion-button (click)="openTimeWindowMenu($event)">
          <ion-icon slot="icon-only" name="time-outline" ></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content>
  <ag-loading [loading]="loading" message="{{text.general.loading|translate}}"></ag-loading>
  <div class="flex flex-row" style="height: 100%">
    <measurement-map [canPlaceMarkers]="false" [devices]="devices"></measurement-map>
  </div>
  <!--<div style="width: 100%; height: 100%; position: relative;">
    <div style="width: 100%; height: 100%;" devicesMap (onMarkerMove)="onMarkerMove()" [options]="options"></div>
    <ion-icon class="map-button" style="top: 10px; right: 10px;" (click)="fitView()" name="expand-outline"></ion-icon>
    <ion-icon class="map-button" style="right: 10px; bottom: 10px;" (click)="locate()" name="locate"></ion-icon>
  </div>-->
</ion-content>`,
styles: [`
.map-button {
    position: absolute; z-index: 9999;
    background: white;
    border-radius: 50px;
    width: 25px; height: 25px;
    padding: 5px;
  }
`]
})
export class MeasurementMapComponent {
  @Input() entityId: string

  //@ViewChild(DevicesMapDirective) map:DevicesMapDirective;

  devices: Device[] = []
  loading = new LoadingState()
  text = TEXT
  _subscriptions: Subscription[] = []

  constructor (
    private route: ActivatedRoute,
    private measurements: MeasurementService,
    private popoverController: PopoverController
  ) {}

  async openTimeWindowMenu (event) {
    let popover = await this.popoverController.create({
      component: TimeQuerySettings,
      event: event,
      translucent: true
    });
    return popover.present();
  }


  listen (s: Subscription) { this._subscriptions.push(s) }
  ngOnDestroy () { 
    this._subscriptions.map(s => s.unsubscribe()); this._subscriptions = [] 
  }

  ngOnInit () {
    this.route.params.subscribe(params => {
      this.entityId = params['entityId'];
      let entityId: EntityId<EntityType.DEVICE> = {entityType: EntityType.DEVICE, id: this.entityId}
      this.measurements.getMeasurementDevice(entityId).subscribe(x => {
        this.devices = [x.entity]
        this.loading.success()
      })
    });
    
  }
}


export interface MeasurementDeviceSettings {
  label: string
}

@Component({
  selector: 'superpro-settings',
  template: `
<ion-header>
  <ion-toolbar>
    <ion-title mode="md">{{settings.label}} {{text.general.settings|translate}}</ion-title>
    <ion-buttons slot="primary">
      <ion-button (click)="dismiss()" >
          <ion-icon name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="dialog-content">
    <!--<ion-list>
        <ion-item>
            <ion-label>Serial: {{entity.name}}</ion-label>
        </ion-item>
    </ion-list>-->
    <ion-item>
      <ion-label position="stacked" [translate]="text.general.device_name"></ion-label>
      <ion-input [(ngModel)]="settings.label" placeholder="{{text.general.label|translate}}"></ion-input>
    </ion-item>

    <div style="margin-top: 50px;">
      <ion-item>
        <ion-button *ngIf="bleDevice" color="danger" (click)="unpairDevice()">Remove Pairing</ion-button>
      </ion-item>
      <ion-item>
        <ion-button color="danger" (click)="unclaimDevice()" [translate]="text.device.remove_device"></ion-button>
      </ion-item>
    </div>
  </div>
  <ion-fab vertical="bottom" horizontal="end" slot="fixed" (click)="saveSettings()">
    <ion-fab-button color="primary">
        <ion-icon name="checkmark-outline"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-content>
  `,
  styleUrls: ['super-pro.scss']
})
export class SuperProSettingsComponent {
  @Input() settings: MeasurementDeviceSettings
  @Input() entity: Device

  constructor (
    private farmer: FarmerService, 
    private measurements: MeasurementService,
    private dialogRef: ModalController,
    private alertController: AlertController, 
    private translate: TranslateService,
    private ble: BluetoothService
  ) {}

  text = TEXT

  dismiss (data?) { this.dialogRef.dismiss(data) }

  bleDevice: IDevice | null = null
  ngOnInit () {
    
    //this.settings.label = this.entity.label
    
    this.ble.devices.subscribe(devices => {
      this.bleDevice = devices.find(x => x.name == this.entity.name && x.stored)
    })
  }

  async unpairDevice () {
    this.ble.removeDevice(this.bleDevice)
  }

  async unclaimDevice () {
    let unclaimPrompt = await this.alertController.create({
      cssClass: 'bold-alert',
      header: this.translate.instant(this.text.settings.confirm_remove_device_title),
      message: this.translate.instant(this.text.settings.confirm_remove_device),
      buttons: [
        {
          text: this.translate.instant(this.text.general.no), // 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
          }
        }, {
          text: this.translate.instant(this.text.general.yes), // 'Yes',
          handler: async () => {
            await this.farmer.unclaimDevice(this.entity)
            this.dismiss({redirect: '/'})
          }
        }
      ]
    });

    await unclaimPrompt.present();
  }

  async saveSettings () {
    // TODO: should be in ngOnInit
    let device = await firstValueFrom(this.measurements.getMeasurementDevice(this.entity.id))
    await device.saveSettings(this.settings)
    this.dialogRef.dismiss()
  }


}



function utcTimestamp () {
  let date = new Date()
  let ts = new Date(date.getTime() + date.getTimezoneOffset() * 60000).getTime() / 1000;
  return Math.round(ts)
}

const IMAGE_KEY = 'image'

@Component({
  selector: 'add-measure-dialog',
  template: `

<ion-header>
    <ion-toolbar>
    <ion-title mode="md" [translate]="text.general.edit_measurement"></ion-title>
    <ion-buttons slot="primary">
        <ion-button (click)="close()" >
            <ion-icon name="close-outline"></ion-icon>
        </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content class='measurement-dialog' style="">
  <div class="background" (click)="dialogRef.dismiss()" ></div>
  <div class="dialog-card" style="margin-bottom: 100px;">
  <ion-item *ngIf="hasCrop">
    <ion-label>{{text.general.crop_type|translate}}:</ion-label>
    <!--ok-text="Okay" cancel-text="Cancel"-->
    <ion-label *ngIf="editMeasurement?.ble_id">
      {{editMeasurement.crop_name|cropLabel|async}}
    </ion-label>
    <ion-select *ngIf="!editMeasurement?.ble_id" [(ngModel)]="editMeasurement.crop_name" interface="popover" >
      <ion-select-option value="">{{text.general.no_crop|translate}}</ion-select-option>
      <ion-select-option *ngFor="let crop of crops" [value]="crop.key">{{crop.title|translate}}</ion-select-option>
    </ion-select>
  </ion-item>
  <ion-item>
    <ion-label>{{text.general.moisture|translate}}:</ion-label>
    <ion-label *ngIf="!editMeasurement?.ble_id">
      <ion-input type="number" [(ngModel)]="editMeasurement.moisture_content"></ion-input>
    </ion-label>
    <ion-label *ngIf="editMeasurement?.ble_id">{{editMeasurement?.moisture_content}}</ion-label>
  </ion-item>
  <ion-item *ngIf="canMeasureTemperature">
    <ion-label>{{text.general.temperature|translate}}:</ion-label>
    <ion-label *ngIf="!editMeasurement?.ble_id">
      <ion-input  type="number" [(ngModel)]="editMeasurement.temperature"></ion-input>
    </ion-label>
    <ion-label *ngIf="editMeasurement?.ble_id">{{editMeasurement?.temperature}}</ion-label>
  </ion-item>
  <div class="flex flex-row" style="height: 300px; padding: 10px; position: relative">
    <agrolog-map (onMarkerMove)="onMarkerMove($event)" [canPlaceMarkers]="true" [markers]="markers"></agrolog-map>
  </div>
  
  <ion-item *ngIf="!editMode">
    <ion-label>{{text.general.time|translate}}:</ion-label>
    <!--<div class="item item-icon-left" ion-datetime-picker ng-model="measurementDate">
        <i class="icon ion-ios-grid-view-outline positive"></i>
        Basic datetime picker:
        <strong>{{measurementDate| date: "yyyy-MM-dd H:mm"}}</strong>
    </div>-->
    <!-- display-format="DD/MMMM/YYYY hh:mm" picker-format="DD/MMMM/YYYY hh:mm" -->
    <!--<ion-datetime [disabled]="editMode" (ionChange)="setDate($event)"  
      [value]="measurementDate" [max]="now"></ion-datetime>-->
    <ion-datetime-button datetime="datetime"></ion-datetime-button>

    <ion-modal [keepContentsMounted]="true">
      <ng-template>
        <ion-datetime 
          [locale]="locale"
          show-default-buttons="true" 
          id="datetime"   
          (ionChange)="setDate($event)"
          [(ngModel)]="measurementDate"
          [max]="now" ></ion-datetime>
           <!--*ngIf="measurementDate" [max]="now"-->
          <!--(ionChange)="setDate($event)" [value]="measurementDate | date" -->
      </ng-template>
    </ion-modal>
  </ion-item>
  <ion-item *ngIf="editMode">
    <ion-label>{{text.general.time|translate}}:</ion-label>
    <ion-label>{{editMeasurement.timestamp | date: "yyyy-MM-dd H:mm"}}</ion-label>
  </ion-item>
  <div>
  <div>
    <ion-textarea [(ngModel)]="editMeasurement.comment" placeholder="{{text.general.comment|translate}}"></ion-textarea>
  </div>
  <div>
    <ion-button (click)="takePhoto()" [translate]="text.general.take_photo"></ion-button>
  </div>
  <div *ngIf="photo" >
    <img [src]="photo" />
  </div>
  
  </div>
</div>
  <ion-fab vertical="bottom" horizontal="end" slot="fixed" (click)="addMeasurement()">
    <ion-fab-button [disabled]="!validate()" color="primary">
        <ion-icon name="checkmark-outline"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-content>
  `,
  styleUrls: ['super-pro.scss'],
  styles: [`

.background {
  filter: blur(3px);
  position: absolute;
  z-index: -1;
}

.dialog-card {
  margin-bottom: 10px;
  border-radius: 7px;
  margin: 5px;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0 2px 4px -1px rgba(0,0,0,0.2), 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12);
}
.measurement-dialog {
}
.map-button {
  position: absolute; z-index: 9999;
  background: white;
  border-radius: 50px;
  width: 25px; height: 25px;
  padding: 5px;
}
.asset-comment-input {
  width: 100%;
  padding: 9px;
  resize: none;
  border-color: #d5dce0;
  border-radius: 2px;
  outline: none;
}
    `]
})
export class AddMeasurementDialogComponent {
  @Input() entity: Device
  @Input() measurement: SAMoistureMeasurement
  //@ViewChild(DevicesMapDirective) map:DevicesMapDirective;

  constructor (
    public dialogRef: ModalController,  // TODO: make private
    private http: HttpClient,
    private attributeService: AttributeService, 
    private geo: LocationService,
    private translate: TranslateService,
    private measurements: MeasurementService
  ) {}

  text = TEXT
  crops = Object.values(FARMER_CROPS)
  editMeasurement: SAMoistureMeasurement
  options: ILeafletOptions
  editMode = false
  loading = true
  canMeasureTemperature = false
  hasCrop = false
  measurementDate
  now = new Date().toISOString()

  markers: Record<string, ILeafsetMarker> = {}
  
  locale = DEFAULT_LOCALE
  _subscriptions: Subscription[] = []
  listen (s: Subscription) { this._subscriptions.push(s) }
  ngOnDestroy () { this._subscriptions.map(s => s.unsubscribe()); this._subscriptions = [] }

  updateLocale () {
    this.locale = this.translate.currentLang
  }

  close () { this.dialogRef.dismiss() }

  photo = ''
  origPhoto = ''
  async takePhoto () {
    const image = await Camera.getPhoto({
      quality: 90, height: 400,
      allowEditing: false,
      resultType: CameraResultType.DataUrl
    });
    this.photo = image.dataUrl
  }

  

  loadEditPhoto () {
    this.attributeService.getEntityTimeseries(
      this.entity.id, [IMAGE_KEY], this.editMeasurement.timestamp - 1, this.editMeasurement.timestamp + 1, 1
      ).subscribe((data) => {
        if (data[IMAGE_KEY]) {
          this.photo = data[IMAGE_KEY][0].value;
          this.origPhoto = data[IMAGE_KEY][0].value;
        }
      })
  }

  validate () {
    let m = this.editMeasurement
    let base = m && typeof m.moisture_content == 'number' 
           //&& typeof m.latitude == 'number' && typeof m.longitude == 'number'
    return base && (!this.canMeasureTemperature || typeof m.temperature == 'number')
  }

  onMarkerMove (event: IMarkerEvent) {
    console.log('ON MARKER MOVED', event)
    this.set_latlng(event.latlng)
  }
  /*onMarkerPlaced (latlng: leaflet.LatLng) {
    this.set_latlng(latlng)
  }*/

  set_latlng (latlng) {
    Object.assign(this.editMeasurement, {
      latitude: latlng.lat, longitude: latlng.lng
    })
    this.refresh_markers(this.editMeasurement)
  }
  
  setDate (evt) {
    let ts = new Date(this.measurementDate).getTime()
    this.editMeasurement.timestamp = ts
  }

  ngOnInit () {
    this.listen(this.translate.onLangChange.subscribe(x => this.updateLocale()))
    this.updateLocale()

    let date = new Date();
    let myDate = new Date(date.getTime() - date.getTimezoneOffset()*60000).toISOString();
    this.now = myDate
    this.measurementDate = myDate // date.toISOString() // myDate // new Date().toISOString()
    this.canMeasureTemperature = [
      agTypes.farmerDeviceTypes.superproCombi.value,
      agTypes.farmerDeviceTypes.cProBT.value
    ].includes(this.entity.type) 
    this.hasCrop = this.entity.type != agTypes.farmerDeviceTypes.superproCombi.value
    
    this.photo = ''
    this.origPhoto = ''
    if (this.measurement) {
      this.editMode = true
      this.editMeasurement = Object.assign({}, this.measurement)
      this.loadEditPhoto()
    } else {
      this.editMeasurement = {
        comment: '', crop_name: '', moisture_content: undefined, timestamp: new Date().getTime(),
        latitude: undefined, longitude: undefined, temperature: undefined,
        ble_id: null
      }
      
    }
    let options: ILeafletOptions = {
      latitude: this.editMeasurement.latitude || null,
      longitude: this.editMeasurement.longitude || null,
      markers: {}
    }
    this.options = options
    this.refresh_markers(options)
  }

  refresh_markers (latlng: {latitude?: number, longitude?: number}) {
    let options = {...this.options}
    if (latlng.latitude && latlng.longitude) {
      options.markers = {default: {icon: MARKER_ICON, moveable: !this.measurement?.ble_id,
        longitude: latlng.longitude, latitude: latlng.latitude, tooltip: '', type: MEASUREMENT_TYPE}
      }
    } else {
      let latlng = this.geo.current?.coords // this.map.getCenter()
      if (latlng) {
        options.markers = {
          default: {icon: MARKER_ICON, moveable: !this.measurement?.ble_id,
            longitude: latlng.longitude, latitude: latlng.latitude, tooltip: '', type: MEASUREMENT_TYPE
          }
        }
        Object.assign(this.editMeasurement, {
          latitude: latlng.latitude, longitude: latlng.longitude
        })
      }
    }
    Object.assign(this.options, options)
    this.options = options
    this.markers = {...options.markers}
  }

  ngAfterViewInit() {
    this.loading = false
  }

  async addMeasurement () {
    await this.save()
    this.dialogRef.dismiss(this.editMeasurement)
  }

  async save () {
    if (!this.validate()) throw new Error('invalid')
    
    let ts = this.editMeasurement.timestamp
    if (!ts) ts = new Date().getTime()
    let postMeasurement = Object.assign({}, this.editMeasurement)
    delete postMeasurement.timestamp

    let timestamp = utcTimestamp()
    let saMeasurement: SAMoistureMeasurement = {
      comment: postMeasurement.comment,
      description: 'App Created Measurement',
      latitude: postMeasurement.latitude,
      longitude: postMeasurement.longitude,
      measurement_type: 'app_moisture',
      measurement_uuid: uuidv4(),
      time_of_transfer: timestamp,
      timestamp: timestamp,
      moisture_content: postMeasurement.moisture_content,
      temperature: postMeasurement.temperature,
      calibration_offset: 0,
      crop_name: postMeasurement.crop_name
    }
    let values = removeNullValues(saMeasurement)
    
    await this.measurements.postMeasurement(this.entity, ts, values)
    if (this.photo && this.photo != this.origPhoto) {
      await this.measurements.postMeasurement(this.entity, ts, {image: this.photo})
      this.origPhoto = this.photo
    }
  }

}

