import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  isLoading = false;

  constructor(public loadingController: LoadingController) { }

  async present(message) {
    this.isLoading = true;
    return await this.loadingController.create({
      message: message,
    }).then(a => {
      a.present().then(() => {
        // console.log('Loader presented');
        if (!this.isLoading) {
          a.dismiss().then(() => console.log('Aborted loader'));
        }
      });
    });
  }

  async presentWithDismiss(message: string, timeout: number) {
    this.isLoading = true;
    return await this.loadingController.create({
      message: message,
    }).then(a => {
      a.present().then(() => {
        // console.log('Loader presented');
        if (!this.isLoading) {
          a.dismiss().then(() => console.log('Aborted loader'),
            err => {
              console.log('Cannot close loader, since it has already been closed');
            }
          );
        }
        setTimeout(() => {
          a.dismiss();
          this.isLoading = false;
        }, timeout);
      },
      err => {
        console.log('Cannot close loader, since it has already been closed');
      });
    });
  }

  async presentWithDismissAndFailmessage(message: string, fallbackMessage: string, timeout: number) {
    const that = this;
    this.isLoading = true;
    return await this.loadingController.create({
      message: message,
    }).then(a => {
      a.present().then(() => {
        console.log('Loader presented');
        if (!this.isLoading) {
          a.dismiss().then(() => console.log('Aborted loader'),
            err => {
              console.log('Cannot close loader, since it has already been closed');
            }
          );
        }
        setTimeout(() => {

          if (this.isLoading === true) {
            a.dismiss();
            that.presentWithDismiss(fallbackMessage, 4000);
          } else {
            a.dismiss();
          }
        }, timeout);
      });
    });
  }

  async dismiss() {
    this.isLoading = false;
    return await this.loadingController.dismiss().then(() => console.log('Loader dismissed'),
      err => {
        console.log('Cannot close loader, since it has already been closed');
      }
    );
  }
}
