import { Component, Input } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { agTypes } from "src/app/ag-types";
import { Device } from "src/app/models/entity.model";
import { AggregationType } from "src/app/models/telemetry.model";
import { FarmerService } from "src/app/services/device.service";
import { EntityService } from "src/app/services/entity.service";
import { TelemetryService } from "src/app/services/telemetry";



@Component({
  template: `
<div *ngIf="loaded" style="height: 100%">
<ag-plot style="height: 100%" [entity]="entity.id" [timeseries]="timeseries" [axis]="flotSettings.axes" ></ag-plot>
</div>
`,
  selector: 'test-plot'
})
export class TestPlot {
  entityId: string
  entity: Device
  loaded = false

  flotSettings: any = {
    shadowSize: 0,
    axes: [
      {
        position: 'left', showLabels: false
      },
      {
        position: 'bottom',
        showLabels: false
      }
    ],
    grid: {
      outlineWidth: 0,
      margin: 0,
      minBorderMargin: 0
    },
    smoothLines: true
  };
  timeseries: any = [
    {
      name: 'test-telemetry',
      label: " Test Telemetry",
      color: agTypes.colors.maxColor
    }
  ]

  constructor (
    private farmer: FarmerService, 
    private telemetry: TelemetryService,
    private entityService: EntityService,
    private route: ActivatedRoute) {}

  async load () {
    this.entity = await this.entityService.getDeviceById(this.entityId)
    this.loaded = true
  }

  ngOnInit () {
    let now = new Date().getTime()
    this.telemetry.updateSettings({
      agg: AggregationType.AVG, interval: 1000 * 10, timeWindow: 1000 * 60 * 30,
      mode: 'TIME-WINDOW', startTs: 0, endTs: 0
    })
    this.route.params.subscribe(params => {
      this.entityId = params['entityId'];
    });
    this.load()
  }
}