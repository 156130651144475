<ion-header translucent="true">

  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button defaultHref="/"></ion-back-button>
    </ion-buttons>
    <ion-title mode="md">{{text.general.alarms | translate}}</ion-title>
    <ion-buttons slot="primary">
      <ion-button (click)="clearAll()">
        <ion-label>{{text.alarm.clear_all | translate}}</ion-label>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  
</ion-header>
<ion-content>
  <ag-loading [loading]="loading" message="{{text.alarm.clearing | translate}}"></ag-loading>
  <div class="ag-card" style="padding: 15px;" *ngIf="!hasPermission">
    <permission-error type="NOTIFICATION" message="{{text.permission.notifications_denied | translate}}"></permission-error>
  </div>
  <div *ngFor="let group of groups">
    <entity-alarm-group [name]="group.name" [alarms]="group.alarms" (onClick)="openEntity(group.id)" ></entity-alarm-group>
  </div>
</ion-content>