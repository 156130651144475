import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Asset, EntityId } from "src/app/models/entity.model";
import { FarmerService } from "src/app/services/device.service";
import { EntityService } from "src/app/services/entity.service";
import { UnitService } from "src/app/services/unit";
import { Index, indexColor, LoadingState } from "src/app/util";
import { IChartInfo, ITimeserie } from "../ag-plot/ag-plot";
import { FarmerBuilding } from "./farmer-building.model";
import { gradientColorValue } from "src/app/util";
import { agTypes } from '../../ag-types'
import moment from "moment-es6";
import { ISensorType, SENSOR_TYPE, TEMPERATURE_SENSOR } from "src/app/services/types.service";
import { Subscription } from "rxjs";
import { TEXT } from "src/app/texts";

// '../farmer-silo/farmer-silo.scss'
@Component({
  selector: 'farmer-building-history',
  styleUrls: ['farmer-building.scss'],
  styles: [`
  
.cell-checkbox {
  display: block;
  width: 25px; height: 25px;
  margin: 5px;
}
  `],
  template: `
<ng-template #cellTemplate let-cell="cell" > 
  <ion-checkbox *ngIf="cell.selectable" class="cell-checkbox" (ionChange)="onCheckChange(cell, $event)" [(ngModel)]="selection[cell.row.entityId.id + ':' + cell.key]" 
  ></ion-checkbox>
</ng-template>

<div class="flex flex-column ag-card flex-auto" style="padding: 8px 4px 4px;">
  <div class="flex temp-history-container md-padding">
    
    <ag-plot 
      [height]="260" [figures]="sensorChart.figures" 
      [entity]="model.asset.id" [axis]="sensorChart.axes"
      [emptyMessage]="sensorChart.timeseries?.length == 0 ? text.building.select_sensors : null"
      [timeseries]="sensorChart.timeseries"></ag-plot>
  </div>

  <farmer-building-spear-table 
    (onSelect)="onSensorSelection($event)"
    [minValue]="0" [maxValue]="50"
    [cellTemplate]="cellTemplate"
    [model]="model" [sensorType]="sensorType.id" ></farmer-building-spear-table>

  <div class="sensors" style="display: flex" *ngIf="getSensorTypes().length > 1">
    <div *ngFor="let type of getSensorTypes()" class="sensor" 
        [class.active]="sensorType.id == type.id" (click)="setSensorType(type)">{{type.name|translate}}</div>
  </div>
</div>  
  `
})
export class FarmerBuildingHistory {
  @Input() model: FarmerBuilding
  @ViewChild('cellTemplate') cellTemplate: TemplateRef<any>
  
  _subscriptions: Subscription[] = []
  listen (s: Subscription) { this._subscriptions.push(s) }
  ngOnDestroy () { this._subscriptions.map(s => s.unsubscribe()); this._subscriptions = [] }

  text = TEXT
  sensorType: ISensorType = TEMPERATURE_SENSOR
  //SENSOR_TYPE = SENSOR_TYPE
  selection: Index<boolean> = {}
  sensorChart: IChartInfo = {
    name: 'Sensor History', figures: [],
    timeseries: [], axes: [
      {show: true, position: 'left'},
      {show: true, position: 'bottom'}
    ]
  }
  constructor (
    private unit: UnitService, private route: ActivatedRoute
  ) {}

  getSensorTypes () { return this.model.getSensorTypes() }

  ngOnInit () {
    this.listen(this.route.queryParams.subscribe(params => {
      let selection = params['k']
      if (selection) {
        this.selection[selection] = true
      }
      if (params['t']) {
        this.setSensorType(params['t'])
      }
    }))
  }

  isTemperature () { return this.sensorType.id == SENSOR_TYPE.TEMP }
  isMoisture () { return this.sensorType.id == SENSOR_TYPE.HUM }

  cells: Index<ITableCell> = {}
  onCheckChange (cell: ITableCell, e) {
    let key = cell.row.entityId.id + ':' + cell.key
    this.cells[key] = cell
    this.refresh()
  }

  refresh () {
    let selection: ITableCell[] = []
    for (var k in this.selection) {
      if (this.selection[k] && this.cells[k]) {
        selection.push(this.cells[k])
      }
    }
    let timeseries: ITimeserie[] = []
    selection.map((cell, i) => {
      let is_temperature = cell.key.startsWith('temperature-')
      let unit = is_temperature ? this.unit.temperatureUnitSymbol() : this.unit.moistureUnitSymbol()
      timeseries.push({
        name: cell.key, label: cell.row.name + ' ' + cell.key, entityId: cell.row.entityId,
        color: indexColor(i), unit: unit
      })
    })
    Object.assign(this.sensorChart, {timeseries: timeseries})
  }

  setSensorType (type: ISensorType) {
    this.sensorType = type
  }
  onSensorSelection (selection: ISensorSelection) {
    this.selection = {}
    selection.sensors.map(s => {
      let cell = s.cell
      let key = cell.row.entityId.id + ':' + cell.key
      this.selection[key] = true
    })
    this.refresh()
  }
}



@Component({
  selector: 'farmer-building-history-page',
  styleUrls: ['farmer-building.scss'],
  template: `

<ion-header>
  <ion-toolbar>

  <ion-buttons slot="start">
    <ion-back-button defaultHref="/"></ion-back-button>
  </ion-buttons>
  <ion-title mode="md">{{asset?.label}} History</ion-title>
  <ion-buttons class="page-tab-list">
  </ion-buttons>
  <ion-buttons slot="primary">
      
  </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div *ngIf="loading.is_success()">
    <farmer-building-history *ngIf="model" [model]="model"></farmer-building-history>
  </div>
</ion-content>
  `
})
export class FarmerBuildingHistoryPage {
  @Input() entityId: string
  model: FarmerBuilding
  loading = new LoadingState()
  asset: Asset
  
  constructor (
    private farmer: FarmerService,
    private entityService: EntityService,
    private route: ActivatedRoute) {}

  _subscriptions: Subscription[] = []
  listen (s: Subscription) { this._subscriptions.push(s) }
  ngOnDestroy () { this._subscriptions.map(s => s.unsubscribe()); this._subscriptions = [] }
  
  ngOnInit () {
    this.loading.loading(true)
    
    this.listen(this.route.params.subscribe(params => {
      this.entityId = params['entityId'];
      this.reload()
    }));
    this.reload()
  }
  async reload () {
    this.asset = await this.entityService.getAssetById(this.entityId)
    this.model = this.farmer.getBuilding(this.asset)
    this.loading.success()
  }
}



export interface ITableCell {
  value: number, style: object, type: SENSOR_TYPE
  rowIndex: number, columnIndex: number,
  ts: number, key: string, column: ITableColumn, row: ITableRow
  selectable?: boolean, isSelected?: boolean
}
interface ITableRow {
  name: string, index: number, entityId: EntityId<any>
}
interface ITableColumn {
  name: string, cells: ITableCell[], entityId?: EntityId<any>
  selectable?: boolean, index?: number
}

interface ISensorSelectionItem {
  entityId: EntityId<any>, name: string, key: string, cell?: ITableCell
}

export interface ISensorSelection {
  sensors: ISensorSelectionItem[]
  columns: string[]
  event?: MouseEvent
}

// '../farmer-silo/farmer-silo.scss'
@Component({
  selector: 'farmer-building-spear-table',
  template: `
<div class="silo-table" >
  <div class='table-column'>
    <div class="table-column-header"></div>
    <div (click)="selectRow(row)" *ngFor="let row of rows | reverse" class='table-row table-cell'>
      {{row.name}}      
    </div>
  </div>
  <div class="table-column" *ngFor="let column of columns">
    <div (click)="selectColumn(column)" class="table-column-header">{{column.name}}</div>
    <div *ngFor="let row of rows | reverse" [class]="row.index + '-' + column.index">
      <div *ngIf="!column.cells[row.index]" class='table-cell'></div>
      <div *ngIf="column.cells[row.index] && cellTemplate" >
        <ng-template [ngTemplateOutlet]="cellTemplate" [ngTemplateOutletContext]="{cell: column.cells[row.index]}"></ng-template>
      </div>
      <div *ngIf="column.cells[row.index] && !cellTemplate">
        <div  (click)="onCellClick(column.cells[row.index], $event)" class='table-cell' [style]="column.cells[row.index].style"
            [class.selected]="isSelected(column.cells[row.index])">
          {{column.cells[row.index].value ? column.cells[row.index].value.toFixed() : ' '}}
        </div>
      </div>
    </div>
  </div>
</div>
`,
  styleUrls: ['farmer-building.scss']
})
export class FarmerBuildingSpearTable {
  @Input() model: FarmerBuilding
  @Input() sensorType: SENSOR_TYPE = SENSOR_TYPE.TEMP
  @Input() minValue: number
  @Input() maxValue: number
  @Input() mode: 'SELECT' | 'VALUE' = 'VALUE'
  @Input() singleSelection: boolean = false
  @Input() cellTemplate: TemplateRef<any>
  @Output() onSelect: EventEmitter<ISensorSelection> = new EventEmitter()

  columns: ITableColumn[] = []
  rows: ITableRow[] = []
  selection: ISensorSelection = {sensors: [], columns: []}

  constructor (private farmer: FarmerService) {}

  _subscriptions: Subscription[] = []
  listen (s: Subscription) { this._subscriptions.push(s) }
  ngOnDestroy () { this._subscriptions.map(s => s.unsubscribe()); this._subscriptions = [] }

  ngOnInit () {
    this.listen(this.model.updated.subscribe(silo => {
      this.buildTable()
    }))
    this.model.load()
  }

  _clearSelection (e) {
    this.selection.sensors = []
    this.onSelect.emit(this.selection)
  }
  clearSelection = this._clearSelection.bind(this)

  timeDisplay (ts: number) {
    if (!ts) return "-"
    return moment(ts).format("MMMM Do YYYY, HH:mm")
  }

  selectColumn (column: ITableColumn) {
    this.selection.event = null
    let cells = column.cells.slice(0)
    this.toggleSelect(cells)
  }
  getAllCells () {
    let cells = []
    this.columns.map(c => {
      cells.push(...c.cells)
    })
    return cells
  }
  selectRow (row: ITableRow) {
    this.selection.event = null
    let cells = this.getAllCells().filter(x => x.row == row)
    this.toggleSelect(cells)
  }
  toggleSelect (cells: ITableCell[]) {
    let hasSelection = cells.find(c => this.isSelected(c))
    if (!hasSelection) {
      this.select(cells)
    } else {
      this.clearSelection()
    }
  }
  onCellClick (cell: ITableCell, $event) {
    this.selection.event = $event
    this.toggleSelect([cell])
  }
  select (cells: ITableCell[]) {
    this.selection.sensors = []
    cells.map(cell => {
      if (!cell.selectable) return
      this.selection.sensors.push({
        entityId: cell.row.entityId, key: cell.key, name: cell.column.name,
        cell: cell
      })
    })
    this.onSelect.emit(this.selection)
  }
  isSelected (cell: ITableCell) {
    return this.selection.sensors.find(
      s => s.entityId?.id == cell.row.entityId?.id && cell.key == s.key
    ) != undefined
  }

  ngOnChanges (changes) {
    this.buildTable()
  }

  getColor (value: number) {
    let matrix = agTypes.colors.tempMatrixColors
    if (this.sensorType == SENSOR_TYPE.HUM) matrix = agTypes.colors.moistureMatrixColors
    return gradientColorValue(value, this.minValue, this.maxValue, matrix)
  }
  getValueKey () {
    if (this.sensorType == SENSOR_TYPE.HUM) { 
      let crop = this.model.settings.crop_type
      return crop ? 'emc-' + crop : 'humidity'
    } else return 'temperature'
  }

  buildTable () {
    let columns: ITableColumn[] = []
    let rows: ITableRow[] = []
    let devices = this.model.devices.map(d => this.farmer.getCellularSpear(d))
    devices.map((device, deviceIndex) => {
      let row: ITableRow = {index: deviceIndex, name: device.entity.label, entityId: device.entity.id}
      rows.push(row)
      
      let sensors = device.sensors
      sensors.map((sensor, sensorIndex) => {
        let col = columns.find(c => c.name == sensor.name)
        if (!col) {
          let column: ITableColumn = {
            selectable: true,
            name: sensor.name, cells: [], entityId: device.entity.id
          }
          columns.push(column)
        }
        let colIndex = columns.findIndex(c => c.name == sensor.name)
        col = columns[colIndex]
        let key = this.getValueKey() + '-' + sensor.key
        col.cells.push({
          value: 0, type: sensor.sensorType, style: {}, 
          ts: 0, column: col, row: row, 
          key: key, selectable: sensor.hasSensorType(this.sensorType), 
          rowIndex: deviceIndex, columnIndex: colIndex,
        })
      })
    })
    this.columns = columns
    this.rows = rows
  }
}