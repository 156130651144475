
import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router, NavigationStart } from "@angular/router";
import { AlertController, ModalController, PopoverController } from "@ionic/angular";
import { isEqual } from "lodash-es";
import { merge, fromEvent, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { agTypes } from "src/app/ag-types";
import { TimeQuerySettings } from "src/app/menus";
import { Asset, Device } from "src/app/models/entity.model";
import { ITelemetryValue } from "src/app/models/telemetry.model";
import { FarmerService } from "src/app/services/device.service";
import { EntityService } from "src/app/services/entity.service";
import { PaymentService } from "src/app/services/payment.service";
import { IBuildingSettings } from "src/app/services/types.service";
import { UnitService } from "src/app/services/unit";
import { AgModal, clone, deviceImage, gradientColorValue, indexColor, IRangeValue, ListCrops, LoadingState, onElementResize, onMouseDrag, onResume } from "src/app/util";
import { IChartInfo } from "../ag-plot/ag-plot";
import { FarmerCellularSpear, FarmerDeviceSensor } from "../cellular-spear/spear.model";
import { EditBuildingMapDialog } from "./farmer-building-list";
import { FarmerBuilding, getEventCoords, getTouchEvent, IBuildingMarker } from "./farmer-building.model";

import * as omitDeep from 'omit-deep-lodash'
import { createAlarmGroups } from "../alarm-page/alarm-page";
import { AlarmService, IAlarmItem } from "src/app/services/alarm.service";
import { TEXT } from "src/app/texts";
import { TranslateService } from "@ngx-translate/core";
import { ATTRIBUTES, ICrop } from "src/app/constant";
import tinycolor from "tinycolor2";
//const omitDeep = require("omit-deep-lodash");


// NOTE: not in use
@Component({
  selector: 'farmer-building-devices-page',
  template: `
<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button defaultHref="/"></ion-back-button>
    </ion-buttons>
    <ion-title mode="md">{{title}}</ion-title>
    <ion-buttons slot="primary">

    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content *ngIf="isLoaded">
  <div *ngIf="!building.hasDevices" class="flex flex-column ag-card flex-auto stats-container">
    <div>{{text.building.has_no_devices|translate}}!</div>
    <div [translate]="text.building.open_settings_to_add_devices"></div>
    <ion-button style="margin-top: 10px;" fill="clear" (click)="openSettings()">
      <ion-icon style="margin-right: 5px;" slot="icon-only" name="settings-outline"></ion-icon>
      {{text.general.settings}}
    </ion-button>
  </div>
  <entity-grid [entities]="devices"></entity-grid>
</ion-content>
  `,
  styleUrls: ['../farmer-devices/farmer-devices.scss']
})
export class FarmerBuildingDevicesPage {
  @Input() entityId: string
  
  loading = new LoadingState()
  asset: Asset
  title = 'Devices'
  building: FarmerBuilding
  devices: Device[] = []
  isLoaded = false

  _subscriptions: Subscription[] = []
  listen (s: Subscription) { this._subscriptions.push(s) }
  ngOnDestroy () { this._subscriptions.map(s => s.unsubscribe()); this._subscriptions = [] }

  text = TEXT
  deviceName (i, d) {
    return d.name
  }

  constructor (
    private route: ActivatedRoute,
    private router: Router,
    private farmer: FarmerService,
    private entityService: EntityService,
    private modalController: ModalController,
    private agModal: AgModal,
    private translate: TranslateService
  ) {}

  ngOnInit () {
    this._subscriptions = []
    this.loading.events.subscribe(x => {
      this.isLoaded = this.loading.is_success()
    })
    this.loading.loading(true)
    this.listen(this.route.params.subscribe(params => {
      this.entityId = params['entityId'];
    }));
    
    this.load()
  }
  async load () {
    this.asset = await this.entityService.getAssetById(this.entityId)
    this.building = this.farmer.getBuilding(this.asset)
    this.listen(this.building.subscribe(() => {
      this.title = this.asset.label + ' ' + this.translate.instant(this.text.general.devices)
      this.devices = this.building.devices.sort((a, b) => a.label.localeCompare(b.label))
      
      this.loading.success()
    }))
  }

  selectDevice (device) {
    this.router.navigateByUrl('/device/' + device.id.id)
  }

  async openSettings () {
    const dialogRef = await this.modalController.create({
      component: FarmerBuildingSettings,
      componentProps: {building: this.building, settings: clone(this.building.settings)}
    });
    const { data } = await this.agModal.openModal(dialogRef)
  }
}


@Component({
  selector: 'farmer-building-page',
  templateUrl: 'farmer-building.html',
  styleUrls: ['farmer-building.scss']
})
export class FarmerBuildingPage {

  @Input() entityId: string
  loading = new LoadingState()
  asset: Asset
  building: FarmerBuilding
  title: string = ''
  charts: IChartInfo[] = []
  text = TEXT
  //latest_moisture_title = this.text.general.latest_humidity
  isMoisture = false
  isHumidity = false

  chartKey (index: number, chart: IChartInfo) {
    return chart.name
  }

  constructor (
    public titleService: Title,
    private modalController: ModalController,
    private unit: UnitService,
    private entityService: EntityService,
    private popoverController: PopoverController,
    private farmer: FarmerService, 
    private router: Router,
    private route: ActivatedRoute,
    private payment: PaymentService,
    private agModal: AgModal,
    private alarmService: AlarmService,
    private translate: TranslateService
  ) {}

  _subscriptions: Subscription[] = []
  listen (s: Subscription) { this._subscriptions.push(s) }
  ngOnDestroy () { 
    this._subscriptions.map(s => s.unsubscribe()); this._subscriptions = [] }

  deviceKey (index: number, item: Device) {
    return item.id.id
  }

  get data () { return this.building.settings }

  //get emcLabel () { return this.data.crop_type ? 'EMC: ' + CropLabel(this.data.crop_type) : 'Humidity' }
  
  checkout () {
    this.payment.openPaymentDialog()
  }

  async openEditPlacements () {
    const modal = await this.modalController.create({
      component: EditBuildingMapDialog, componentProps: {building: this.building},
      cssClass: 'my-custom-class', 
      showBackdrop: true //,
      //presentingElement: await this.modalController.getTop()
    });
    const { data } = await this.agModal.openModal(modal)
  }

  async openHistoryPage (ev) { 
    let params = {}
    this.router.navigate(['history'], {relativeTo: this.route, 
      queryParams: params})
  }

  chartIdentity (index: number, item: IChartInfo) {
    return item.name
  }

  isLoaded = null
  hasTemperature = null
  hasMoisture = null
  isPaid = null
  alarmItems: IAlarmItem[] = []
  ngOnInit () {
    this.listen(this.loading.events.subscribe(x => {
      this.isLoaded = this.loading.is_success()
    }))
    this.loading.loading(true)
    this.listen(this.route.params.subscribe(params => {
      this.entityId = params['entityId'];
    }));
    this.listen(this.payment.subscribe(() => {
      if (this.asset) this.isPaid = this.payment.getAssetState(this.asset)?.active
    }))
    this.listen(
      this.alarmService.subscribe(alarms => {
        let entityAlarms = alarms.filter(x => x.originator.id == this.entityId)
        let groups = createAlarmGroups(this.alarmService, entityAlarms)
        this.alarmItems = groups.alarms
        //this.alarmGroups = createAlarmGroups(this.alarmService, entityAlarms).groups
      })
    )
    
    /*this.listen(
      this.router.events.subscribe((event: any): void => {
        if (event instanceof NavigationStart) {
          if (event.navigationTrigger === 'popstate') {
            this.modalController.getTop().then(x => x.dismiss())
            return
            //this.autocloseOverlaysService.trigger();
          }
        }
      })
    )*/

    this.reload()
  }
  async reload () {
    try {
      this.asset = await this.entityService.getAssetById(this.entityId)
    } catch (err) {
      this.loading.error(this.translate.instant(this.text.building.failed_to_load_building))
      throw err
    }
    this.setTitle(this.asset.label)
    this.building = this.farmer.getBuilding(this.asset)
    this.listen(this.building.subscribe(() => {
      this.setTitle(this.building.settings.label)
      this.setupCharts()
      if (this.building.loaded) {
        this.hasMoisture = this.building.hasMoisture()
        this.hasTemperature = this.building.hasTemperature()
        this.isPaid = this.payment.getAssetState(this.asset)?.active
        
        this.isMoisture = !!(this.building.settings.crop_type)
        this.isHumidity = !this.isMoisture

        this.loading.success()
      }
    }))
    this.building.load()
  }

  async openDeviceList () {
    this.router.navigateByUrl('/building/' + this.asset.id.id + '/devices')
  }
  async openTimeWindowMenu (ev) {
    let popover = await this.popoverController.create({
      component: TimeQuerySettings,
      event: ev,
      translucent: true
    });
    return popover.present();
  }

  async openAddDeviceDialog () {
    const modal = await this.modalController.create({
      component: DeviceSelector,
      cssClass: 'my-custom-class',
      showBackdrop: true //,
      //presentingElement: await this.modalController.getTop()
    });
    const { data } = await this.agModal.openModal(modal)
    if (data) {
      let shouldLoad = this.building.devices.length == 0
      this.loading.loading(shouldLoad)
      try {
        for (var device of data) {
          await this.building.assignDevice(device)
        }
      } finally {
        this.loading.success()
      }
    }
  }

  setupCharts () {
    let settings = this.building.settings
    let isHumidity = !!(settings.crop_type)
    let tempChart: IChartInfo = {
      name: this.text.general.temperature,
      timeseries: [], axes: [ 
        {show: true, position: 'left'},
        {show: true, position: 'bottom'}
      ], figures: []
    }
    let moistureChart: IChartInfo = {
      name: this.text.general.humidity,
      timeseries: [], axes: [ 
        {show: true, position: 'left', tickFormatter: (val, obj) => val.toFixed() + '%'},
        {show: true, position: 'bottom'}
      ], figures: []
    }
    

    tempChart.figures.push({
      y: settings.sample_alarm_max_temperature, type: 'horizontal',
      color: "#D9461D", label: 'Max Temperature Alarm'
    })
    tempChart.figures.push({
      y: settings.sample_alarm_min_temperature, type: 'horizontal',
      color: "#D9461D", label: 'Min Temperature Alarm'
    })
    moistureChart.figures.push({
      y: settings.sample_alarm_max_emc, type: 'horizontal',
      color: "#D9461D", label: 'Max Moisture Alarm'
    })
    moistureChart.figures.push({
      y: settings.sample_alarm_min_emc, type: 'horizontal',
      color: "#D9461D", label: 'Min Moisture Alarm'
    })

    /*
    let humidityChart: IChartInfo = {
      name: this.text.general.humidity,
      timeseries: [], axes: [ 
        {show: true, position: 'left', tickFormatter: (val, obj) => val.toFixed() + '%'},
        {show: true, position: 'bottom'}
      ], figures: []
    }
    
    humidityChart.figures.push({
      y: settings.sample_alarm_max_emc, type: 'horizontal',
      color: "#D9461D", label: 'Max Moisture Alarm'
    })
    humidityChart.figures.push({
      y: settings.sample_alarm_min_emc, type: 'horizontal',
      color: "#D9461D", label: 'Min Moisture Alarm'
    })
    */
    
    let index = 0
    this.building.temperatureKeys().map(key => {
      let attribute = ATTRIBUTES.find(x => x.key == key.key)
      tempChart.timeseries.push({
        name: key.key, label: attribute?.name,
        color: indexColor(index), unit: this.unit.temperatureUnitSymbol()
      })
      index += 1
    })

    this.building.moistureKeys().map(key => {
      let attribute = ATTRIBUTES.find(x => x.key == key.key)
      // TODO: attribute label should be humidity when not moisture
      let label = attribute?.name || 'unknown'
      if (!isHumidity) {
        label = label.split('_').slice(0, -1).join('_') + '_humidity'
      }
      moistureChart.timeseries.push({
        name: key.key, label: label, 
        color: indexColor(index), unit: this.unit.moistureUnitSymbol()
      })
      index += 1
    })

    
    let newCharts = []
    if (this.building.hasTemperature()) newCharts.push(tempChart)
    if (this.building.hasMoisture()) newCharts.push(moistureChart)
    
    if (isHumidity) {
      moistureChart.name = this.text.general.moisture
    }

    let a = omitDeep(this.charts, 'id', 'entityId', 'tickFormatter')
    let b = omitDeep(newCharts, 'id', 'entityId', 'tickFormatter')
    let hasChanges = !isEqual(a, b)
    
    if (hasChanges) {
      this.charts = newCharts
    }
  }

  setTitle (label: string) {
    this.titleService.setTitle(label)
    this.title = label
  }

  async openSettings () {
    const dialogRef = await this.modalController.create({
      component: FarmerBuildingSettings,
      componentProps: {building: this.building, settings: clone(this.building.settings)}
    });
    const { data } = await this.agModal.openModal(dialogRef)
  }
}


@Component({
  selector: 'farmer-building-settings',
  template: `
<ion-header>
    <ion-toolbar>
    <ion-title mode="md">{{building.label}} {{text.general.settings|translate}}</ion-title>
    <ion-buttons slot="primary">
        <ion-button (click)="dismiss()" >
            <ion-icon name="close-outline"></ion-icon>
        </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content >
  <ag-loading [loading]="saving" message="{{text.general.saving|translate}}" (retry)="saveSettings()"></ag-loading>
  <div class="dialog-content">
    
    <ion-list>
      <ion-item>
          <ion-label position="stacked" [translate]="text.general.device_name"></ion-label>
          <ion-input [(ngModel)]="settings.label" placeholder="{{text.general.label | translate}}"></ion-input>
      </ion-item>

      <ion-item>
        <!--<ion-label [translate]="text.general.crop_type"></ion-label>-->
        <!--ok-text="{{text.general.okay | translate}}" cancel-text="Cancel"-->
        <ion-select [placeholder]="text.general.crop_type | translate" [(ngModel)]="settings.crop_type" interface="popover" >
          <ion-select-option value="" [translate]="text.general.no_crop"></ion-select-option>
          <ion-select-option *ngFor="let crop of crops; trackBy:cropKey" [value]="crop.key">{{crop.title|translate}}</ion-select-option>
        </ion-select>
      </ion-item>
    </ion-list>

    <ion-list>
      <ion-list-header [translate]="text.general.alarm_threshold"></ion-list-header>
      <ion-item lines="none">
          <ion-label position="stacked" [translate]="text.general.temperature_range"></ion-label>
          <ion-range [(ngModel)]="sample_temperature_threshold" dualKnobs="true" pin min="-20" max="60" color="secondary">
              <ion-label style="min-width: 25px" slot="start">{{sample_temperature_threshold.lower}}</ion-label>
              <ion-label style="min-width: 25px" slot="end">{{sample_temperature_threshold.upper}}</ion-label>
          </ion-range>
      </ion-item>
      <!-- *ngIf="building.hasMoisture()"-->
      <ion-item lines="none">
          <ion-label position="stacked" [translate]="text.general.moisture_range"></ion-label>
          <ion-range class="range-slider" [(ngModel)]="sample_emc_threshold" dualKnobs="true" pin  min="0" max="100" color="secondary">
              <ion-label style="min-width: 25px" slot="start">{{sample_emc_threshold.lower}}</ion-label>
              <ion-label style="min-width: 25px" slot="end">{{sample_emc_threshold.upper}}</ion-label>
          </ion-range>
      </ion-item >
      
      <ion-item lines="none" >
          <ion-label position="stacked" [translate]="text.general.ambient_temperature">Ambient Temperature</ion-label>
          <ion-range [(ngModel)]="ambient_temperature_threshold" dualKnobs="true" pin  min="-20" max="60" color="secondary">
              <ion-label style="min-width: 25px" slot="start">{{ambient_temperature_threshold.lower}}</ion-label>
              <ion-label style="min-width: 25px" slot="end">{{ambient_temperature_threshold.upper}}</ion-label>
          </ion-range>
      </ion-item >
      <!-- *ngIf="building.hasMoisture()"-->
      <ion-item lines="none" >
          <ion-label position="stacked" [translate]="text.general.ambient_moisture"></ion-label>
          <ion-range [(ngModel)]="ambient_emc_threshold" dualKnobs="true" pin  min="0" max="100" color="secondary">
              <ion-label style="min-width: 25px" slot="start">{{ambient_emc_threshold.lower}}</ion-label>
              <ion-label style="min-width: 25px" slot="end">{{ambient_emc_threshold.upper}}</ion-label>
          </ion-range>
      </ion-item>
    </ion-list>

    <ion-list>
      <ion-list-header [translate]="text.general.building"></ion-list-header>
      <ion-item>
          <ion-label position="stacked">{{text.building.length|translate}} ({{text.general.meters|translate}})</ion-label>
          <ion-input (ionChange)="saveSettings()" type="number" [(ngModel)]="settings.length" placeholder="Building length (meter)"></ion-input>
      </ion-item>
      <ion-item>
          <ion-label position="stacked">{{text.building.width|translate}} ({{text.general.meters|translate}})</ion-label>
          <ion-input (ionChange)="saveSettings()" type="number" [(ngModel)]="settings.width" placeholder="Building width (meter)"></ion-input>
      </ion-item>

      <div style="padding: 20px; background: #f7f7f7; z-index: 99999; position: relative;">
        <building-sensor-map (onMarkersUpdate)="markersUpdated($event)" [editMode]="true" [editable]="true" [building]="building"></building-sensor-map>
      </div>
    </ion-list>
    
    <div style="margin-top: 100px;">
      <ion-button color="danger" (click)="remove()" [translate]="text.building.remove"></ion-button>
    </div>
  </div>
  <ion-fab  vertical="bottom" horizontal="end" slot="fixed" >
      <ion-fab-button [disabled]="canSave" color="primary" (click)="accept()">
          <ion-icon name="checkmark-outline"></ion-icon>
      </ion-fab-button>
    </ion-fab>
</ion-content>
  `,
  styleUrls: ['farmer-building.scss']
})
export class FarmerBuildingSettings {
  @Input() building: FarmerBuilding
  @Input() settings: IBuildingSettings

  constructor (
    private modal: ModalController,
    public alertController: AlertController,
    public farmer: FarmerService, 
    public route: Router,
    private translate: TranslateService
  ) {}

  text = TEXT
  canSave = true
  saving = new LoadingState()
  updatedMarkers: IBuildingMarker[]
  sample_temperature_threshold: IRangeValue
  sample_emc_threshold: IRangeValue
  ambient_temperature_threshold: IRangeValue
  ambient_emc_threshold: IRangeValue
  crops = ListCrops()

  _subscriptions: Subscription[] = []
  listen (s: Subscription) { this._subscriptions.push(s) }
  ngOnDestroy () { this._subscriptions.map(s => s.unsubscribe()); this._subscriptions = [] }
  
  cropKey (index: number, item: ICrop) {
    return item.key
  }

  ngOnInit () {
    this.saving.success()
    this.sample_temperature_threshold = {
      lower: this.settings.sample_alarm_min_temperature, 
      upper: this.settings.sample_alarm_max_temperature
    }
    this.sample_emc_threshold = {
      lower: this.settings.sample_alarm_min_emc, 
      upper: this.settings.sample_alarm_max_emc
    }
    this.ambient_temperature_threshold = {
      lower: this.settings.ambient_alarm_min_temperature, 
      upper: this.settings.ambient_alarm_max_temperature
    }
    this.ambient_emc_threshold = {
      lower: this.settings.ambient_alarm_min_emc, 
      upper: this.settings.ambient_alarm_max_emc
    }
    this.listen(this.saving.events.subscribe(x => {
      this.canSave = !x.is_complete()
    }))
  }

  async remove () {
    let unclaimPrompt = await this.alertController.create({
      cssClass: 'bold-alert',
      header: this.translate.instant(this.text.settings.confirm_remove_building_title),
      message: this.translate.instant(this.text.settings.confirm_remove_building),
      buttons: [
        {
          text: this.translate.instant(this.text.general.no), // 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
          }
        }, {
          text: this.translate.instant(this.text.general.yes), // 'Yes',
          handler: async () => {
            await this.farmer.deleteBuilding(this.building.entity)
            this.dismiss({redirect: '/'})
          }
        }
      ]
    });

    await unclaimPrompt.present();
  }

  markersUpdated (markers) {
    this.updatedMarkers = markers
  }

  dismiss (data?) { this.modal.dismiss(data) }

  async saveSettings () {
    
    this.settings.sample_alarm_min_temperature = this.sample_temperature_threshold.lower
    this.settings.sample_alarm_max_temperature = this.sample_temperature_threshold.upper
    
    this.settings.sample_alarm_min_emc = this.sample_emc_threshold.lower
    this.settings.sample_alarm_max_emc = this.sample_emc_threshold.upper
    
    this.settings.ambient_alarm_min_temperature = this.ambient_temperature_threshold.lower
    this.settings.ambient_alarm_max_temperature = this.ambient_temperature_threshold.upper

    this.settings.ambient_alarm_min_emc = this.ambient_emc_threshold.lower
    this.settings.ambient_alarm_max_emc = this.ambient_emc_threshold.upper
    await this.building.saveSettings(this.settings)
    if (this.updatedMarkers) {
      await this.building.saveMarkers(this.updatedMarkers)
    }
    
  }

  async accept () {
    this.saving.loading(true)
    
    try {
      await this.saveSettings()
    } finally {
      this.saving.success()
    }
    this.dismiss()
  }
}



@Component({
  selector: 'building-sensor-map',
  template: `
<div >
  <ag-loading [loading]="loading" [inline]="true" message="" (retry)="reload()"></ag-loading>
  <div #root class="sensor-map" style="height: 300px; position: relative">
    <div *ngIf="!hasDevices && !editMode" class="empty-message">
      {{text.building.no_devices_assigned|translate}}<br /> 
      {{text.building.open_settings_to_add_devices|translate}}
    </div>
    <div *ngIf="!hasDevices && editMode" class="empty-message">
      {{text.building.add_device_help|translate}}
    </div>
    <!-- NOTE: using style.visibility is required, component must know the element-size, even if not used -->
    <div #buildingElement [style.visibility]="hasDevices ? 'visible':'hidden'" style="background: white; outline: 2px solid #7b7b7b; position: absolute; left: 50%; transform: translateX(-50%);">
      <!--(click)="onCanvasClick($event)" (touchstart)="onCanvasClick($event)" -->
      <canvas #canvas style="position: absolute;"></canvas>
      <div class="tooltip" *ngIf="marker && marker.viewPos"
           [style.top]="(marker.viewPos.y-(locSize*0.5)-15) + 'px'"
           [style.left]="marker.viewPos.x + 'px'"
           (click)="onTooltipClick($event, marker.device)" 
        >
        <div class="tooltip-content">
          <div class="tooltip-name">{{marker.device.label}}</div>
          <div *ngIf="editMode" class="tooltip-value">
            <!--<button style="padding: 10px; border: 1px solid lightgray;" (click)="removeDevice(marker.device, $event)">REMOVE</button>-->
          </div>
          <div *ngIf="!editMode && marker.value">
            <span class="tooltip-label">{{marker.sensor}}</span>
            <span class="tooltip-value">
              <ag-telemetry-value [status]="marker.value.status" [value]="marker.value.value" [type]="type"></ag-telemetry-value>
            </span>
          </div>
          <div *ngIf="!editMode && marker.value">
            <span class="tooltip-label">
              <ag-time-since [ts]="marker.value.ts"></ag-time-since>
            </span>
          </div>
        </div>
        <div class="tooltip-tip-container">
          <div class="tooltip-tip"></div>
        </div>
      </div>

      <!--(touchstart)="startDrag(loc.id, $event)"
      (mousedown)="startDrag(loc.id, $event)"-->
      <div *ngFor="let loc of layoutedLocations"
        
        [class]="{selected: isSelected(loc.id), invalid: !loc.valid}"
        [style.top]="(loc.viewPos.y-(locSize/2)) + 'px'" 
        [style.left]="(loc.viewPos.x-(locSize/2)) + 'px'"
        class="sensor-item">
          <span *ngIf="editMode" class="sensor-item-shape"></span>
          <span *ngIf="!editMode && loc.value?.status != 'error' && loc.value">{{loc.value.value|number:"1.1-1"}}{{unitSymbol}}</span> 
          <span *ngIf="!editMode && loc.value?.status != 'error' && !loc.value">N/A</span>
          <span *ngIf="!editMode && loc.value?.status == 'error'" [translate]="text.general.error_value" [style.color]="errorValueColor"></span>
      </div>
    </div>
  </div>
  <!--<div *ngFor="let log of logs">{{log}}</div>-->
  <div *ngIf="!editMode" class="sensors">
    <div class="sensor" [class]="{active: isActiveSensor(sensor)}" 
    (click)="setActiveSensor(sensor)" *ngFor="let sensor of sensors">{{sensor.title|translate}}</div>
  </div>
  <div *ngIf="!editMode" style="width: 100%">
    <div class="messurements">
      <div>{{minValue}}{{unitSymbol}}</div>
      <div>{{minValue + (maxValue - minValue) / 2}}{{unitSymbol}}</div>
      <div>{{maxValue}}{{unitSymbol}}</div>
    </div>
    <div [class]="{
      'temperature-gradient': isTemperature(),
      'moisture-gradient': isMoisture()
    }" style="width: 100%; height: 10px"></div>  
  </div>
  <div *ngIf="editMode" style="display: flex;">
    <ion-button [translate]="text.general.add_device" style="margin-left: auto; margin-right: auto;" (click)="openSelectDeviceDialog($event)"></ion-button>
  </div>
</div>
`,
  styles: [`

.empty-message {
  display: inherit;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  font-weight: bold;
  font-size: 12px;
  color: #a9a9a9;
  white-space: pre;
}


.tooltip-label {
  font-weight: bold;
}
.tooltip-value {
  margin-left: 5px;
}
.tooltip-name {
  font-weight: bold;
}

.tooltip {
  font: 12px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;
  position: absolute;
  transform: translate(-50%, -100%);
  z-index: 999;
  background: white;
  border-radius: 6px;
  color: #333;
  box-shadow: 0 3px 14px rgb(0 0 0 / 40%);
}
.tooltip-content {
  width: 110px;
  margin: 13px 19px;
    line-height: 1.4;
}

.tooltip-tip-container {
    width: 40px;
    height: 20px;
    position: absolute;
    left: 50%;
    margin-left: -20px;
    overflow: hidden;
    pointer-events: none;
}
.tooltip-tip {
  background: white;
  width: 17px;
  height: 17px;
  padding: 1px;
  margin: -10px auto 0;
  transform: rotate(45deg);
}

.messurements {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}
.sensor-map {
  position: relative;
}
.sensors {
  display: flex; justify-content: space-around; margin: 10px;
}
.sensor-item {
  background: none;
  color: white;
  font-size: 14px;
  font-weight: bold;

  border-radius: 2px;
  white-space: pre;
  padding: 3px;
  position: absolute;
  
}

.error-value {
  color: #d53d0e;
}

.sensor-item-shape {
  width: 20px;
  height: 20px;
  background: red;
  display: block;
  border-radius: 45px;
  background: url("/assets/svg/spear_sensor.svg");
}
.sensor {
  border: 1px solid transparent;
}
.sensor.active {
  border-bottom: 2px solid;
}
.selected {
  outline: 1px solid gray;

}
.invalid {
  opacity: 0.5;
}
`]
})
export class BuildingSensorMap {
  @Input() building: FarmerBuilding
  @Input() type: 'TEMPERATURE' | 'MOISTURE'
  @Input() editMode: boolean = false
  @Input() editable: boolean = false

  @ViewChild('root') el; 
  @ViewChild('buildingElement') buildingElement;
  @ViewChild('canvas') canvas; 

  @Output() onMarkersUpdate = new EventEmitter<IBuildingMarker[]>()

  constructor (
    private units: UnitService,
    private router: Router, 
    private modalController: ModalController,
    private farmer: FarmerService,
    private cdr: ChangeDetectorRef,
    private agModal: AgModal,
    private translate: TranslateService
    ) {}

  text = TEXT
  loading = new LoadingState()
  unitSymbol = ''
  locSize = 30
  locations: IBuildingMarker[] = []
  selection: string
  
  errorValueColor = agTypes.colors.errorValueColor

  minValue = 20
  maxValue = 60
  colors: string[] = []
  devices: Device[] = []
  width: number
  length: number

  activeSensor: string
  
  ctrl: FarmerCellularSpear
  sensors: FarmerDeviceSensor[] = []

  _subscriptions: Subscription[] = []
  listen (s: Subscription) { this._subscriptions.push(s) }
  ngOnDestroy () { this._subscriptions.map(s => s.unsubscribe()); this._subscriptions = [] }

  get layoutedLocations () {
    return this.locations.filter(x => x.viewPos != null)
  }

  onCanvasClick ($event: MouseEvent) {
    this.clearSelection()
  }
  
  isValidSensor (sensor: FarmerDeviceSensor) {
    if (this.editMode) return true
    if (this.type == 'TEMPERATURE' && sensor.hasTemperature()) return true
    else if (this.type == 'MOISTURE' && sensor.hasMoisture()) return true
    return false
  }

  setEditMode (editMode: boolean) {
    this.editMode = editMode
    this.createMarkers()
  }

  async acceptChanges () {
    this.setEditMode(false)
  }

  clearSelection () { 
    this.selection = null 
  }

  get marker () {
    return this.locations.find(x => x.id == this.selection)
  }
  get sensor () {
    return this.sensors.find(x => x.key == this.activeSensor)
  }

  async onTooltipClick (event, device: Device) {
    if (this.editMode) {}
    else { this.openDevicePage(event, device) }
  }

  async openDevicePage (event, device: Device) {
    if (this.editMode) return // NOTE: do not allow changing page in edit-mode
    event.stopPropagation()
    this.router.navigateByUrl('/device/' + device.id.id)
  }

  async openSelectDeviceDialog (ev) {
    let ignore = this.locations.map(x => x.device)
    let includes = this.building.devices.filter(x => !ignore.includes(x))
    const modal = await this.modalController.create({
      component: DeviceSelector, componentProps: {ignore: ignore, include: includes},
      cssClass: 'my-custom-class',
      showBackdrop: true
    });
    const { data } = await this.agModal.openModal(modal)
    let devices: Device[] = data
    if (devices && devices.length > 0) {
      for (var device of devices) {
        await this.addDevice(device)
      }
    } 
  }

  emit () {
    let bound = this.getViewBound()
    if (!bound.width || !bound.height) {
      return
    }
    
    let realSize = this.buildingSize()
    let viewSize = {width: bound.width, length: bound.height}

    let realPerView = {
      width: realSize.width / viewSize.width,
      length: realSize.length / viewSize.length
    }
    // TODO: wait for all positions saved
    let updatedLocations = this.locations.map(loc => {
      return Object.assign({}, loc, {
        realPos: {
          x: loc.viewPos.x * realPerView.width,
          y: loc.viewPos.y * realPerView.length
        }
      })
    })
    this.onMarkersUpdate.emit(updatedLocations)
  }

  onBuildingLoaded () {
    this.devices = this.building.devices.slice(0)
    this.hasDevices = this.devices.length > 0
    if (this.loaded)
      this.createMarkers()
  }

  hasDevices = null
  loaded = false
  ngOnInit () {
    this.listen(this.building.subscribe(() => {
      if (this.building.loaded && !this.loaded) {
        this.loaded = true
        this.onBuildingLoaded()
        this.loading.success()
      }
    }))
  }
  reload () {
    this.building.load()
  }

  async createMarkers () {
    for (var device of this.devices) {
      let ctrl = this.farmer.getCellularSpear(device)
      ctrl.load()
      await ctrl.waitLoaded()
    }

    this.devices.map(device => {
      let ctrl = this.farmer.getCellularSpear(device)
      if (!ctrl.sensors.length || !ctrl.isLoaded()) console.warn(device.label, ctrl.sensors.length, !ctrl.isLoaded(), 'expected device to be loaded before creatingMarkers', {ctrl: ctrl})
      ctrl.sensors.map(sensor => {
        let isKnown = this.sensors.find(s => s.key == sensor.key)
        if (!isKnown && this.isValidSensor(sensor)) {
          this.sensors.push(sensor)
          if (!this.activeSensor) this.activeSensor = sensor.key
        }
      })
    })

    // create markers
    let center = {
      x: this.building.settings.width * 0.5,
      y: this.building.settings.length * 0.5
    }
    let newLocations = this.devices.map(device => {
      let ctrl = this.farmer.getCellularSpear(device)
      let x = ctrl.settings.pos_x || center.x
      let y = ctrl.settings.pos_y || center.y
      let loc = this.locations.find(x => x.id == device.id.id)
      if (!loc) {
        loc = {
          device: device, id: device.id.id, value: null,
          realPos: {x: x, y: y}, viewPos: null, 
          bound: null, valid: false, sensor: '', unit: ''
        }
      }
      Object.assign(loc, {
        realPos: {x: x, y: y}
      })
      return loc
      
    })
    this.locations.splice(0, this.locations.length + 1, ...newLocations)
    this.setup()
  }

  ngAfterViewInit () {
    this.listen(onElementResize(this.el.nativeElement).pipe(debounceTime(100)).subscribe(x => this.onElementResize()))
    this.listen(this.building.updated.subscribe(e => {
      if (!this.editMode) {
        this.onBuildingLoaded()
      }
      this.setupCanvas()
      this.layout()
    }))
    this.listen(
      merge(
        fromEvent(this.el.nativeElement, 'mousedown', {}),
        fromEvent(this.el.nativeElement, 'touchstart', {passive: true})
      ).subscribe(x => {
        let evt = x as TouchEvent | MouseEvent
        let pos = getTouchEvent(evt)
        this.selectMarker(this.getNearestMarker({x: pos.clientX, y: pos.clientY}))
      })
    )
    if (this.editMode) {
      let dragState: {lastEvent: MouseEvent | TouchEvent, marker: IBuildingMarker}
      this.listen(onMouseDrag(this.el.nativeElement, {}).subscribe(x => {
        try {
          let e = x.move
          e.preventDefault()
          if (['mouseup', 'touchend'].includes(x.move.type)) {
            this.emit()
            dragState = null
            return
          }
          if (!dragState?.lastEvent) {
            let evt = e
            let clientPos = getTouchEvent(evt)
            let nearest = this.getNearestMarker({x: clientPos.clientX, y: clientPos.clientY})
            if (nearest) {
              dragState = {lastEvent: x.start || x.move, marker: nearest}
            }
            this.selectMarker(nearest)          
          }
          if (!x || !x.move || !dragState?.lastEvent) {
            return 
          }
          let lastPos = getEventCoords(dragState.lastEvent)
          let currPos = getEventCoords(e)
          dragState.lastEvent = x.move
          let vector = {
            x: currPos.x - lastPos.x, y: currPos.y - lastPos.y
          }
          
          
          let loc = dragState.marker
          loc.viewPos.x += vector.x
          loc.viewPos.y += vector.y
          this.setLocation(loc)
          this.cdr.detectChanges()
          //this.cd
        } catch (err) {
          // NOTE: angular is doing something behind the scene that makes this function fail at last call
          console.warn('failed to handle drag')
        }
      }))
    }
    this.listen(
      onResume().subscribe(x => this.layout())
    )
  }

  getNearestMarker (pos: {x: number, y: number}, max_dist=60): IBuildingMarker | null {
    let view = this.getViewBound()
    let p = {
      x: pos.x - view.left, y: pos.y - view.top
    }
    let nearest: {dist: number, marker: IBuildingMarker} = null
    this.layoutedLocations.map(x => {
      let dt = {x: p.x - x.viewPos.x, y: p.y - x.viewPos.y}
      let dist = Math.sqrt(Math.pow(dt.x, 2) + Math.pow(dt.y, 2))
      if (isNaN(dist)) {
        return
      }
      if (!nearest || nearest.dist > dist) {
        nearest = {dist: dist, marker: x}
      }
    })
    if (nearest?.dist < max_dist) {
      return nearest.marker
    }
    return null
  }

  ngOnChanges (changes) {    
    if (this.el) {
      this.layout()
    }
  }

  selectMarker (marker: IBuildingMarker | null) {
    if (marker) this.selection = marker.id
    else this.clearSelection()
  }
  getViewBound () {
    let canvas = this.canvas.nativeElement as HTMLCanvasElement
    return canvas.getBoundingClientRect()
  }
  buildingSize () {
    return {
      width: Math.min(this.building.settings.width, this.building.settings.length),
      length: Math.max(this.building.settings.width, this.building.settings.length)
    }
  }

  onElementResize () {
    this.setupCanvas()
    this.layout()
  }
  setupCanvas () {
    let bound = this.el.nativeElement.getBoundingClientRect()
    if (!bound.width || !bound.height) {
      return
    }
    let margin = 10
    let realSize = this.buildingSize()
    let aspect = realSize.length / realSize.width
    let viewLength = bound.height - margin
    let viewWidth = viewLength / aspect
    let buildingElement = this.buildingElement.nativeElement as HTMLDivElement
    buildingElement.style.width = viewWidth + 'px'
    buildingElement.style.height = viewLength + 'px'
    let canvas = this.canvas.nativeElement as HTMLCanvasElement
    canvas.width = viewWidth
    canvas.height = viewLength
  }

  setup () {
    let sensor = this.getActiveSensor()
    if (this.type == 'TEMPERATURE') {
      this.colors = agTypes.colors.tempMatrixColors
      this.unitSymbol = this.units.temperatureUnitSymbol()
      if (sensor && sensor.key == "ambient") {
        this.minValue = this.building.settings.ambient_alarm_min_temperature
        this.maxValue = this.building.settings.ambient_alarm_max_temperature
      } else {
        this.minValue = this.building.settings.sample_alarm_min_temperature
        this.maxValue = this.building.settings.sample_alarm_max_temperature
      }
    } else {
      this.colors = agTypes.colors.moistureMatrixColors
      this.unitSymbol = this.units.moistureUnitSymbol()
      if (sensor && sensor.key == "ambient") {
        this.minValue = this.building.settings.ambient_alarm_min_emc
        this.maxValue = this.building.settings.ambient_alarm_max_emc
      } else {
        this.minValue = this.building.settings.sample_alarm_min_emc
        this.maxValue = this.building.settings.sample_alarm_max_emc
      }
    }
    this.layout()
  }

  setActiveSensor (sensor: FarmerDeviceSensor) {
    this.activeSensor = sensor.key
    this.setup()
  }
  isActiveSensor (sensor: FarmerDeviceSensor) {
    return sensor.key == this.activeSensor
  }
  getActiveSensor () {
    return this.sensors.find(x => x.key == this.activeSensor)
  }

  isTemperature () { return this.type == 'TEMPERATURE' }
  isMoisture () { return this.type == 'MOISTURE' }

  async addDevice (device: Device) {
    this.devices.push(device)
    await this.createMarkers()
    this.selection = device.id.id
    this.layout()
    this.emit()
  }
  async removeDevice (device: Device, $event?) {
    $event?.stopPropagation(); 
    this.devices = this.devices.filter(d => d.id.id != device.id.id)
    await this.createMarkers()
    this.layout()
    this.emit()
    return false
  }
  
  setLocation (loc) {
    let bound = this.getViewBound()
    loc.viewPos.x = Math.max(0, loc.viewPos.x)
    loc.viewPos.y = Math.max(0, loc.viewPos.y)
    loc.viewPos.x = Math.min(bound.width, loc.viewPos.x)
    loc.viewPos.y = Math.min(bound.height, loc.viewPos.y)
  }

  get selectedLocation () {
    return this.locations.find(loc => loc.id == this.selection)
  }

  isSelected (id: string) {
    return this.selection == id
  }

  isValid () {
    return this.building.settings.length > 0 && this.building.settings.width > 0
  }

  layout () {
    if (!this.el)  {
      window.setTimeout(() => this.layout(), 100)
      return
    }
    let bound = this.getViewBound()
    if (!bound.width || !bound.height) {
      return
    }

    if (!this.isValid()) {
      return
    }

    let realSize = this.buildingSize()
    let viewSize = {width: bound.width, length: bound.height}
    let viewPerReal = {
      width: viewSize.width / realSize.width,
      length: viewSize.length / realSize.length
    }
    let newLocations = this.locations.map(loc => {
      let value: ITelemetryValue<number>
      let ctrl = this.farmer.getCellularSpear(loc.device)
      let pos = loc.realPos
      // NOTE: if we're not editing, it should always update view-position to match latest model position
      if (!loc.viewPos || !this.editMode) {
        loc.viewPos = {
          x: viewPerReal.width * pos.x, y: viewPerReal.length * pos.y
        }
      }
      loc.bound = bound
      let sensor = ctrl.sensors.find(s => s.key == this.activeSensor)
      if (sensor) {
        value = this.type == 'TEMPERATURE' ? sensor.temperatureData : sensor.moistureData
        loc.loaded = sensor.loaded
        loc.sensor = this.translate.instant(sensor.title)
      }
      loc.valid = false
      loc.value = value
      loc.valid = this.building.isValueValid(value)
      
      this.setLocation(loc)
      return loc
    })
    this.locations.splice(0, this.locations.length, ...newLocations)
    this.drawHeatmap()
  }

  validMarkers () {
    return this.locations.filter(loc => {
      return loc.value && this.building.isValueValid(loc.value)
    })
  }

  averageValue () {
    let markers = this.validMarkers()
    return markers.map(l => l.value.value).reduce((a,b) => a+b, 0) / markers.length
  }

  valueColor (value: number) {
    return gradientColorValue(value, this.minValue, this.maxValue, this.colors)
  }

  _cachedHeatmap: string
  _cachedHeatmapImage: ImageData
  drawHeatmap () {
    if (this.editable) return
    const MAX_DIST = 80
    
    let _average = this.averageValue()
    let _validLocations = this.validMarkers()
    let _bound = this.getViewBound()
    
    let opt: IHeatmapOptions = {
      version: 'v1',
      width: _bound.width, height: _bound.height, average: _average,
      minValue: this.minValue, maxValue: this.maxValue, colors: this.colors.slice(0),
      markers: _validLocations.map(loc => {
        return {
          x: loc.viewPos.x, y: loc.viewPos.y, value: loc.value.value
        }
      })
    }
    let elm = this.canvas.nativeElement as HTMLCanvasElement
    let ctx = elm.getContext('2d')
    let cacheKey = JSON.stringify(opt)
    if (cacheKey == this._cachedHeatmap) {
      if (this._cachedHeatmapImage) {
        if (this.heatmapRenderTimeout) clearTimeout(this.heatmapRenderTimeout)
        this.heatmapRenderTimeout = setTimeout(() => ctx.putImageData(this._cachedHeatmapImage, 0,0), 100)
      }
      return
    }
    this._cachedHeatmap = cacheKey
    
    let image = ctx.createImageData(opt.width, opt.height)
    let numPixels = opt.width * opt.height
    let data = image.data
    let defaultColor = 'lightgray'
    let background = tinycolor(defaultColor).toRgb()
    if (!isNaN(opt.average)) {
      background = this.valueColor(opt.average)
    }
    let x = 0
    let y = 0
    
    for (var i=0; i<(numPixels*4); i+=4) {
      x += 1
      if (x >= opt.width) {
        x = 0
        y += 1
      }
      let dist = 99999
      let nearestLoc: {x: number, y: number, value: number} | null = null
      let color = background
      
      // find nearest marker
      for (var loc of opt.markers) {
        let locy = loc.y
        let sdist = Math.sqrt(Math.pow(loc.x-x, 2) + Math.pow(locy-y, 2)) 
        if (sdist < MAX_DIST && sdist < dist) { 
          if (sdist < dist) {
            dist = sdist
            nearestLoc = loc
          }
        }
      }

      // set color based on nearest marker (if found)
      if (nearestLoc) {
        let distPercent = (MAX_DIST * 1.5) / 100 * dist
        let alpha = 1 - (1 / 100 * distPercent)
        let value = nearestLoc.value
        color = this.valueColor(value)
        color.a = alpha
      }

      data[i] = color.r
      data[i+1] = color.g
      data[i+2] = color.b
      data[i+3] = color.a * 255
    }
    this._cachedHeatmapImage = image
    let bg = background
    elm.width = opt.width
    elm.height = opt.height
    if (isNaN(opt.average)) {
      elm.style.backgroundColor = defaultColor
    } else {
      let bgColor = "rgb(" + bg.r + ', ' + bg.g + ', ' + bg.b + ')'
      if (elm.style.backgroundColor != bgColor) {
        elm.style.backgroundColor = bgColor
      }
      if (this.heatmapRenderTimeout) clearTimeout(this.heatmapRenderTimeout)
      this.heatmapRenderTimeout = setTimeout(() => ctx.putImageData(image, 0,0), 100)
    }
  }
  heatmapRenderTimeout
}

/*
console['image'] = function(url, width, height, size = 100) {
  var image = new Image();
  image.onload = function() {
    var style = [
      'font-size: 1px;',
      'padding: ' + height/2 + 'px ' + width/2 + 'px;',
      'background: url('+ url +') no-repeat;',
      'background-size: contain;'
     ].join(' ');
  };
  image.src = url;
};

function imagedata_to_image(imagedata) {
  var canvas = document.createElement('canvas');
  var ctx = canvas.getContext('2d');
  canvas.width = imagedata.width;
  canvas.height = imagedata.height;
  ctx.putImageData(imagedata, 0, 0);

  var image = new Image();
  image.src = canvas.toDataURL();
  return image;
}*/

interface IHeatmapOptions {
  version: string
  width: number, height: number
  markers: {x: number, y: number, value: number}[]
  average: number, minValue: number, maxValue: number, colors: string[]
}

@Component({
  selector: 'select-device',
  template: `
<ion-header>
  <ion-toolbar>
  <ion-title mode="md" [translate]="text.general.select_device"></ion-title>
  <ion-buttons slot="primary">
      <ion-button (click)="dismiss()" >
          <ion-icon name="close-outline"></ion-icon>
      </ion-button>
      </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ag-loading [loading]="loading" message="{{text.general.loading | translate}}" (retry)="reload()"></ag-loading>
  <div *ngIf="isLoaded">
    <div style="margin-top: 10px !important;" class="flex flex-column fade">
      <div class='empty-message' *ngIf="devices.length == 0">{{emptyMessage|translate}}</div>
      <div [class]="{selected: isSelected(device)}" (click)="select(device)" class="flex-row select-item device-type-card" style="margin: 5px 10px;" 
        *ngFor="let device of devices">
        <div class="flex flex-column select-item-content">
          <div class="select-item-label">{{device.label}}</div>
          <div class="select-item-name">{{device.name}}</div>
        </div>
        <div style="padding: 10px;" class="flex-column flex-center-center img-container">
          <img src="{{getDeviceImg(device.type)}}" class="device-pic">
        </div>
      </div>
    </div>
    <ion-fab vertical="bottom" horizontal="end" slot="fixed" >
      <ion-fab-button [disabled]="selected.length == 0" color="primary" (click)="accept()">
          <ion-icon name="checkmark-outline"></ion-icon>
      </ion-fab-button>
    </ion-fab>
  </div>
</ion-content>
`,
  styles: [`

.selected {
  border: 3px solid #007aa3 !important;
  opacity: 1 !important;
  filter: grayscale(0) !important;
}
.select-item {
  border: 3px solid transparent;
  opacity: 0.9;
  filter: grayscale(1);
}
.empty-message {
  font-size: 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 10px;
  border-radius: 5px;
  color: dimgray;
}
  `],
  styleUrls: ['farmer-building.scss']
})
export class DeviceSelector {
  devices: Device[] = []
  selected: Device[] = []
  loading = new LoadingState()
  @Input() ignore: Device[] = []
  @Input() include: Device[] = []
  @Input() types: string[] = [agTypes.farmerDeviceTypes.cellularSensorSpear.value]
  @Input() emptyMessage: string = TEXT.building.no_available_devices
  @Output() onSelect = new EventEmitter<Device[]>()
  constructor (
    private farmer: FarmerService, 
    private modalController: ModalController
  ) {}

  text = TEXT

  _subscriptions: Subscription[] = []
  listen (s: Subscription) { this._subscriptions.push(s) }
  ngOnDestroy () { this._subscriptions.map(s => s.unsubscribe()); this._subscriptions = [] }

  isLoaded = false
  ngOnInit () {
    this.listen(this.loading.events.subscribe(x => {
      this.isLoaded = x.is_success()
    }))
    this.reload()
    this.listen(this.farmer.onRefresh.subscribe(x => this.reload()))
  }
  onChange (evt) {
    //let device = this.devices.find(x => x.id.id == evt.target.value)
    //this.onSelect.emit(device)
  }
  getDeviceImg (deviceType) {
    return deviceImage(deviceType)
  }
  isSelected (device: Device) {
    return this.selected.includes(device)
  }
  select (device: Device) {
    if (!this.selected.includes(device))
      this.selected.push(device)
    else {
      this.selected = this.selected.filter(x => x.id.id != device.id.id)
    }
  }
  dismiss () { this.modalController.dismiss(null) }
  accept () {
    let selected: Device[] = []
    for (var d of this.selected) {
      if (!selected.includes(d)) selected.push(d)
    }
    this.onSelect.emit(selected)
    this.modalController.dismiss(selected)
  }

  async reload () {
    this.loading.loading(true)
    let freeDevices = await this.farmer.listFreeDevices()
    let ignoreIds = this.ignore.map(d => d.id.id)
    let devices: Device[] = [...freeDevices, ...this.include]
    let seen = {}
    this.devices = devices.filter(device => {
      if (seen[device.id.id]) return false
      seen[device.id.id] = true
      return this.types.includes(device.type) && !ignoreIds.includes(device.id.id)
    })
    this.loading.success()
  }
}
