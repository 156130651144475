

<ion-content>
  
<ag-loading *ngIf="!loading.is_success()" [loading]="loading" message="" ></ag-loading>
<div class="login-page">
  <div  class="ag-card login-frame">
    <div class="login-frame-inner">
    <div class="login-header" >
      <img src="../../assets/logo-login.png" />
    </div>
    <div (click)="openHelp()" style="cursor: pointer; z-index: 99999; color: black; text-decoration: none; border-radius: 5px; padding: 2px 6px; display: flex; opacity: 0.7; position: absolute; left: 5px; bottom: 0px;">
      <ion-icon style="width: 30px; height: 30px;" name="help-circle-outline"></ion-icon>
      <span style="display: flex; font-weight: bold; align-self: center; margin: 0 5px;">{{text.general.help | translate}}</span>
    </div>
    <div (click)="reload()" style="position: absolute; right: 0; top: 0; font-size: 18px; padding: 10px;">
      <ion-icon name="refresh"></ion-icon>
    </div>
    <div style="position: absolute; left: 14px; top: -4px; font-size: 14px;">
      <ion-select [(ngModel)]="settings.locale" (ngModelChange)="updateLocale()" interface="popover" >
        <ion-select-option value="" [translate]="text.general.select_language"></ion-select-option>
        <ion-select-option *ngFor="let lang of languages" [value]="lang.locale">{{lang.name_en}}</ion-select-option>
      </ion-select>
    </div>
    <div [hidden]="mode != 'LOGIN'" class="login-content">
      <form (ngSubmit)="login()" >
        <div class="login-field">
          <input name="email" placeholder="{{text.general.email|translate}}" tabIndex="1" autocomplete="false" (focus)="onFocus()" (focusout)="onFocusOut()" type="email" [(ngModel)]="loginForm.username" autocapitalize="none" />
        </div>
        <div class="login-field">
          <input name="password" placeholder="{{text.general.password|translate}}" tabIndex="2" autocomplete="off" [type]="passwordVisible ? 'input' : 'password'" (focus)="onFocus()" (focusout)="onFocusOut()" [(ngModel)]="loginForm.password" autocapitalize="none" />
          <ion-icon class="input-icon" *ngIf="!passwordVisible" (mousedown)="setPasswordVisible($event, true)"  name="eye-off"></ion-icon>
          <ion-icon class="input-icon" *ngIf="passwordVisible" (mousedown)="setPasswordVisible($event, false)" name="eye"></ion-icon>          
        </div>
        <div class='forgot-password-section'>
          <div class="forgot-password" style="cursor: pointer; font-size: 13px;" (click)="forgotPassword()">{{text.profile.forgot_password|translate}}?</div>
        </div>
        <div *ngIf="loginNote" style="display: flex;justify-content: center;">
          <div class="login-note">{{loginNote|translate}}</div>
        </div>
        <div class="login-container">
          <button [disabled]="!canLogin()" type="submit" class="login-button" tabIndex="3">
            {{text.profile.login|translate}}
          </button>
        </div>
      </form>
    </div>
    <div [hidden]="mode != 'SIGNUP'" class='login-content'>
      <div #recaptcha ></div>
      <form *ngIf="captchaLoaded"  (ngSubmit)="signup()">
        <div class="login-field">
          <input tabIndex="1" name="email" placeholder="{{text.general.email|translate}}" autocomplete="off" (focus)="onFocus()" (focusout)="onFocusOut()" type="email" [(ngModel)]="signupForm.email" autocapitalize="none" />
        </div>
        <div class="login-field">
          <input tabIndex="2" name="password" placeholder="{{text.general.password|translate}}" autocomplete="off" [type]="passwordVisible ? 'input' : 'password'" (focus)="onFocus()" (focusout)="onFocusOut()" [(ngModel)]="signupForm.password" autocapitalize="none" />
          <ion-icon class="input-icon" (mousedown)="setPasswordVisible($event, true)" [hidden]="passwordVisible" name="eye-off"></ion-icon>
          <ion-icon class="input-icon" (mousedown)="setPasswordVisible($event, false)" [hidden]="!passwordVisible" name="eye"></ion-icon>
        </div>
        <div class="login-field">
          <input tabIndex="3" name="first" placeholder="{{text.general.first_name|translate}}" autocomplete="off" (focus)="onFocus()" (focusout)="onFocusOut()" [(ngModel)]="signupForm.firstName" />
        </div>
        <div class="login-field">
          <input tabIndex="4" name="last" placeholder="{{text.general.last_name|translate}}" autocomplete="off" (focus)="onFocus()" (focusout)="onFocusOut()" [(ngModel)]="signupForm.lastName" />
        </div>
        <div class="item-container"  style="display: flex;">
          <div (click)="toggleTos()" style="cursor: pointer; padding: 20px 5px; flex-grow: 1;display: flex; flex-direction: column; align-self: center;">
            {{text.profile.tos_agree|translate}}
          </div>
          <ion-checkbox style="margin: 25px 10px;" name="tos" [(ngModel)]="signupForm.tos" slot="end"></ion-checkbox> <!---->
        </div>
        <div class="validate-error" *ngIf="signupErrors().length > 0">{{signupErrors()[0].message|translate:signupErrors()[0].params}}</div>
        <div class="login-container">
          <button style="margin-top: 10px;" [disabled]="!canSignUp()" type="submit" class="login-button" tabIndex="5"> 
            {{text.profile.sign_up | translate}}
          </button>
        </div>
      </form>
    </div>
    <div class="login-footer" style="flex-direction: row; justify-content: center; margin-bottom: 40px;">
      <div></div>
      <div class="switch-button" *ngIf="mode == 'SIGNUP'" >{{text.profile.already_registered|translate}}?
        <div style="cursor: pointer;" class="switch-text" (mousedown)="switchMode('LOGIN')">{{text.profile.sign_in|translate}}!</div>
      </div>
      <div class="switch-button" *ngIf="mode == 'LOGIN'">{{text.profile.not_a_member|translate}}? 
        <div style="cursor: pointer;" class="switch-text" (mousedown)="switchMode('SIGNUP')">{{text.profile.register|translate}}!</div>
      </div>
    </div>
    <div style="position: absolute; right: 5px; bottom: 7px; font-size: 12px; text-align: end; margin: 2px 5px;">
      {{upgrade.versionLabel}}
    </div>
  </div>
  </div>
</div>
</ion-content>