import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { init as sentryAngularInit, captureException }  from "@sentry/angular";
import { init as SentryCapacitorInit } from "@sentry/capacitor";
import { defineCustomElements } from '@ionic/pwa-elements/loader';

import build from 'src/build.json'

console.log('build version:', build.version)
console.log('environment:', environment)

import { enableProdMode } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Capacitor } from '@capacitor/core';

if (environment.production) {
  enableProdMode();
}

// https://stackoverflow.com/questions/49198268/how-to-overcome-loading-chunk-failed-with-angular-lazy-loaded-modules
const serverErrorsRegex = new RegExp(
  `Loading chunk|Failed to fetch|(reading 'isProxied')|NetworkError|Cstr is undefined`
);
const SENTRY_DNS = "https://10b6a895641e45dbb2c40fe3edecf3e4@sentry2.agrolog.io/2"

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(success => {
    console.log(`bootstrap success!`)
    sentryAngularInit({
      dsn: SENTRY_DNS, 
      environment: environment.name, 
      release: build.version, 
      tracesSampleRate: 1.0,
      //maxBreadcrumbs: 0,
      ignoreErrors: [serverErrorsRegex],
      beforeSend(event, hint) {
        let error = hint.originalException
        if (error instanceof HttpErrorResponse && error.status == 0) {
          return null
        }
        return event;
      }
    })
    if (Capacitor.isNativePlatform()) {
      SentryCapacitorInit({
        dsn: SENTRY_DNS
      })
    }
  })
  .catch(error => {
    captureException(error)
    alert("Error occured: " + error.message);
    console.error(error)
  });

defineCustomElements(window);