<ion-header>
    <ion-toolbar>
    <ion-title mode="md">{{settings.label}} {{text.general.settings | translate}}</ion-title>
    <ion-buttons slot="primary">
        <ion-button (click)="dismiss()" >
            <ion-icon name="close-outline"></ion-icon>
        </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content class="dialog">
    <ag-loading [loading]="saving" message="{{text.general.saving | translate}}" (retry)="saveSettings()"></ag-loading>
    <div class="dialog-background"></div>
    
    <div *ngIf="entity" class="dialog-content">
        <ion-list>
            <ion-item [translate]="text.settings.use_building" *ngIf="hasBuilding"></ion-item>
        </ion-list>

        <ion-list>
            <ion-item>
                <ion-label>{{text.general.serial|translate}}: {{entity.name}}</ion-label>
            </ion-item>
        </ion-list>

        <ion-list>
            <ion-item>
                <ion-label position="stacked" [translate]="text.general.device_name"></ion-label>
                <ion-input [(ngModel)]="settings.label" placeholder="Label"></ion-input>
            </ion-item>
            <ion-item *ngIf="!hasBuilding">
                <ion-select [placeholder]="text.general.crop_type | translate" [(ngModel)]="settings.crop_type" interface="popover" ok-text="Okay" cancel-text="Cancel">
                    <ion-select-option value="" [translate]="text.general.no_crop"></ion-select-option>
                    <ion-select-option *ngFor="let crop of crops" [value]="crop.key">{{crop.title|translate}}</ion-select-option>
                </ion-select>
            </ion-item>
        </ion-list>
    
    
    <ion-list *ngIf="!hasBuilding">
        <ion-list-header [translate]="text.general.alarm_threshold"></ion-list-header>
    
        <ion-item lines="none">
            <ion-label position="stacked" [translate]="text.general.temperature"></ion-label>
            <ion-range [(ngModel)]="sample_temperature_threshold" dualKnobs="true"  min="-20" max="60" color="secondary">
                <ion-label style="min-width: 25px" slot="start">{{sample_temperature_threshold.lower}}</ion-label>
                <ion-label style="min-width: 25px" slot="end">{{sample_temperature_threshold.upper}}</ion-label>
            </ion-range>
        </ion-item>

        <ion-item lines="none" *ngIf="hasMoisture()">
            <ion-label position="stacked" [translate]="text.general.moisture"></ion-label>
            <ion-range class="range-slider" [(ngModel)]="sample_emc_threshold" dualKnobs="true" pin  min="0" max="100" color="secondary">
                <ion-label style="min-width: 25px" slot="start">{{sample_emc_threshold.lower}}</ion-label>
                <ion-label style="min-width: 25px" slot="end">{{sample_emc_threshold.upper}}</ion-label>
            </ion-range>
        </ion-item >
            
        <ion-item lines="none">
            <ion-label position="stacked" [translate]="text.general.ambient_temperature"></ion-label>
            <ion-range [(ngModel)]="ambient_temperature_threshold" dualKnobs="true" pin  min="-20" max="60" color="secondary">
                <ion-label style="min-width: 25px" slot="start">{{ambient_temperature_threshold.lower}}</ion-label>
                <ion-label style="min-width: 25px" slot="end">{{ambient_temperature_threshold.upper}}</ion-label>
            </ion-range>
        </ion-item >
        
        <ion-item lines="none" *ngIf="hasMoisture()">
            <ion-label position="stacked" [translate]="text.general.ambient_moisture"></ion-label>
            <ion-range [(ngModel)]="ambient_emc_threshold" dualKnobs="true" pin  min="0" max="100" color="secondary">
                <ion-label style="min-width: 25px" slot="start">{{ambient_emc_threshold.lower}}</ion-label>
                <ion-label style="min-width: 25px" slot="end">{{ambient_emc_threshold.upper}}</ion-label>
            </ion-range>
        </ion-item>
        

    </ion-list>


    <ion-list>
        <ion-list-header [translate]="text.general.logging">
        </ion-list-header>
        <ion-item>
            <ion-label position="stacked" [translate]="text.general.report_interval"></ion-label>
            <ion-select placeholder="{{text.general.select_reporting | translate}}" [(ngModel)]="settings.report_interval" interface="popover" ok-text="Okay" cancel-text="Cancel">
                <ion-select-option *ngFor="let interval of ReportIntervals" [value]="interval.minutes">{{interval | formatTime}}</ion-select-option>
            </ion-select>
        </ion-item>
    </ion-list>

    <ion-list *ngIf="showQr">
        <qr-code [value]="qr"></qr-code>
    </ion-list>

    <div *ngIf="!hasBuilding" style="margin-top: 100px;">
        <ion-button color="danger" (click)="unclaimDevice()" [translate]="text.device.remove_device"></ion-button>
    </div>
</div>
<ion-fab vertical="bottom" horizontal="end" slot="fixed" >
    <ion-fab-button [disabled]="canSave" color="primary" (click)="saveSettings()">
        <ion-icon name="checkmark-outline"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-content>