import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AlertController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "src/app/services/auth.service";
import { PaymentService } from "src/app/services/payment.service";
import { TEXT } from "src/app/texts";

@Component({
  template: `
  <ion-header>
  <ion-toolbar>
  <ion-title mode="md" [translate]="text.profile.delete_account_title"></ion-title>
  <ion-buttons slot="primary">
      <ion-button (click)="dismiss()" >
          <ion-icon name="close-outline"></ion-icon>
      </ion-button>
      </ion-buttons>
  </ion-toolbar>
  </ion-header>
  <ion-content >
    <div class="flex flex-column" style="padding: 15px; background-color: white; height: 100%">
      <ion-item>
        {{email}}
      </ion-item>
      <ion-item>
        {{text.profile.delete_account_confirm|translate}}
      </ion-item>

      <ion-item>
        <ion-label >{{text.general.yes|translate}}</ion-label>
        <ion-checkbox (ionChange)="onToggleConfirm($event)" [(ngModel)]="confirmed"></ion-checkbox>
      </ion-item>

      <ion-button [disabled]="!confirmed" (click)="sendDeleteAccountRequest()" >
        {{text.profile.delete_account|translate}}
      </ion-button>
    </div>
  </ion-content>
  `,
  styleUrls: ['login-page.scss']
})
export class DeleteAccountPageComponent {
  constructor (
    private translate: TranslateService,
    private payment: PaymentService,
    private alertController: AlertController,
    private route: Router,
    private auth: AuthService
  ) {
  }
  text = TEXT
  confirmed = false
  email = null


  onToggleConfirm (evt) {

  }

  ngOnInit () {
    this.auth.getAuth().subscribe(x => {
      this.email = x.userDetails.email
    })
  }

  async sendDeleteAccountRequest () {
    let message = this.translate.instant(this.text.profile.delete_account_success) // "Your request have been received. You will receive a confirmation mail when your request for deletion have been processed. Please contact sales@supertech.dk for any question."
    //let header = "Request received"
    try {
      await this.payment.sendDeletionRequest()
    } catch (err) {
      message = this.translate.instant(this.text.profile.delete_account_failed) // "Failed to send request. Please contact us directly on sales@supertech.dk"
    }
    let confirmAlert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: this.translate.instant(this.text.profile.delete_account_title),
      message: message,
      buttons: [
        {
          text: this.translate.instant(this.text.general.ok),
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            this.dismiss()
          }
        }
      ]
    })
    await confirmAlert.present();
  }
  dismiss () {
    this.route.navigateByUrl('/')
  }
}