import { Component } from "@angular/core";
import { debounceTime } from "rxjs";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { BluetoothService, DeviceStatus, IDevice, IDeviceStatus } from "src/app/services/bluetooth.service";

interface IDisplayDevice {
  name: string
  address: string
  status: DeviceStatus
}


@Component({
  template: `
<div style="height: 100%">
  <ion-button (click)="startScan()">Scan for devices</ion-button>
  <ion-item *ngFor="let device of devices">
    <ion-label>{{device.name}}</ion-label>
    <ion-label>{{ status.get(device.address)?.status }}</ion-label>
  </ion-item>
  <pre>
  {{logs}}
  </pre>
</div>
`,
  selector: 'test-building-telemetry'
})
export class TestBluetooth {
  logs = ''
  devices: IDevice[] = []
  status = new Map<string, IDeviceStatus<any>>()
  
  constructor (
    private ble: BluetoothService
  ) {}
  ngOnInit () {
    this.ble.devices.subscribe(bleDevices => {
      this.devices = bleDevices.filter(x => x.stored)
    })
    this.ble.deviceStatus.subscribe(status => {
      this.status = status
    })
  }
  startScan () {
    //this.ble.startScanning()
  }
}









/*
interface IBleMessage {
  address: string
  value: string
  buffer: ArrayBuffer
}


function _arrayBufferToBase64( buffer ) {
  var binary = '';
  var bytes = new Uint8Array( buffer );
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
      binary += String.fromCharCode( bytes[ i ] );
  }
  return window.btoa( binary );
}

function str2ab32(time: number) {
  const buf = new ArrayBuffer(4);
  const dv = new DataView(buf);

  const timeAsHex = time.toString(16);
  const parts = timeAsHex.split(/(.{2})/).filter((O) => O);
  const timeAsHexReversed = parts.reverse().join("");

  dv.setUint32(0, parseInt(timeAsHexReversed, 16));

  return buf;
}
function Uint8ToString(u8a: Uint8Array){
  var CHUNK_SZ = 0x8000;
  var c = [];
  for (var i=0; i < u8a.length; i+=CHUNK_SZ) {
    c.push(String.fromCharCode.apply(null, u8a.subarray(i, i+CHUNK_SZ)));
  }
  return c.join("");
}
// U16 to array buffer
function str2ab16(str: string) {
  const buf = new ArrayBuffer(str.length);
  const bufView = new Uint8Array(buf);
  for (let i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}



  log (msg: string) {
    this.logs = '\n' + msg + this.logs
  }
  
  async initialize () {
    this.log('initializing...')
    await BleClient.initialize({})
    this.log('initialized!')
  }
  async scan () {
    let device = await BleClient.requestDevice(BLE_SCAN_CONFIG)
    this.updateDevice(device.deviceId, device)
    
  }

  async connectAll () {
    let devices = this.listDevices()
    for (let device of devices) {
      if (device.status == 'disconnected') {
        this.connect(device).catch(err => {
          this.log('failed to connect: ' + err.message)
        })
      }
    }
  }
  async connect (device: IScanDevice) {
    //let device = await BleClient.requestDevice(BLE_SCAN_CONFIG)
    //console.log('found device:', device) 
    
    this.updateDevice(device.deviceId, {status: 'connecting'})
    this.log('connecting to device' + device)
    let result = await BleClient.connect(device.deviceId, err => {
      console.error('device disconnected', err)
      this.log('device disconnected - ' + err)
      this.updateDevice(device.deviceId, {status: 'disconnected'})
    })
    this.updateDevice(device.deviceId, {status: 'connected'})

    await this.startNotifications(device.deviceId)

    this.log('connected!')
    console.log('connect', result)
  }
  async findDevices () {
    let result = await BleClient.getConnectedDevices([AVOCADO_SERVICE])
    this.log('find devices: ' + JSON.stringify(result))
    for (let device of result) {
      if (device?.name.startsWith('CSS')) {
        this.updateDevice(device.deviceId, device)
      }
      
    }
    console.log('FIND:', result)
  }


  async startNotifications (deviceId: string) {
    this.log('start time notifications')
    await BleClient.startNotifications(
      deviceId, AVOCADO_SERVICE, REQUEST_TIME_CHARACTERISTIC, (value) => {
        let msg: IBleMessage = {
          address: deviceId, value: _arrayBufferToBase64(value.buffer),
          buffer: value.buffer
        }
        this.onRequestTimeStateChanged(msg)
      }
    )
    this.log('start location notifications')
    await BleClient.startNotifications(
      deviceId, AVOCADO_SERVICE, REQUEST_LOCATION_CHARACTERISTIC, (value) => {
        let msg: IBleMessage = {
          address: deviceId, value: _arrayBufferToBase64(value.buffer),
          buffer: value.buffer
        }
        this.onRequestLocationStateChanged(msg)
      }
    )
    this.log('notifications complete..')
  }


  onRequestTimeStateChanged(msg: IBleMessage) {
    
    this.log('received time information ' + msg.address)
    let value = msg.value
    this.log(msg.value)
    if (typeof value !== "undefined") {
      if (atob(value).includes("GET_TIME")) {
        this.log('  - SEND TIME')
        this.sendTime(msg);
      } else if (atob(value).includes('{"v":')) {
        this.log('  - V')
        const reqTimeEnabledMessage = atob(value);
        const reqTimeEnabledAsObj = JSON.parse(reqTimeEnabledMessage);
        this.log(reqTimeEnabledAsObj.v)
        //this.deviceVersion = reqTimeEnabledAsObj.v;
      }
    }
  }

  onRequestLocationStateChanged (msg: IBleMessage) {
    this.log('request location')
    this.log(msg.value)
    let value = msg.value
    if (typeof value !== "undefined") {
      if (atob(value).includes("GET_LOC")) {

        
          this.sendLocation(msg);
        } else if (atob(value).includes('{"v":')) {
          this.log(' - location v ?')
        }
      }
    }
  
    async sendLocation (msg: IBleMessage) {
      this.log(' - send location')
      
      let loc = await this.locationService.getCurrentLocation()
  
      
      const coords = loc.coords
      this.log(' - loc: ' + JSON.stringify(loc))
      
      let buffer: ArrayBuffer
      if (coords != null) {
        buffer = str2ab16(
          '{"latitude": ' +
          JSON.stringify(coords.latitude) +
          ', "longitude": ' +
          JSON.stringify(coords) +
          "}"
        );
      } else {
        buffer = str2ab16(
          '{"latitude": ' + 0 + ', "longitude": ' + 0 + "}"
        );
      }
      const loctoSendAsUint8array = new Uint8Array(buffer);
      let result = _arrayBufferToBase64(loctoSendAsUint8array);
      
      this.log(' - send: ' + result)
      await BleClient.write(
        msg.address, AVOCADO_SERVICE, SEND_LOCATION_CHARACTERISTIC,
        textToDataView(result)
      )
      this.log(' - sent location')
      //this.writeLocation(loctoSend, locationRequestMessage);

    }
  
    async sendTime(msg: IBleMessage) {
      const nowAsArrayBuffer = str2ab32(Math.round(new Date().getTime() / 1000));
      const nowAsString = new Uint8Array(nowAsArrayBuffer);
  
      let value = Uint8ToString(nowAsString)
      await BleClient.write(
        msg.address, AVOCADO_SERVICE, SEND_TIME_CHARACTERISTIC,
        textToDataView(value)
      )

*/