import { Injectable } from "@angular/core";
import { AttributeService } from "./entity.service";
import { AGUserService } from "./user.services";
import { SwPush } from '@angular/service-worker';
import { EntityId, EntityType } from '../models/entity.model';
import { AttributeScope } from '../models/telemetry.model';


import { getMessaging, getToken, onMessage, isSupported, Unsubscribe } from "firebase/messaging";
import { AuthService } from "./auth.service";
import { map, switchMap } from "rxjs/operators";

import { BroadcastChannel, createLeaderElection } from 'broadcast-channel';
import { onAuthenticationChange } from "../util";
import { Store } from "@ngrx/store";
import { AppState } from "../state";
import { captureException, captureMessage } from "@sentry/angular";


import { FCM } from "@capacitor-community/fcm";
import { PushNotifications } from "@capacitor/push-notifications";
import { LocalNotifications } from "@capacitor/local-notifications"
//import { LocalNotifications } from '@awesome-cordova-plugins/local-notifications/ngx';

import { BehaviorSubject } from 'rxjs'

const KEY = 'registrationTokens'

//import { FCM } from '@awesome-cordova-plugins/fcm/ngx';
import { Capacitor } from "@capacitor/core";
import { PermissionService } from "./permissions.service";

let FCM_KEY = "BE_OuriOia6hBGw8W2CiipkooA1OWfBu63KX0-Ns0ohALy5JFlxzRJIAKz3YWI6WsiKEM8wtuoRftoTCHKPJ7XE"

interface IMessaingServiceState {
  hasPermission: boolean
}

@Injectable()
export class MessagingService {
  token: string | null = null
  channel = new BroadcastChannel('notifyChannel');
  isLeader = false
  elector = createLeaderElection(this.channel, {
    fallbackInterval: 2000, // optional configuration for how often will renegotiation for leader occur
    responseTime: 1000, // optional configuration for how long will instances have to respond
  });
  _subscriber: Unsubscribe

  registerState = new BehaviorSubject<{
    permission?: NotificationPermission, reg?: ServiceWorkerRegistration, 
    sw?: ServiceWorkerState, token?: string, 
    status: 'init' | 'registering' | 'ready',
    isSubscribing?: boolean
  }>({status: 'init'})

  private async requestNotificationPermissions () {
    if (!Capacitor.isNativePlatform()) {
      if (window.Notification) {
        window.Notification.permission
        await window.Notification.requestPermission()
      }
    } else {
      await PushNotifications.requestPermissions()
    }
  }
  // TODO: check notification permissions without requesting
  private async checkNotificationPermissions () {
    if (!Capacitor.isNativePlatform()) {
      return window.Notification?.permission == 'granted'
    } else {
      return (await PushNotifications.checkPermissions()).receive == 'granted'
    }
  }

  constructor (
    private authService: AuthService,
    private attributeService: AttributeService,
    private store: Store<AppState>,
    private permissions: PermissionService
  ) {
    
    this.permissions.registerPermission('NOTIFICATION', {
      requestPermission: async () => await this.requestNotificationPermissions(),
      checkPermission: async () => await this.checkNotificationPermissions(),
      openSettings: async () => await this.permissions.openPermissionSettings()
    })

    onAuthenticationChange(this.store).subscribe(isAuth => {
      if (isAuth) {
        this.permissions.requestPermission('NOTIFICATION')
      }
    })

    this.permissions.state.subscribe(perms => {
      this.registerState.next({
        ...this.registerState.value, 
        permission: perms.NOTIFICATION.enabled ? 'granted' : 'denied'
      })
    })

    this.registerState.subscribe(state => {
      if (!state.token && state.permission == 'granted' && state.status == 'init') {
        this.register()
      }


      if (this.token != state.token) {
        this.token = state.token
        if (state.token) {
          this.saveCustomerToken(state.token)
        }
      }
      if (state.token) {
        console.log('received notification token', state.token)
        //this.permissions.setPermission('NOTIFICATION', true)
      }
    })
    
    this.initialize()
  }

  initialize () {
    onAuthenticationChange(this.store).subscribe(x => {
      this.clear()
      if (x) this.registerState.next(this.registerState.value)
    })

    
    
    let site_name = new URL(location.href).hostname
    if (site_name.startsWith('localhost')) {
      console.warn('is localhost, skipping leader election for notifications')
    } else {
      this.elector.awaitLeadership().then(()=> {
        this.isLeader = true
      })
    }
  }

  clear () {
    if (this._subscriber) {
      this._subscriber()
    }
  }

  async subscribeFirebaseCloudMessaging () {
    return new Promise<string|null>((resolve, reject) => {
      //this.checkPermissions()
    })
  }

  async getCustomerToken () {
    return this.authService.getAuth().pipe(
      switchMap(auth => {
        let customerId: EntityId<EntityType.USER> = {
          entityType: EntityType.USER, id: auth.authUser.userId
        }
        return this.attributeService.getEntityAttributes(
          customerId, AttributeScope.SERVER_SCOPE, [KEY])
      }),
      map(attrs => {
        return attrs.find(attr => attr.key == KEY)
      })
    ).toPromise()
  }

  async saveCustomerToken (token: string) {
    if (!token) return
    let attr = await this.getCustomerToken()
    
    let value: string[] = []
    
    try {
      if (attr == undefined) {
        value = []
      } else if (Array.isArray(attr.value)) {
        value = attr.value
      } else if (attr.value == undefined) {
        value = []
      } else {
        value = JSON.parse(attr.value) as string[]
      }
    } catch (err) {
      captureMessage('FCM: failed to parse thingsboard notification attribute', {extra: {attribute: attr}, level: 'error'})
      console.error('FCM: failed to parse thingsboard value', attr)
    }

    
    if (value.findIndex(v => v == token) != -1) {
      return
    }
    value.push(token)

    await this.authService.getAuth().pipe(
      switchMap(auth => {
        
        let customerId: EntityId<EntityType.USER> = {
          entityType: EntityType.USER, id: auth.authUser.userId
        }
        return this.attributeService.saveEntityAttributes(
          customerId, AttributeScope.SERVER_SCOPE, [
            {key: KEY, value: value}
          ]
        )
      })
    ).toPromise()
    this.token = token
  }

  async getDeliveredNotifications () {
    const notificationList = await PushNotifications.getDeliveredNotifications();
    notificationList.notifications.map(x => {
    })
  }

  /*async checkPermissions (): Promise<boolean> {
    let hasPermission = await this._checkPermissions()
    
    if (!hasPermission) {
      this.permissions.setPermission('NOTIFICATION', false)
    }

    this.registerState.next({
      ...this.registerState.value, permission: hasPermission ? 'granted' : 'denied'
    })
    return hasPermission
  }*/

  /*async _checkPermissions (): Promise<boolean> {
    if (!Capacitor.isNativePlatform()) {
      if (!window.Notification?.requestPermission) {
        return false
      }
      let result = await window.Notification.requestPermission()
      return result == 'granted'
    } else {
      let perm = await LocalNotifications.requestPermissions()
      return perm.display == 'granted'
    }
  }*/

  async registerNative () {
    console.log('FCM: register')
    await PushNotifications.addListener('registration', token => {
      console.log('Registration token: ', token.value);
    });
  
    await PushNotifications.addListener('registrationError', err => {
      console.error('Registration error: ', err.error);
    });
  
    await PushNotifications.addListener('pushNotificationReceived', notification => {
      this.getDeliveredNotifications()
    });
  
    await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
    });

    // external required step
    // register for push
    let permission = await PushNotifications.requestPermissions();
    console.log('FCM permissions: ' + JSON.stringify(permission))
    await PushNotifications.register();
    
    FCM.getToken()
      .then((r) => {
        console.log('FCM received token: ' + JSON.stringify(r))
        this.registerState.next({...this.registerState.value, token: r.token})
        //this.getDeliveredNotifications()
      })
      .catch((err) => console.error(err));

    // Remove FCM instance
    /*FCM.deleteInstance()
      .then(() => alert(`Token deleted`))
      .catch((err) => console.error(err));*/

    // Enable the auto initialization of the library
    FCM.setAutoInit({ enabled: true }).then((r) => {
      console.log('FCM set auto enable: ' + JSON.stringify(r))
    });

    // Check the auto initialization status
    /*FCM.isAutoInitEnabled().then((r) => {
      console.warn("FCM: Auto init is " + (r.enabled ? "enabled" : "disabled"));
    });*/

    //await this.getDeliveredNotifications()
  }

  async registerWeb () {
    let canUseMessaging = await isSupported()
    if (!window.Notification || !canUseMessaging || !navigator.serviceWorker) {
      return console.warn('FCM: app messaging is not supported by this client', canUseMessaging)
    }
    navigator.serviceWorker.ready.then(reg => {
      console.log('service worker is', reg.active.state)
      const messaging = getMessaging();
      reg.addEventListener('notificationclick', (event) => {
        // NOTE: not sure this is ever called
      })
      this._subscriber = onMessage(messaging, payload => {
        // NOTE: shows when window is active or visible
        if (payload && payload.notification) {
          let n = payload.notification
          if (this.isLeader || !this.elector.hasLeader) {
            reg.showNotification(n.title, n)
            window.focus()
          } else {
          }
        }          
      })
      this.registerState.next({...this.registerState.value, reg: reg, sw: reg.active?.state})        
    })
    this.registerState.subscribe((state) => {
      
      const messaging = getMessaging();
      let isActivating = state.sw == 'activating' || state.sw == 'activated'
      if (!state.token && !state.reg && !isActivating && state.permission == 'granted') {
        navigator.serviceWorker.register('/firebase-messaging-sw.js').catch(err => {
          captureException(err)
        });
      }
      if (!state.isSubscribing && state.permission == 'granted' && isActivating && !state.token && state.reg) {
        this.registerState.next({...this.registerState.value, isSubscribing: true})
        getToken(messaging, {vapidKey: FCM_KEY, serviceWorkerRegistration: state.reg}).then(token => {
          this.registerState.next({...this.registerState.value, token: token, isSubscribing: false})
        }).catch(err => {
          console.error(err)
          captureException(err)
        })
      }
    })
  }
  
  async register () {
    this.registerState.next({
      ...this.registerState.value, status: 'registering'
    })
    if (Capacitor.isNativePlatform()) {
      await this.registerNative()
    } else {
      await this.registerWeb()
    }
  }

}
