import { Injectable } from "@angular/core"
import { Capacitor } from "@capacitor/core"
import { Network } from "@capacitor/network"
import { BehaviorSubject } from "rxjs"


@Injectable()
export class PhoneService {

  networkReady = new BehaviorSubject<boolean>(true)

  constructor (
  ) {
    this.listenForNetwork()
  }

  async listenForNetwork () {
    if (Capacitor.isNativePlatform()) {
      let status = await Network.getStatus()
      this.networkReady.next(status.connected)
      Network.addListener('networkStatusChange', status => {
        this.networkReady.next(status.connected)
      })
    } else {
      this.networkReady.next(navigator.onLine)
      window.addEventListener('online', evt => {
        this.networkReady.next(navigator.onLine)
      })
      window.addEventListener('offline', evt => {
        this.networkReady.next(navigator.onLine)
      })
    }
  }
}