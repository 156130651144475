// import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings/ngx';

import { Component, Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, MenuController, ModalController, PopoverController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { ProfilePageComponent } from './components/login-page/profile-page';
import { AggregationType } from './models/telemetry.model';
import { AuthService } from './services/auth.service';
import { FarmerService } from './services/device.service';
import { ITelemetrySettings, TelemetryService, TELEMETRY_MODE } from './services/telemetry';
import { AppState } from './state';
import { AgModal, clone, deviceIcon, TIME_UNIT } from './util';

import { MessagingService } from './services/messaging.service';
import { UpgradeService } from './services/upgrade.service';
import { AddDeviceDialogComponent } from './components/farmer-devices/farmer-create-device';
import { EntityType } from './models/entity.model';
import { Subscription } from 'rxjs';
import { parseISO } from 'date-fns'
import { captureMessage } from '@sentry/angular';

import { TEXT } from './texts';
import { TranslateService } from '@ngx-translate/core';
import { DEFAULT_LOCALE } from './constant';

@Injectable()
export class MenuService {
  showMap = false
  helpURL = "https://www.agrolog.io/help-guide" // TODO: move to environment
  text = TEXT
  constructor (
    private menu: MenuController,
    private deviceController: FarmerService,
    private router: Router,
    private popoverController: PopoverController,
    private modalController: ModalController,
    private alertController: AlertController,
    private messagingService: MessagingService,
    private version: UpgradeService,
    private agModal: AgModal,
    private translate: TranslateService
    ) {}

  async openAddAsset () {
    const dialogRef = await this.modalController.create({
      component: AddDeviceDialogComponent, componentProps: {page: EntityType.ASSET}
    });
    const { data } = await this.agModal.openModal(dialogRef)
  }
  async openAddDevice () {
    const dialogRef = await this.modalController.create({
      component: AddDeviceDialogComponent
    });
    const { data } = await this.agModal.openModal(dialogRef)
  }

  openHelp () {
    window.open(this.helpURL, "_blank")
  }

  openBuildingsPage () {
    this.menu.close()
    this.router.navigateByUrl('/buildings')
  }

  async openBilling () {
    this.menu.close()
    this.router.navigateByUrl('/billing')
  }

  openBluetoothPage () {
    this.menu.close()
    this.router.navigateByUrl('/testing/test-bluetooth')
  }

  openAlarmPage () {
    this.menu.close()
    this.router.navigateByUrl('/alarms')
  }

  async openTimeSettingsMenu (ev?) {
    let popover = await this.popoverController.create({
      component: TimeQuerySettings,
      event: ev, 
      translucent: true
    });
    return popover.present();
  }
  openMainMenu () {
    this.menu.enable(true, 'mainMenu');
    this.menu.open('mainMenu');
  }

  async openProfileMenu () {
    const dialogRef = await this.modalController.create({
      component: ProfilePageComponent,
      componentProps: {}
    });
    const { data } = await this.agModal.openModal(dialogRef)
  }
  toggleDevicesMap () {
    this.router.navigateByUrl('/map')
  }

  reload () {
    this.deviceController.reload()
  }

  refresh () {
    window.location.reload()
  }

  deviceId () {
    try {
      return MediaDeviceInfo['deviceId'] || ''
    } catch (err) {
      captureMessage('failed to retreive user device-id', 'warning')
      return ''
    }
  }

  async openAbout () {
    let message = this.version.message
    let token = this.messagingService.token || ''
    let deviceId = this.deviceId()
    let latest = this.translate.instant(this.text.general.latest)
    let latestMsg = this.version.codeVersion == this.version.latestVersion ? ' (' + latest + ')' : ''
    let msg = `
    ${message}
    ${deviceId}
    ${token}`
    let alertComplete = await this.alertController.create({
      header: this.version.app,
      subHeader: this.version.versionLabel,
      message: msg,
      buttons: [
        {
          text: this.translate.instant(this.text.general.ok),
          role: 'cancel'
        }
      ]
    });
    await alertComplete.present();
  }
}


@Component({
  selector: 'main-menu',
  styleUrls: ['app.component.scss'],
  template: `<div id="main-menu">
  <div id="menu-header">
    <div id='menu-icon'>
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="23.5" cy="23.5" r="22" stroke="#333333" stroke-width="3"/>
        <path d="M26.375 17C26.375 17 26.125 20.1902 24.5 23.503C23.5 25.4662 21 27.7974 21 31.233C21 36.9998 26.5 36.9998 26.5 36.9998C26.5 36.9998 32 37.1225 32 31.233C32 27.7974 29.375 25.4662 28.375 23.503C27.125 21.1717 26.375 17 26.375 17Z" fill="#00B5D8"/>
        <path d="M18.7286 34C18.7286 34 15 33.6438 15 23.9087C15 9.6621 19.2429 8 20.0143 8C21.6857 8 24 11.2055 24 15.5982C24 16.3105 23.8714 17.2603 23.8714 17.2603C23.8714 17.2603 23.4857 20.347 21.6857 23.5525C20.6571 25.4521 18.0857 27.7078 18.0857 31.032C18.0857 32.2192 18.3429 33.1689 18.7286 34Z" fill="#ED6D05"/>
      </svg>
    </div>
    <div id='menu-title'>AgroLog App</div>
    <div id="menu-subtitle">{{text.general.menu|translate}}</div>
  </div>
  
  <div>
    
    <ion-item (click)="menu.openAddDevice()">
      <ion-icon [src]="icons.device"></ion-icon>
      <ion-label [translate]="text.menu.add_new_device"></ion-label>
    </ion-item>
    <ion-item (click)="menu.openAddAsset()">
      <ion-icon [src]="icons.building"></ion-icon>
      <ion-label [translate]="text.menu.add_new_building"></ion-label>
    </ion-item>

    <ion-item (click)="menu.toggleDevicesMap()">
      <ion-icon name="map-sharp"></ion-icon> <!--*ngIf="!showMap" -->
      <ion-label [translate]="text.menu.map_view"></ion-label>
    </ion-item>
    <ion-item (click)="menu.openAlarmPage()">
      <ion-icon src="/assets/svg/notifications_icon.svg" ></ion-icon>
      <ion-label [translate]="text.menu.alarms"></ion-label>
    </ion-item>
    
    <ion-item (click)="menu.openBilling()">
      <ion-icon name="card-outline"></ion-icon>
      <ion-label [translate]="text.menu.billing"></ion-label>
    </ion-item>
    
    <!--<ion-item (click)="menu.openTimeSettingsMenu()">
      <ion-icon name="time-outline" ></ion-icon>
      <ion-label>Time Window</ion-label>
    </ion-item>
    <ion-item (click)="menu.openProfileMenu()">
      <ion-icon name="person-circle-outline"></ion-icon>
      <ion-label>Profile</ion-label>
    </ion-item>-->

    <ion-item (click)="menu.refresh()">
      <ion-icon name="refresh-circle-outline"></ion-icon>
      <ion-label [translate]="text.menu.refresh"></ion-label>
    </ion-item>
    <!--<ion-item (click)="menu.reload()">
      <ion-icon name="refresh-circle-outline"></ion-icon>
      <ion-label>Reload</ion-label>
    </ion-item>-->
    <ion-item (click)="menu.openAbout()">
      <ion-icon name="information-circle-outline"></ion-icon>
      <ion-label [translate]="text.menu.about"></ion-label>
    </ion-item>
    <ion-item (click)="menu.openHelp()">
      <ion-icon name="help-circle-outline"></ion-icon>
      <ion-label [translate]="text.menu.help"></ion-label>
    </ion-item>

    <!--<ion-item (click)="menu.openPermissionSettings()">
      <ion-icon name="help-circle-outline"></ion-icon>
      <ion-label>Permissions</ion-label>
    </ion-item>-->
  </div>
</div>
  `
})
export class MainMenu {
  text = TEXT
  icons = {
    building: deviceIcon('building'),
    device: deviceIcon('cellular_sensor_spear')
  }
  constructor (public menu: MenuService,
    private alertController: AlertController,
    private store: Store<AppState>
    ) {}

    ngOnInit () {}
}


interface ITimeName {
  unit: TIME_UNIT
  value: number
  ms: number
}

interface ITimeWindow {
  time: number, minInterval: number, maxInterval: number, standard: number
  value: number, unit: TIME_UNIT
}

interface ITimeRange {
  startTs: number, endTs: number
}

const SECOND = 1000
const MINUTE = SECOND * 60
const HOUR = MINUTE * 60
const DAY = HOUR * 24
const YEAR = DAY * 365

@Component({
  selector: 'time-query-settings',
  template:`
  <ion-content style="height: 100%;">
    <!--<ion-tabs>-->
    <ion-tab-bar >
        <ion-tab-button (click)="selectMode('TIME-WINDOW')" [selected]="timeSettings.mode == 'TIME-WINDOW'" tab="schedule">
          <ion-icon name="hourglass-outline"></ion-icon>
          <ion-label [translate]="text.general.realtime"></ion-label>
        </ion-tab-button>

        <ion-tab-button (click)="selectMode('HISTORY')" [selected]="timeSettings.mode == 'HISTORY'" tab="speakers">
          <ion-icon name="time-outline"></ion-icon>
          <ion-label [translate]="text.general.history"></ion-label>
        </ion-tab-button>
</ion-tab-bar>
      <!--  
      </ion-tab-bar>
    </ion-tabs>-->
    <!--
    <button *ngIf="timeSettings.mode == 'TIME-WINDOW'" (click)="selectMode('HISTORY')">History</button>
    <button *ngIf="timeSettings.mode == 'HISTORY'" (click)="selectMode('TIME-WINDOW')">Realtime</button>
-->
    <ion-item *ngIf="timeSettings.mode == 'TIME-WINDOW'">
      <ion-label [translate]="text.general.last"></ion-label>
      <!-- ok-text="Okay" cancel-text="Cancel" -->
      <ion-select [(ngModel)]="timeSettings.timeWindow" (ngModelChange)="updateLatestTime()" interface="popover" >
        <ion-select-option *ngFor="let time of timeWindowOptions" [value]="time.time">{{ time | formatTime }}</ion-select-option>
      </ion-select>
    </ion-item>
    <div *ngIf="timeSettings.mode == 'HISTORY'">
      <ion-item >
        <ion-label>{{text.general.start_time|translate}}:</ion-label>
        <ion-datetime-button datetime="start_datetime"></ion-datetime-button>

        <ion-modal [keepContentsMounted]="true" 
          (willPresent)="onModalPresent($event)" (willDismiss)="onModalDismiss($event)">
          <ng-template>
            <ion-datetime
              [locale]="locale"  
              show-default-buttons="true" 
              id="start_datetime" (ionChange)="setStartDate($event)"
              [(ngModel)]="startTime"
              > <!--[value]="startTime" (ionChange)="setStartDate($event)"-->
            </ion-datetime>
          </ng-template>
        </ion-modal>
      </ion-item>
      <ion-item >
        <ion-label>{{text.general.end_time|translate}}:</ion-label>
        <ion-datetime-button datetime="end_datetime"></ion-datetime-button>

        <ion-modal [keepContentsMounted]="true" 
          (willPresent)="onModalPresent($event)" (willDismiss)="onModalDismiss($event)">
          <ng-template>
            <ion-datetime
              [locale]="locale"
              show-default-buttons="true" 

              id="end_datetime" (ionChange)="setEndDate($event)"
              [(ngModel)]="endTime"
              > <!--[value]="endTime" (ionChange)="setEndDate($event)" -->
            </ion-datetime>
          </ng-template>
        </ion-modal>
      </ion-item>
    </div>
    <ion-item>
      <ion-label>{{text.general.interval | translate}}</ion-label>
      <!--[(ngModel)]="timeSettings.interval"-->
      <ion-select [(ngModel)]="activeInterval" (ngModelChange)="onIntervalSelected()" interface="popover">
        <ion-select-option *ngFor="let interval of intervalOptions" [value]="interval">{{interval | formatTime}}</ion-select-option>
        <!--<ion-select-option [value]="timeSettings.interval">{{activeInterval | formatTime}}</ion-select-option>-->
      </ion-select>
    </ion-item>
    <!--
    // method selection
    <ion-item>
      <ion-label>{{text.general.method|translate}}</ion-label>
      <ion-select [(ngModel)]="timeSettings.agg" (ngModelChange)="updateLatestTime()" interface="popover">
        <ion-select-option value="AVG" [translate]="text.general.average"></ion-select-option>
        <ion-select-option value="MIN" [translate]="text.general.min"></ion-select-option>
        <ion-select-option value="MAX" [translate]="text.general.max"></ion-select-option>
      </ion-select>
    </ion-item>-->
  </ion-content>
  `
})
export class TimeQuerySettings {

  constructor (
    private telemetry: TelemetryService,
    private translate: TranslateService,
    private ngZone: NgZone
  ) {
    this.timeSettings = clone(this.telemetry.settings)
  }

  text = TEXT
  now = new Date().toISOString()
  startTime
  endTime
  locale = DEFAULT_LOCALE


  // TODO: this is a hack, ion-datetime will randomly set itself back to previous value and emit ionChange
  //       only allow updates from ion-datetime if we're currently presenting the datetime-picker-modal
  isEditing = false
  onModalPresent ($event) {
    this.isEditing = true
  }

  onModalDismiss ($event) {
    this.isEditing = false
  }

  toDate (ts) { return new Date(ts).toString() }
  loadDateTimes (startTs?: number, endTs?: number) {
    let date = new Date();
    if (!startTs) { startTs = new Date().getTime() } 
    if (!endTs) { endTs = new Date().getTime() } 
    
    let startTime = new Date(startTs - date.getTimezoneOffset()*60000).toISOString()
    let endTime = new Date(endTs - date.getTimezoneOffset()*60000).toISOString()
    
    // NOTE: loading date-times everytime time-settings changes makes the browser crash
    //       I'll only allow one initial loading of date-times to get around this problem
    //       should still work the same
    if (!this.startTime) {
      this.startTime = startTime
      this.endTime = endTime
    }
  }
  setStartDate (evt) {
    
    if (this.isEditing) {
      this.refreshHistoryTime()
    }
  }
  setEndDate (evt) {
    if (this.isEditing) {
      this.refreshHistoryTime()
    }
  }
  refreshHistoryTime () {
    if (!this.startTime || !this.endTime) return
    let st = parseISO(this.startTime)
    let et = parseISO(this.endTime)
    this.updateHistory(st.getTime(), et.getTime())
  }

  activeInterval: ITimeName

  timeSettings: ITelemetrySettings = {
    timeWindow: 86400000, interval: 30 * MINUTE, agg: AggregationType.AVG,
    mode: 'TIME-WINDOW', startTs: 0, endTs: 0
  }
  
  // TODO: this list is unnessecary, use "time" in timeWindowOptions to determine unit and value automatically
  predefinedIntervals: ITimeName[] = [
    {unit: 'second', value: 10, ms: 10 * SECOND},
    {unit: 'minute', value: 2, ms: 2 * MINUTE},
    {unit: 'minute', value: 5, ms: 5 * MINUTE},
    {unit: 'minute', value: 10, ms: 10 * MINUTE},
    {unit: 'minute', value: 30, ms: 30 * MINUTE},
    {unit: 'hour', value: 1, ms: HOUR},
    {unit: 'hour', value: 2, ms: 2*HOUR},
    {unit: 'hour', value: 3, ms: 3*HOUR},
    {unit: 'hour', value: 6, ms: 6*HOUR},
    {unit: 'hour', value: 12, ms: 12 * HOUR},
    {unit: 'day', value: 1, ms: DAY},
    {unit: 'day', value: 2, ms: 2*DAY},
    {unit: 'day', value: 3, ms: 3*DAY},
    {unit: 'day', value: 7, ms: 7 * DAY},
    {unit: 'day', value: 30, ms: 30 * DAY},
    {unit: 'year', value: 1, ms: YEAR}
  ]

  timeWindowOptions: ITimeWindow[] = [
    //{time: HOUR, minInterval: 10*SECOND, maxInterval: 5*MINUTE, standard: 10*SECOND},
    //{time: 12*HOUR, minInterval: 10*MINUTE, maxInterval: HOUR, standard: 30*MINUTE},
    //{time: 3*HOUR, minInterval: 10*MINUTE, maxInterval: HOUR, standard: 30*MINUTE},
    {time: 6*HOUR, unit: 'hour', value: 6, minInterval: 10*MINUTE, maxInterval: HOUR, standard: 30*MINUTE},
    {time: DAY, unit: 'day', value: 1, minInterval: 30*MINUTE, maxInterval: 2*HOUR, standard: 1*HOUR},
    {time: 2*DAY, unit: 'day', value: 2, minInterval: 30*MINUTE, maxInterval: 2*HOUR, standard: 1*HOUR},
    {time: 3*DAY, unit: 'day', value: 3, minInterval: 30*MINUTE, maxInterval: 2*HOUR, standard: 1*HOUR},
    {time: 7*DAY, unit: 'day', value: 7, minInterval: 2*HOUR, maxInterval: DAY, standard: 2*HOUR},
    {time: 30*DAY, unit: 'day', value: 30, minInterval: DAY, maxInterval: DAY, standard: 30*MINUTE},
    {time: YEAR, unit: 'year', value: 1, minInterval: 7*DAY, maxInterval: 7*DAY, standard: DAY}
  ]

  onIntervalSelected () {
    // TODO: save selected interval, use as preferred interval for current time-window (only for realtime)
    this.updateInterval()
  }

  updateInterval () {
    let validInterval = this.intervalOptions.find(x => x.ms == this.activeInterval?.ms)

    if (!validInterval) {
      validInterval = this.intervalOptions[0]
      this.activeInterval = this.intervalOptions[0]
      if (!validInterval) return
    }
    
    if (this.timeSettings.interval != validInterval.ms) {
      this.timeSettings.interval = validInterval.ms
      this.updateTimeSettings()
    }
  }  

  updateHistory (startTs: number, endTs: number) {
    if (!startTs) startTs = endTs
    if (!endTs) endTs = startTs
    if (endTs < startTs) endTs = startTs
    if (endTs == startTs) return
    this.timeSettings.startTs = startTs
    this.timeSettings.endTs = endTs
    this.updateIntervalOptions()
    this.updateTimeSettings()
  }

  selectMode (mode: TELEMETRY_MODE) {
    this.timeSettings.mode = mode
    if (!this.timeSettings.startTs || !this.timeSettings.endTs) {
      let now = new Date().getTime()
      this.updateHistory(now-DAY, now)
    }
    this.updateIntervalOptions()
    this.updateTimeSettings()
  }


  updateIntervalOptions () {
    if (this.timeSettings.mode == 'HISTORY') {
      let maxLimit = 50
      let minLimit = 5
      let tsLength = (this.timeSettings.endTs - this.timeSettings.startTs)
      this.intervalOptions = this.predefinedIntervals.filter(interval => {
        let points = (tsLength / interval.ms)
        return points > minLimit && points < maxLimit
      })
    } else {
      let tw = this.timeWindowOptions.find(t => t.time == this.timeSettings.timeWindow)
      if (!tw) {
        tw = this.timeWindowOptions[0]
      }
      this.intervalOptions = this.predefinedIntervals.filter(t => {
        return t.ms >= tw.minInterval && t.ms <= tw.maxInterval
      })
    }
    
    this.updateInterval()
    
  }

  intervalOptions: ITimeName[] = []

  updateLatestTime () {
    this.updateIntervalOptions()
    this.updateTimeSettings()
  }

  updateTimeSettings () {
    this.telemetry.updateSettings(this.timeSettings)
  }

  _subscriptions: Subscription[] = []
  listen (s: Subscription) { this._subscriptions.push(s) }
  ngOnDestroy () { this._subscriptions.map(s => s.unsubscribe()); this._subscriptions = [] }

  updateLocale () {
    this.locale = this.translate.currentLang
  }

  onTelemetrySettingsUpdate (settings) {
    this.timeSettings = settings
    this.loadDateTimes(this.timeSettings.startTs, this.timeSettings.endTs)
    this.activeInterval = this.predefinedIntervals.find(x => x.ms == this.timeSettings.interval)
    this.updateIntervalOptions()
  }

  ngOnInit () { 
    this.listen(this.telemetry.onSettingUpdate.subscribe(settings => {
      this.onTelemetrySettingsUpdate(settings)
    }))
    this.listen(this.translate.onLangChange.subscribe(x => this.updateLocale()))
    this.updateLocale()
    this.onTelemetrySettingsUpdate(this.telemetry.settings)
  }
}


@Component({
  selector: 'profile-menu',
  template: `
<ion-item (click)="logout()" >
  <ion-button>Logout</ion-button>
</ion-item>
  `
})
export class ProfileMenu {

  constructor (private auth: AuthService) {}

  logout () {
    this.auth.logout()
  }
}