import { Injectable } from '@angular/core';
import { NgZone } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SQLite, SQLiteObject } from '@awesome-cordova-plugins/sqlite/ngx';
import { Storage } from '@ionic/storage';
import { BehaviorSubject, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';




// TODO: ..
abstract class Measurement {
  public meter_uuid: string;
  public device_id: string;
  private access_token: string;

  //device_id and access_token is the cloud service attached to the device
  public measurement_uuid: string;
  public measurement_type: string;
  public measurement_meter_id: number;
  public description: string;
  public comment: string;
  public image: string;
  public mac_address: string;

  //location number type should be 32bit float for latitude and longitude
  public longitude: number;
  public latitude: number;

  //timestamp should be unix/epoch int
  public timestamp: number;
  public time_of_transfer: number;

  constructor(
    meter_uuid,
    measurement_uuid,
    measurement_meter_id,
    time_of_transfer,
    device_id,
    access_token
  ) {
    this.mac_address = '';
    this.meter_uuid = meter_uuid;
    this.measurement_uuid = measurement_uuid;
    this.measurement_meter_id = measurement_meter_id;
    this.time_of_transfer = time_of_transfer;
    this.device_id = device_id;
    this.access_token = access_token;
    this.timestamp = 0;
  }

  public getTimeForHumans() {
    if (this.timestamp == -1 || this.timestamp == 0) {
      return 'MEASUREMENT.gettimeofmeasurement';
    } else {
      const date = new Date(this.timestamp * 1000);
      return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
    }
  }

  //TOT: Time of transfer
  public getTOTForHumans() {
    let date = new Date(this.timestamp * 1000);
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
  }

  public setComment(comment) {
    this.comment = comment;
  }

  public setTimestamp(timestamp) {
    this.timestamp = timestamp;
  }

  public setImage(image) {
    this.image = image;
  }

  public getAccessToken() {
    return this.access_token;
  }

  public exportLocation() {
    // console.log('Runing exportlocation');

    if (typeof this.latitude == 'number' && typeof this.longitude == 'number') {
      let location = {
        latitude: this.latitude,
        longitude: this.longitude
      };
    } else {
      let location = {
        latitude: 0,
        longitude: 0
      };
    }
    // let location = {"latitude": 55.4180698, "longitude": 10.3067062}
    return location;
  }

  public setLocation(longitude, latitude) {
    this.longitude = longitude;
    this.latitude = latitude;
  }
}


export class MoistureMeasurement extends Measurement {
  public moisture_content: string;
  public crop_name: string;

  //float
  private calibration_offset: number;
  //float
  private temperature: number;

  constructor(
    meter_uuid,
    measurement_uuid,
    measurement_meter_id,
    moisture_content,
    crop_name,
    calibration_offset,
    temperature,
    time_of_transfer,
    device_id,
    access_token
  ) {
    super(
      meter_uuid,
      measurement_uuid,
      measurement_meter_id,
      time_of_transfer,
      device_id,
      access_token
    );
    //If you extend measurement, be sure to add measurement_type
    this.measurement_type = 'avocado_moisture';
    this.description = 'Moisture content';
    this.moisture_content = moisture_content;
    this.crop_name = crop_name;
    this.calibration_offset = calibration_offset;
    this.temperature = temperature;
  }

  //getter and setter functions
  public getMeasurement() {
    return {
      mac_address: this.mac_address,
      meter_uuid: this.meter_uuid,
      measurement_type: this.measurement_type,
      measurement_uuid: this.measurement_uuid,
      measurement_meter_id: this.measurement_meter_id,
      moisture_content: this.moisture_content,
      crop_name: this.crop_name,
      calibration_offset: this.calibration_offset,
      temperature: this.temperature,
      timestamp: this.timestamp,
      longitude: this.longitude,
      latitude: this.latitude,
      time_of_transfer: this.time_of_transfer,
      access_token: this.getAccessToken(),
      device_id: this.device_id
    };
  }

  public exportMeasurement() {
    return {
      meter_uuid: this.meter_uuid,
      type: 'measurement',
      measurement: {
        id: this.measurement_uuid,
        type: this.measurement_type,
        crop_name: this.crop_name,
        latitude: this.latitude,
        longitude: this.longitude,
        timestamp: this.timestamp,
        time_of_transfer: this.time_of_transfer,
        calibration_offset: this.calibration_offset,
        temperature: this.temperature,
        moisture_content: this.moisture_content
      }
    };
  }

  public measurementToAgrolog() {
    if (this.timestamp == undefined) {
      return {
        ts: Date.now(),
        values: {
          meter_uuid: this.meter_uuid,
          id: this.measurement_uuid,
          type: this.measurement_type,
          crop_name: this.crop_name,
          latitude: this.latitude,
          longitude: this.longitude,
          time_of_transfer: this.time_of_transfer,
          calibration_offset: this.calibration_offset,
          temperature: this.temperature,
          moisture_content: this.moisture_content
        }
      };
    } else if (this.timestamp == -1) {
      return {
        ts: Date.now(),
        values: {
          meter_uuid: this.meter_uuid,
          id: this.measurement_uuid,
          type: this.measurement_type,
          crop_name: this.crop_name,
          latitude: this.latitude,
          longitude: this.longitude,
          time_of_transfer: this.time_of_transfer,
          calibration_offset: this.calibration_offset,
          temperature: this.temperature,
          moisture_content: this.moisture_content
        }
      };
    } else {
      return {
        ts: this.timestamp + '000',
        values: {
          meter_uuid: this.meter_uuid,
          id: this.measurement_uuid,
          type: this.measurement_type,
          crop_name: this.crop_name,
          latitude: this.latitude,
          longitude: this.longitude,
          timestamp: this.timestamp,
          time_of_transfer: this.time_of_transfer,
          calibration_offset: this.calibration_offset,
          temperature: this.temperature,
          moisture_content: this.moisture_content
        }
      };
    }
  }
}


export interface IDeviceModel {
  name: string
  mac_address: string
}

@Injectable({
  providedIn: 'root'
})

// Storage module built from:
// https://www.youtube.com/watch?v=E6h8PFHMLIU
export class StorageService {
  private db: SQLiteObject;
  // Database version, only used if we ever need to update the database layout
  private dbVer = 100;
  private storedDbVer: number;
  private databaseReady: BehaviorSubject<boolean>;
  // We need this devicesInDb, since IOS has problems with doing sql quering
  // When in background mode, so we need to keep all the devices in a list as well.
  //public allDevicesInDb: string[] = [];
  public firstEverBoot: boolean = false;

  public devices = new BehaviorSubject<IDeviceModel[]>([])
  public onMeasurementSaved = new Subject<MoistureMeasurement>()

  constructor(
    private sqlite: SQLite,
    private storage: Storage,
    private ngZone: NgZone,
    private platform: Platform,
    private router: Router,
    public alertController: AlertController
  ) {
    // Prepare database, if already exist, then .create() just uses the database
    this.databaseReady = new BehaviorSubject(false);
    this.databaseReady.subscribe(isReady => {
      if (isReady) this.reloadDevices()
    })
    this.platform.ready().then(() => {
      if (!Capacitor.isNativePlatform()) return console.warn('sqlite db only available on mobile')
      this.storage.create().then(storage => {
        this.sqlite
          .create({
            name: 'guacamole.db',
            iosDatabaseLocation: 'Documents'
          })
          .then((dbRes: SQLiteObject) => {
            this.db = dbRes;

            storage.get('dbVer').then(val => {
              this.storedDbVer = parseInt(val);
              // Make sure that
              storage.get('tables_created').then(tablesCreated => {
                console.log('Tables created val:' + tablesCreated);
                console.log(
                  'storedDbVer: ' +
                    this.storedDbVer +
                    ' - - - dbver: ' +
                    this.dbVer
                );
                // Check if the version of the database is up to date and compare it to the version stored.
                // If there is a version change in the database, you need to take measurements not to break
                // things.
                if (tablesCreated && this.storedDbVer == this.dbVer) {
                  console.log('Tables already created, and app is up to date');
                  this.databaseReady.next(true);
                  this.devicesInDB()
                  //this.router.navigate(['/home']);
                } else if (tablesCreated && this.storedDbVer < this.dbVer) {
                  console.log('Tables created, but app is not up to date');
                  // Update table, function to be written later
                  this.databaseReady.next(true);
                } else {
                  this.createTables(storage);
                  storage.set('dbVer', this.dbVer);
                  this.firstEverBoot = true;
                  this.databaseReady.next(true);
                }
              });
            });
          });
      })
    });
  }

  // This is a small hack to wait for database creation and then reload the app first time it is used.
  // This is needed since ble will not run properly without the database service.
  // leading to crashings if we do not wait.
  async waitForDatabaseCreation(waittime) {
    await new Promise(resolve => setTimeout(resolve, waittime));
  }

  // ################ Measurements ############# //
  public addMeasurement(measurement: MoistureMeasurement) {
    const measurementData = measurement.getMeasurement();
    //console.log('Adding measurement to DB ' + JSON.stringify(measurementData));
    const data = [
      measurementData.meter_uuid,
      measurementData.measurement_type,
      measurementData.measurement_uuid,
      measurementData.measurement_meter_id,
      measurementData.moisture_content,
      measurementData.crop_name,
      measurementData.calibration_offset,
      measurementData.temperature,
      this.dbVer,
      false,
      measurementData.timestamp,
      measurementData.time_of_transfer,
      measurementData.access_token,
      measurementData.device_id,
      measurementData.longitude,
      measurementData.latitude
    ];

    // ["240AC48A61B0","avocado_moisture","b82c5139-c068-455a-b9af-d7f5d0f12ad8",0,15.3,
    // "Barley",0,30.3,100,false,0,1552911868,"0Lgltp15WNJc2q39MjUM","f3eea200-4973-10Lgltp15WNJc2q39MjUM"]

    this.measurementAlreadyInDB(
      measurementData.meter_uuid,
      measurementData.measurement_meter_id
    ).then(result => {
      if (result == false) {
        this.db
          .executeSql(
            'INSERT INTO measurements(meter_uuid, measurement_type, measurement_uuid,' +
              'measurement_meter_id, moisture_content, crop_name, calibration_offset, temperature,' +
              'dbVersion, uploadedToAgrolog, timestamp, time_of_transfer, access_token,' +
              'device_id, longitude, latitude) VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)',
            data
          )
          .then(() => {
            console.log('added measurement:', data)
            this.onMeasurementSaved.next(measurement)
          })
          .catch(e =>
            console.error('error inserting into table measurements ', e)
          );
      }
    });
  }

  // This function simply check if a measurement exists, based on the measurement_uuid
  // Which was assigned by Guacamole, not by Avocado.
  protected measurementExists(measurement_uuid): Promise<boolean> {
    return this.getMeasurement(measurement_uuid).then(measurements => {
      if (measurements === undefined || measurements.length == 0) {
        //  console.log("Measurement doesnt exist");
        return false;
      } else {
        //  console.log("Measurement exist");
        return true;
      }
    });
  }

  // This function simply check if a measurement has already been uploaded to agrolog
  public measurementUploadedToAgrolog(measurement_uuid): Promise<boolean> {
    return this.getMeasurement(measurement_uuid).then(measurements => {
      //  console.log("measurementUploadedToAgrolog: "+JSON.stringify(measurements));
      if (measurements !== undefined) {
        if (measurements.uploadedToAgrolog == 'false') {
          //  console.log("Measurement has not been uploaded to agrolog yet");
          return false;
        } else {
          //  console.log("Measurement has already been uploaded to agrolog prior");
          return true;
        }
      } else {
        //  console.log("measurements false");
        return false;
      }
    });
  }

  public listDeviceMeasurementIds (meter_uuid: string) {
    meter_uuid = meter_uuid.replace(/\:/g, '')
    return this.db.executeSql('SELECT measurements.measurement_meter_id FROM measurements WHERE measurements.meter_uuid = ?', [meter_uuid]).then(data => {
      const measurements: number[] = [];
      // Rewrite this to not use looping since unnecessary
      if (data.rows.length > 0) {
        for (let i = 0; i < data.rows.length; i++) {
          measurements.push(data.rows.item(i)['measurement_meter_id']);
        }
      }
      return measurements;
    })
  }

  // Not to be confused with measurementExists()
  // This function is used to check if a measurement has already been sent from Avocado to Guacamole.
  public measurementAlreadyInDB(meter_uuid, measurement_meter_id) {
    return this.db
      .executeSql(
        'SELECT * FROM measurements WHERE measurements.meter_uuid = ?' +
          ' AND measurements.measurement_meter_id = ? LIMIT 1',
        [meter_uuid, measurement_meter_id]
      )
      .then(
        data => {
          if (data === undefined || data.rows.length == 0) {
            return false;
          } else {
            return true;
          }
        },
        err => {
          console.error(
            'Error looking up measurement in DB: ',
            JSON.stringify(err)
          );
          return false;
        }
      );
  }

  public getMeasurement(measurement_uuid) {
    return this.db
      .executeSql(
        'SELECT * FROM measurements WHERE measurements.measurement_uuid = ? LIMIT 1',
        [measurement_uuid]
      )
      .then(
        data => {
          const measurements = [];
          // Rewrite this to not use looping since unnecessary
          if (data.rows.length > 0) {
            for (let i = 0; i < data.rows.length; i++) {
              measurements.push(data.rows.item(i));
            }
          }
          return measurements[0];
        },
        err => {
          console.error('error retrieving measurements: ', JSON.stringify(err));
          return [];
        }
      );
  }

  public getMeasurements() {
    return this.db
      .executeSql(
        'select * from measurements ORDER BY timestamp DESC, time_of_transfer DESC;',
        []
      )
      .then(
        data => {
          const measurements = [];
          if (data.rows.length > 0) {
            for (let i = 0; i < data.rows.length; i++) {
              measurements.push(data.rows.item(i));
            }
          }
          return measurements;
        },
        err => {
          console.error('error retrieving measurements: ', JSON.stringify(err));
          return [];
        }
      );
  }

  public updateComment(measurement_uuid, comment) {
    if (this.measurementExists(measurement_uuid)) {
      this.db
        .executeSql(
          'UPDATE measurements SET comment = ? WHERE measurement_uuid = ?',
          [comment, measurement_uuid]
        )
        .then(res => {
          //  console.log('Success updating table measurements, with comment');
        })
        .catch(err =>
          console.error(
            'error updating table measurements: ' + JSON.stringify(err)
          )
        );
    }
  }

  public updateImage(measurement_uuid, ImageLocation) {
    if (this.measurementExists(measurement_uuid)) {
      this.db
        .executeSql(
          'UPDATE measurements SET picture = ? WHERE measurement_uuid = ?',
          [ImageLocation, measurement_uuid]
        )
        .then(res => {
          console.log('Success updating table measurements, with updateImage');
        })
        .catch(err =>
          console.error(
            'error updating table measurements ' + JSON.stringify(err)
          )
        );
    }
  }

  public setAsUploadedToAgrolog(measurement_uuid) {
    if (this.measurementExists(measurement_uuid)) {
      this.db
        .executeSql(
          'UPDATE measurements SET uploadedToAgrolog = ? WHERE measurement_uuid = ?',
          [true, measurement_uuid]
        )
        .then(res => {
          console.log(
            'Success updating table measurements, with setAsUploadedToAgrolog'
          );
        })
        .catch(err =>
          console.error(
            'error updating table measurements ' + JSON.stringify(err)
          )
        );
    }
  }

  public measurementStringSearch(searchType: string, searchString: string) {
    const search = new SearcherQL(searchType);
    search.setStringSearch(searchString);
    return this.db.executeSql(search.getSQL(), []).then(
      data => {
        const measurements = [];
        if (data.rows.length > 0) {
          for (let i = 0; i < data.rows.length; i++) {
            measurements.push(data.rows.item(i));
          }
        }
        return measurements;
      },
      err => {
        console.error('error retrieving measurements: ', JSON.stringify(err));
        return [];
      }
    );
  }

  public measurementNumberSearch(
    searchType: string,
    lower: number,
    upper: number
  ) {
    const search = new SearcherQL(searchType);
    search.setRangeSearch(lower, upper);
    return this.db.executeSql(search.getSQL(), []).then(
      data => {
        const measurements = [];
        if (data.rows.length > 0) {
          for (let i = 0; i < data.rows.length; i++) {
            measurements.push(data.rows.item(i));
          }
        }
        return measurements;
      },
      err => {
        console.error(
          'error retrieving measurements in measurementNumberSearch: ',
          JSON.stringify(err)
        );
        return [];
      }
    );
  }

  public noMeasurements() {
    return this.db.executeSql('SELECT * FROM measurements', []).then(
      data => {
        const rows = [];
        // Rewrite this to not use looping since unnecessary
        if (data.rows.length > 0) {
          for (let i = 0; i < data.rows.length; i++) {
            rows.push(data.rows.item(i));
          }
        }
        if (rows.length === 0) {
          return true;
        } else {
          return false;
        }
      },
      err => {
        console.error(
          'Non-Fatal: error retrieving measurements in noMeasurements(): ',
          JSON.stringify(err)
        );
        return true;
      }
    );
  }

  getDatabaseState() {
    return this.databaseReady.asObservable();
  }

  purgeDB() {
    this.db.executeSql('DELETE FROM measurements;', []).then(
      () => {
        console.log('Erased the measurements table');
      },
      err => {
        console.error(
          'Failed Erasing the measurements table, ' + JSON.stringify(err)
        );
      }
    );
  }

  deleteAllDevices () {
    this.db.executeSql('DELETE FROM devices;', []).then(
      () => {
        console.log('Erased the devices table');
      },
      err => {
        console.error(
          'Failed Erasing the devices table, ' + JSON.stringify(err)
        );
      }
    );
  }

  deleteDeviceMeasurements (meter_uuid: string) {
    meter_uuid = meter_uuid.replace(/\:/g, '')
    console.log('delete measurements for device:', meter_uuid)
    this.db.executeSql('DELETE FROM measurements where measurements.meter_uuid=?;', [meter_uuid]).then(
      () => {
        this.db.executeSql('select * from measurements;', []).then(
          (data) => {
            let rows = []
            if (data.rows.length > 0) {
              for (let i = 0; i < data.rows.length; i++) {
                rows.push(data.rows.item(i));
              }
            }
          }
        )
      },
      err => {
        console.error(
          'Failed Erasing the devices table, ' + JSON.stringify(err)
        );
      }
    );
    
  }

  // ################ Devices ############# //
  private reloadDevices () {
    this.devicesInDB().then(devices => {
      this.devices.next(devices)
    })
  }
  public addDevice(mac_address, name) {
    this.deviceAlreadyInDB(mac_address).then(result => {
      if (!result) {
        this.db
          .executeSql('INSERT INTO devices(mac_address, name) VALUES (?, ?)', 
          [
            mac_address,
            name
          ])
          .then( res => {
            console.log('device added to storage ' + mac_address);
            //this.allDevicesInDb.push(mac_address);
            this.reloadDevices()
          })
          .catch(err =>
            console.error(
              'error inserting into table devices ' + JSON.stringify(err)
            )
          );
      }
    });
  }

  public removeDevice(mac_address) {
    this.deviceAlreadyInDB(mac_address).then(result => {
      if (result) {
        this.db
          .executeSql('DELETE FROM devices WHERE devices.mac_address = ?', [
            mac_address
          ])
          .then(() => {
            this.reloadDevices()
            console.log('Success deleting device from DB: ' + mac_address);
          })
          .catch(err =>
            console.error('error deleting device ' + JSON.stringify(err))
          );
      } else {
        this.reloadDevices()
      }
    });
  }

  public deviceAlreadyInDB(mac_address) {
    return this.db
      .executeSql(
        'SELECT * FROM devices WHERE devices.mac_address = ? LIMIT 1',
        [mac_address]
      )
      .then(
        data => {
          //console.log('DEVICES::alreadyindb' + JSON.stringify(data.rows.item(0)))
          if (data === undefined || data.rows.length === 0) {
            return false;
          } else {
            return true;
          }
        },
        err => {
          console.error('error finding device in DB: ', JSON.stringify(err));
          return false;
        }
      );
  }



  public getDeviceUUIDFromMac(mac_address) {
    return this.db
      .executeSql(
        'SELECT * FROM devices WHERE devices.mac_address = ? LIMIT 1',
        [mac_address]
      )
      .then(
        data => {
          const devices = [];
          if (data.rows.length > 0) {
            for (let i = 0; i < data.rows.length; i++) {
              devices.push(data.rows.item(i).name);
            }
          }
          return devices;
        },
        err => {
          console.log(
            'error retrieving device UUID/Name: ',
            JSON.stringify(err)
          );
          console.log(JSON.stringify(err.rows));
          return [];
        }
      );
  }

  public devicesInDB() {
    return this.db.executeSql('SELECT * FROM devices', []).then(
      data => {
        const devices: IDeviceModel[] = [];
        //this.allDevicesInDb = [];
        //console.log('DEVICES::', JSON.stringify(data))
        if (data.rows.length > 0) {
          for (let i = 0; i < data.rows.length; i++) {
            let item = data.rows.item(i)
            //console.log('DEVICES:: ' + i + data.rows.length + JSON.stringify(item))
            if (!item.mac_address) { 
              
              continue
            }
            devices.push(item);
            //this.allDevicesInDb.push(data.rows.item(i).mac_address);
          }
        }
        //console.log('All devices in db: ' + JSON.stringify(this.allDevicesInDb));
        return devices;
      },
      err => {
        console.log('error retrieving devices: ', JSON.stringify(err));
        console.log(JSON.stringify(err.rows));
        return [] as IDeviceModel[];
      }
    );
  }

  // Check if there are any devices in the db.
  // if NoDevices === true then there are no devices in db.
  public noDevices() {
    return this.db.executeSql('SELECT * FROM devices', []).then(
      data => {
        const rows = [];
        // Rewrite this to not use looping since unnecessary
        if (data.rows.length > 0) {
          for (let i = 0; i < data.rows.length; i++) {
            rows.push(data.rows.item(i));
          }
        }
        if (rows.length === 0) {
          return true;
        } else {
          return false;
        }
      },
      err => {
        return true;
      }
    );
  }

  /*
    A note regarding meter_uuid Vs.measurement_meter_id:
    meter_uuid is the UUID assigned by Supertech Agroline to each meter
    measurement_meter_id is the incremental ID given by Avocado to a measurement.
    The meter_uuid+measurement_meter_id in theory supplies a UID in which we
    can check if the measurement already exists in the db
   */
  private createTables(storage) {
    this.db
      .executeSql(
        'CREATE table IF NOT EXISTS measurements(id INTEGER PRIMARY KEY AUTOINCREMENT,' +
          ' meter_uuid TEXT, measurement_type TEXT, measurement_uuid TEXT, measurement_meter_id INTEGER,' +
          ' moisture_content REAL, crop_name VARCHAR(32), latitude REAL, longitude REAL, timestamp INTEGER,' +
          ' time_of_transfer INTEGER, calibration_offset REAL, temperature REAL, comment TEXT, dbVersion INT,' +
          ' device_id TEXT, access_token TEXT, uploadedToAgrolog BOOLEAN, picture TEXT)',
        []
      )
      .then(() => {
        storage.set('tables_created', true);
      })
      .catch(e => {
        console.log('error creating table measurements');
        this.databaseReady.next(false);
        storage.set('tables_created', false);
      });

    this.db
      .executeSql(
        'CREATE table IF NOT EXISTS devices(id INTEGER PRIMARY KEY AUTOINCREMENT,' +
          ' mac_address TEXT, name TEXT)',
        []
      )
      .then(() => {
        this.databaseReady.next(true);
        storage.set('tables_created', true);
      })
      .catch(e => {
        console.log('error creating table devices');
        this.databaseReady.next(false);
        storage.set('tables_created', false);
      });
  }

  async presentAlertWithRedirect(
    header: string,
    subtitle: string,
    text: string,
    route: string
  ) {
    const alert = await this.alertController.create({
      header: header,
      subHeader: subtitle,
      message: text,
      buttons: ['OK']
    });
    alert.onDidDismiss().then(() => {
      this.router.navigate([route]);
    });
    await alert.present();

    return alert;
  }
}

// This class is used to create sql queries to search through the database
class SearcherQL {
  private searchType: string;
  // Can only be any of these:
  // Strings: meter_uuid, measurement_type, measurement_uuid, crop_name, comment
  // Numbers: moisture_content, timestamp, calibration_offset, temperature
  // Location: latitude, longitude
  private searchString: string;
  private searchUpper: number;
  private searchLower: number;

  constructor(searchType) {
    this.searchType = searchType;
  }

  setStringSearch(searchString: string) {
    this.searchString = searchString;
  }

  setRangeSearch(lower: number, upper: number) {
    this.searchLower = lower;
    this.searchUpper = upper;
  }

  getSQL() {
    let SQLString: string;
    if (this.searchString != null) {
      SQLString =
        'SELECT * FROM measurements WHERE ' +
        this.searchType +
        ' like "' +
        this.searchString +
        '%" ORDER BY timestamp DESC, time_of_transfer DESC';
    } else if (this.searchLower != null) {
      SQLString =
        'SELECT * FROM measurements WHERE ' +
        this.searchType +
        ' >= ' +
        this.searchLower +
        ' AND ' +
        this.searchType +
        ' <= ' +
        this.searchUpper +
        ' ORDER BY timestamp DESC, time_of_transfer DESC';
    } else {
      SQLString =
        'SELECT * FROM measurements ORDER BY timestamp DESC, time_of_transfer DESC';
    }
    return SQLString;
  }
}
