import { EventEmitter } from "@angular/core"
import { Subscription, Subject } from "rxjs"
import { debounceTime } from "rxjs/operators"
import { Asset, Device, EntityId, EntityType } from "src/app/models/entity.model"
import { ITelemetryValue } from "src/app/models/telemetry.model"
import { EntityModel, FarmerService } from "src/app/services/device.service"
import { HUMIDITY_SENSOR, IBuildingSettings, ISensorType, MOISTURE_SENSOR, TEMPERATURE_SENSOR } from "src/app/services/types.service"
import { clone, Index } from "src/app/util"

interface IRelation<T extends EntityType> {
  to: EntityId<T>
}
export interface IBuildingState {
  settings: IBuildingSettings, devices: Device[]
}

export class FarmerBuilding {
  //updated = new EventEmitter<this>()
  updated = new Subject()
  //onLoaded = new EventEmitter<this>()
  devices: Device[] = []
  state = 'INITIAL'
  model: EntityModel<EntityType.ASSET, IBuildingSettings>
  hasDevices = null
  loaded = false

  get settings () { return this.model.settings }
  get width () { return this.settings.width || 200 }
  get length () { return this.settings.length || 300 }

  get label () { return this.asset.label }
  get entity () { return this.asset }

  constructor (private farmer: FarmerService, public asset: Asset) {
    this.model = this.farmer.createModel(this.farmer.types.building, this.asset)
    this.model.onUpdate.subscribe(e => {
      this.onModelUpdate(e)
    })
    this.farmer.subscribe(() => {
      this.load()
    })
    this.model.load()
  }

  latestTemperatureTs: number | null = null
  latestMoistureTs: number | null = null

  /*$devices = this.farmer.onRefresh.pipe(
    switchMap(x => {
      return this.farmer.getBuildingDevices(this.asset).filter(
        d => d.type == agTypes.farmerDeviceTypes.cellularSensorSpear.value
      ).map(x => this.farmer.getCellularSpear(x).model.$settings)
    })
  )*/
  
  // TODO: this could fail if building has other relations than cellular_spear
  getCellularSpears () {
    return this.devices.map(x => this.farmer.getCellularSpear(x))
  }
  //hasDevices () { return this.devices.length > 0 }
  hasTemperature () {
    return this.getCellularSpears().find(x => x.hasTemperature()) != undefined
  }
  hasMoisture () {
    return this.getCellularSpears().find(x => x.hasMoisture()) != undefined
  }

  getSensorTypes () {
    let types: ISensorType[] = []
    if (this.hasTemperature()) types.push(TEMPERATURE_SENSOR)
    if (this.hasMoisture()) {
      if (this.settings.crop_type) {
        types.push(MOISTURE_SENSOR)
      } else {
        types.push(HUMIDITY_SENSOR)
      }
    }
    return types
  }
  
  getValues () { 
    return this.model.getValues([
      'current_min_temperature', 'current_avg_temperature', 'current_max_temperature',
      'current_min_emc', 'current_avg_emc', 'current_max_emc'
    ]) 
  }

  set_entity (entity: Asset) {
    this.asset = entity
  }

  subscribe (cb: ()=>void) {
    if (this.loaded) try{ cb() } catch (err) { console.error(err) }
    return this.updated.pipe(debounceTime(500)).subscribe(cb)
  }

  temperatureKeys () {
    let keys = ['current_min_temperature', 'current_avg_temperature', 'current_max_temperature']
    return keys.map(k => this.model.attributes.get(k))
  }
  moistureKeys () {
    let keys = ['current_min_emc', 'current_avg_emc', 'current_max_emc']
    return keys.map(k => this.model.attributes.get(k))
  }

  isValueValid (value?: ITelemetryValue<number>) {
    if (value == undefined || value.status == 'error') return false;
    let now = (new Date().getTime() + new Date().getTimezoneOffset()*60*1000);
    let maxAge = now - (24 * 60 * 60 * 1000) // one day
    return value != undefined && value.value != undefined && typeof value.value == 'number' 
      && value.value > -99 && value.value < 99 && value.ts > maxAge
  }

  createState (): IBuildingState {
    return {settings: clone(this.settings), devices: this.devices.slice(0)}
  }

  get telemetry () { return this.farmer.telemetry }
  get http () { return this.farmer.http }
  get attributeService () { return this.farmer.attributeService }
  get entityService () { return this.farmer.entityService }

  emit () {
    this.updated.next(this)
    //this.updated.emit(this)
  }

  onModelUpdate (settings: Partial<IBuildingSettings>) {
    this.latestTemperatureTs = this.model.getLatestTs([
      'current_min_temperature', 'current_avg_temperature', 'current_max_temperature'
    ])
    this.latestMoistureTs = this.model.getLatestTs([
      'current_min_emc', 'current_avg_emc', 'current_max_emc'
    ])
    this.emit()
  }

  async saveSettings (settings: Partial<IBuildingSettings>) {
    await this.model.saveSettings(settings)
    this.devices.map(device => {
      this.farmer.getCellularSpear(device).onBuildingUpdated()
    })
    this.emit()
  }

  isLoaded () { return this.state == 'LOADED' }

  _device_subscriptions: Index<Subscription> = {}
  load () {
    this.devices = []
    let devices = this.farmer.getBuildingDevices(this.asset)
    this.hasDevices = devices.length > 0
    for (var device of devices) {
      this.addDevice(device)
      // TODO: unsubscribe!
      if (this._device_subscriptions[device.id.id]) continue
      this._device_subscriptions[device.id.id] = this.farmer.getCellularSpear(device).subscribe(() => {
        this.onDeviceLoaded()
      })
    }
    this.onDeviceLoaded()
  }

  onDeviceLoaded () {
    let allLoaded = this.getCellularSpears().find(x => !x.isLoaded()) == undefined
    if (allLoaded) {
      this.loaded = true
      //this.onLoaded.emit(this)
      this.emit()
    }
  }

  async getDeviceRelations () {
    let url = `/api/relations/info?fromId=${this.asset.id.id}&fromType=ASSET`
    return await this.http.get<IRelation<EntityType.DEVICE>[]>(url).toPromise()
  }

  async createRelationTo (device: Device) {
    let url = '/api/relation'
    await this.http.post(url, {
      additionalInfo: "",
      from: this.asset.id,
      to: device.id,
      type: 'Contains',
      typeGroup: "COMMON"
    }).toPromise()
  }
  async removeRelationTo (entityId: EntityId<EntityType.DEVICE>) {
    let url = '/api/relation?relationTypeGroup=COMMON'
    url += '&fromId=' + this.asset.id.id
    url += '&fromType=' + this.asset.id.entityType 
    url += '&relationType=Contains'
    url += '&toId=' + entityId.id 
    url += '&toType=' + entityId.entityType
    await this.http.delete(url).toPromise()
  }

  async assignDevice (device: Device) {
    let currentDevice = this.devices.find(x => x.id.id == device.id.id)
    if (!currentDevice) {
      await this.createRelationTo(device)
      this.devices.push(device)
    }
    await this.farmer.reload()
    this.emit()
  }
  async unassignDevice (device: Device) {
    let hasDevice = this.devices.find(x => x.id.id == device.id.id)
    if (hasDevice) {
      await this.removeRelationTo(device.id)
      await this.farmer.reload()
      this.emit()
    }
  }

  async saveMarkers (markers: IBuildingMarker[]) {
    // TODO: create relation operations should run in farmer-service
    let newDevices: Device[] = []
    for (let marker of markers) {
      let device = this.devices.find(x => x.id.id == marker.device.id.id)
      if (!device) {
        await this.createRelationTo(marker.device)
        device = marker.device
      }
      newDevices.push(device)
      let ctrl = this.farmer.getCellularSpear(device)
      await ctrl.saveSettings({pos_x: marker.realPos.x, pos_y: marker.realPos.y})
    }
    for (var device of this.devices) {
      let hasDevice = newDevices.find(x => x.id.id == device.id.id) != undefined
      if (!hasDevice) {
        await this.removeRelationTo(device.id)
      }
    }
    this.devices = newDevices
    await this.farmer.reload()
    this.emit()
  }
  updateDevicePosition (device: Device, x: number, y: number) {
    this.farmer.getCellularSpear(device).saveSettings({
      pos_x: x, pos_y: y
    })
  }

  _subscriptions: Index<Subscription> = {}
  addDevice (device: Device) {
    this.devices.push(device)
    let ctrl = this.farmer.getCellularSpear(device)
    ctrl.load()
    if (this._subscriptions[device.id.id]) {
      this._subscriptions[device.id.id].unsubscribe()
    }
    this._subscriptions[device.id.id] = ctrl.onUpdate.subscribe(e => {
      this.emit()
    })
    this._subscriptions[device.id.id + '-data'] = ctrl.onDataUpdate.subscribe(e => {
      this.emit()
    })
    this.emit()
  }
  removeDevice (device: Device) {
    if (this._subscriptions[device.id.id]) {
      this._subscriptions[device.id.id].unsubscribe()
    }
    if (this._subscriptions[device.id.id] + '-data') {
      this._subscriptions[device.id.id + '-data'].unsubscribe()
    }
    this.devices = this.devices.filter(d => d.id.id != device.id.id)
    this.emit()
  }
}


export function getTouchEvent (evt: MouseEvent | TouchEvent) {
  if (evt instanceof MouseEvent) return evt
  else return evt.touches[0]
}

export function getEventCoords (evt: MouseEvent | TouchEvent) {
  if (evt instanceof MouseEvent) {
    return {x: evt.screenX, y: evt.screenY}
  } else {
    let touch = evt.touches[0]
    return {x: touch.screenX, y: touch.screenY}
  } 
}


export interface IBuildingMarker {
  id: string, device: Device
  realPos: {x: number, y: number}
  viewPos: {x: number, y: number}
  value: ITelemetryValue<number>
  unit: string, loaded?: boolean
  bound: {width: number, height: number}
  valid: boolean, sensor: string
}