import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Subscription } from "rxjs";
import { agTypes } from "src/app/ag-types";
import { Asset, Device, Entity, IAlarm } from "src/app/models/entity.model";
import { AlarmService } from "src/app/services/alarm.service";
import { FarmerService } from "src/app/services/device.service";
import { deviceHasCrop, deviceIcon, deviceImage, LoadingState } from "src/app/util";
//import { FarmerMeasurementDevice } from "./super-pro.model";
import { FarmerMeasurementDevice, SAMoistureMeasurement, MeasurementService } from "src/app/services/measurement.service";




@Component({
  selector: 'farmer-measurement-item',
  template: `

<div *ngIf="isLoaded" class="card-container" >  
  <div (click)="onClick.emit()" class="flex flex-column card-content flex-auto" > 
    <div class="flex-row flex-space-between-center device-section flex-auto" style="flex: 0 !important">
      <div class="flex flex-column card-header-label">
        <div class="device-label">{{device.label}}</div>
      </div>
      <!--<ion-icon *ngIf="getDeviceIcon()" class="ag-device-icon" [src]="getDeviceIcon()"></ion-icon>-->
      <img *ngIf="getDeviceIcon()" class="ag-device-icon" [src]="getDeviceIcon()" />
    </div>

    <div class="flex flex-row flex-auto"> 
      <div class="flex flex-column flex-center-start" style="padding: 10px;"> 
        <div *ngIf="measurement" class="flex-row moisture-row">
          <ion-icon class="icon" [ngStyle]="{fill: colors.minColor}" src="/assets/svg/iconset/ag-moisture.svg" ></ion-icon>  
          <span class="moisture-value">{{measurement.moisture_content | number:'1.1-1'}}%</span>
        </div>
        <div *ngIf="measurement && measurement.temperature != null" class="flex-row moisture-row">
          <ion-icon class="icon" [ngStyle]="{fill: colors.maxColor}" src="/assets/svg/iconset/ag-thermometer.svg" ></ion-icon>  
          <span class="moisture-value">{{measurement.temperature | number:'1.1-1'}}°</span>
        </div>
        <div *ngIf="measurement && deviceHasCrop()" class="moisture-crop">{{measurement.crop_name | cropLabel | async}}</div>
      </div>
      <div class="flex flex-row flex-end-start device-pic-container" style="margin-right: 20px;">
        <img [src]="getDevicePic()" class="device-pic"> <!---->
      </div>
    </div>
  
  </div>
  
  <div class="flex-row device-section">
    <div *ngIf="measurement && measurement.timestamp" class="time-value">
      <ag-time-since [ts]="measurement.timestamp"></ag-time-since>
    </div>
  </div>
</div>

  `,
  styleUrls: ['../farmer-devices/farmer-device-item.scss']
})
export class FarmerMeasurementItem {
  @Input() device: Entity<any>
  @Output() onClick = new EventEmitter<Asset>();
  loadingState = new LoadingState()
  
  ctrl: FarmerMeasurementDevice
  colors = agTypes.colors
  alarm: IAlarm | null = null
  measurement: SAMoistureMeasurement
  sub: Subscription
  isLoaded = false
  
  constructor (
    //private farmer: FarmerService, 
    private measurements: MeasurementService,
    private alarmService: AlarmService
  ) {}

  _subscriptions: Subscription[] = []
  listen (s: Subscription) { this._subscriptions.push(s) }
  ngOnDestroy () { this._subscriptions.map(s => s.unsubscribe()); this._subscriptions = [] }

  ngOnInit () {
    // ctrl.latestMeasurements()
    this.measurements.getMeasurementDevice(this.device.id).subscribe(ctrl => {
      this.listen(
        ctrl.latest.subscribe((measurement) => {
          this.measurement = measurement
        })
      )
      this.loadingState.success()
    })
    //this.listen(this.ctrl.subscribe(() => this.initDevice()))
    this.listen(this.loadingState.events.subscribe(x => {
      this.isLoaded = this.loadingState.is_success()
    }))
    
    

    /*let sensor = this.ctrl.messurement()
    this.sub = sensor.onDataUpdate.subscribe(sensor => {
      this.updateMessurement()
    })
    this.updateMessurement()*/
    // TODO: should we have a new subscription list for initDevice?
    this.listen(this.alarmService.subscribe(alarms => {
      this.alarm = alarms.find(a => a.originator.id == this.device.id.id) || null
    }))
    
  }
  
  getDeviceIcon() { return deviceIcon(this.device.type) }
  getDevicePic() { return deviceImage(this.device.type) }
  deviceHasCrop () { return deviceHasCrop(this.device.type) }
}