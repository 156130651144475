import { SAMoistureMeasurement, MeasurementService } from "src/app/services/measurement.service"
import { AddMeasurementDialogComponent, SuperProSettingsComponent } from "./super-pro"
import { agTypes } from "src/app/ag-types"
import { firstValueFrom, Subscription } from "rxjs"
import { AgModal, clone, deviceImage, LoadingState } from "src/app/util"
import { TimeseriesSubsciption } from "src/app/services/telemetry"
import { ModalController, PopoverController } from "@ionic/angular"
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router"
import { TEXT } from "src/app/texts"
import { Device } from "src/app/models/entity.model"
import { Component, Input, NgZone } from "@angular/core"
import { BluetoothService, BluetoothTaskErrors, DeviceStatus, IBleTask, IBluetoothState, IDevice, IDeviceStatus, isDeviceMeasuring, isDeviceSyncing } from "src/app/services/bluetooth.service"
import { IPermissionState, PermissionService } from "src/app/services/permissions.service"
import { TimeQuerySettings } from "src/app/menus"
import { Device as CapDevice } from "@capacitor/device"


interface IBleDisplayState {
  deviceStatus: DeviceStatus | null
  bleReady: boolean
  statusColor: string
  notConnected: boolean
  canForceConnect: boolean
  timestamp: Date | null
  issue: {
    type?: 'PAIR' | null
    severity: 'error' | 'warn'
    title: string
    helpText: string
    buttonText?: string
    resolve?: () => void
  }
}

type DeviceDisplayStatus = 'idle' | 'pairing' | 'measuring' | 'syncing' | 'success' | 'error'

interface IDisplayMeasurement {
  id?: number
  latitude?: number
  longitude?: number
}

interface IDeviceDisplay {
  bleDevice: IDevice
  status: DeviceDisplayStatus
  image: string
  devicePageUrl: string | null
  //completed?: boolean
  entity?: Device | null
  measurements?: IDisplayMeasurement[]
  numUnsyncedMeasurements?: number
  error?: BluetoothTaskErrors | string
  progress?: number | null
  lastResponse: number
}


interface IBleDialogDisplay {
  status: 'scanning' | 'operating' | 'idle' | 'uploading'
  devices: IDeviceDisplay[]
}


@Component({
  selector: 'ag-bluetooth-dialog',
  template: `
<div *ngIf="state.status != 'idle'" class='container' (click)="dismiss()">
  <div class='content'>
    <div>
      <div *ngIf="state.status == 'scanning'">
        <div class="title" [translate]="text.ble.scanning"></div>
        <div class="help-text" [translate]="text.ble.sync_help" *ngIf="state.devices.length == 0"></div>
      </div>
      <div *ngIf="state.status == 'uploading'">
        <div class="title" [translate]="text.ble.saving_measurements"></div>
      </div>
      <div *ngFor="let device of state.devices">
        <div *ngIf="device.entity == null">
          <div class="title">{{device.bleDevice.name}}</div>
          <div class="help-text" [translate]="text.ble.unknown_device"></div>
          <ion-button class="ble-button" size="small" (click)="claimDevice(device.bleDevice.name)" [translate]="text.ble.claim_device"></ion-button>
        </div>

        <div *ngIf="device.entity != null" style="margin: 5px;">
          <div class="device-title">{{device.entity.label || device.entity.name}}</div>
          <!--<div *ngIf="device.entity.label" class="device-subtitle">{{device.entity.name}}</div>-->
        </div>

        <div style="display: flex" *ngIf="device.entity != null">          
          <div class="image-container" style="width: 90px; min-width: 90px">
            <img [src]="device.image" />
          </div>
          <div class="device-content">
            <div class="device-status" *ngIf="device.status == 'pairing'" [translate]="text.ble.pairing"></div>
            <div class="device-status" *ngIf="device.status == 'measuring'" [translate]="text.ble.measuring"></div>
            <div class="device-status" *ngIf="device.status == 'syncing'">
              <div [translate]="text.ble.syncing"></div>
              <ion-progress-bar *ngIf="device.progress != null" [value]="device.progress"></ion-progress-bar>
              <!--<ion-button class="ble-button" size="small" (click)="cancelOperation()" [translate]="text.ble.cancel_operation"></ion-button>-->
            </div>
            <div *ngIf="device.status == 'success'">
              <div *ngIf="!device.numUnsyncedMeasurements">
                <div class="device-status" [translate]="text.ble.complete"></div>
                <ion-button class="ble-button" background="transparent" size="small" (click)="dismiss()" [translate]="text.ble.close"></ion-button>
              </div>
              
              <div *ngIf="device.numUnsyncedMeasurements">
                <div class="device-status" [translate]="text.ble.measurements_available"></div>
                <div class="help-text" [translate]="text.ble.sync_help"></div>
              </div>
              
              <div *ngIf="device.devicePageUrl && device.devicePageUrl != currentPage">
                <ion-button class="ble-button" size="small" (click)="openUrl(device.devicePageUrl)" [translate]="text.ble.open_device_page"></ion-button>
              </div>
            </div>
            <div *ngIf="device.status == 'error'">
              <div class="device-status" [translate]="text.ble.error"></div>
              <div *ngIf="device.error == 'no_gps'" [translate]="text.ble.no_gps_location" class="help-text"></div>
              <div *ngIf="device.error == 'device_disconnected'" class="help-text">
                Device Disconnected!
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
`,
  styles: `
.container {
  font-family: Ubuntu;
  position: fixed;
  left: 0; top: 0;
  width: 100%; height: 100%;
  pointer-events: fill;
  background: rgba(0.1,0.1,0.1,0.3);
  z-index: 9999;
}
.ble-button {
  font-size: 12px;
}
.image-container {
  margin: 10px 20px;
}
.content {
  border-radius: 10px;
  width: 90%;
  position: absolute;
  left: 50%;
  top: 50%;
  background: white;
  box-shadow: 0 0 10px #6c6c6c;
  border: 1px solid lightgray;
  padding: 10px;
  transform: translate(-50%, -50%);
} 
.help-text {
  white-space: pre-line;
  line-height: 24px;
  font-size: 14px;
  font-family: 'Ubuntu';
  letter-spacing: 0.44px;
  margin-left: 10px;
}
.title {
  margin: 10px 10px;
  font-weight: bold;
  letter-spacing: 1.5px;
  text-align: center;
}
.device-title {
  text-align: center;
  font-weight: bold;
  letter-spacing: 1.5px;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
}
.device-subtitle {
  text-align: center;
  font-size: 13px;
  letter-spacing: 1.5px;
}
.device-status {
  margin: 5px 0;
  font-size: 14px;
}
.device-content {
  margin: 10px;
}
`
})
export class BluetoothDialog {
  constructor (
    private ble: BluetoothService,
    private measurements: MeasurementService,
    private zone: NgZone,
    private router: Router

  ) {}

  text = TEXT
  bleState: IBluetoothState
  devices: Map<string, IDeviceStatus<IBleTask<any>>>

  state: IBleDialogDisplay = {
    status: 'idle', devices: []
  }

  currentPage = ''

  private getActiveRouteComponent(route: ActivatedRoute): void {
    if (route.firstChild) {
      this.getActiveRouteComponent(route.firstChild);
    } else {
      this.currentPage = route.snapshot.url.join('/')
    }
  }

  openUrl (url: string) {
    this.router.navigateByUrl(url)
  }

  cancelOperation () {
    this.ble.cancelOperation()
  }

  ngOnInit () {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const route = this.router.routerState.root;
        this.getActiveRouteComponent(route);
      }
    });
    this.measurements.syncState.subscribe(state => {
      this.updateDisplay()
    })
    this.measurements.measurementDevices.subscribe(devices => {
      this.updateDisplay()
    })
    this.ble.state.subscribe(state => {
      this.bleState = state
      this.updateDisplay()
    })
    this.ble.deviceStatus.subscribe(status => {
      this.devices = status
      this.updateDisplay()
    })
  }

  isPageDevice (device: Device) {
    
  }

  updateDisplay () {
    let testing = false
    if (testing) {
      let testDevice: any = {
        image: deviceImage(agTypes.farmerDeviceTypes.cProBT.value),
        bleDevice: {address: '', name: 'CPRO-321321321', stored: true},
        status: 'success', 
        entity: {label: 'Label on CPRO Device', name: 'CPRO-232143243'} as any,
        numUnsyncedMeasurements: 0
      }
      this.state = {
        status: 'operating', devices: [testDevice]
      }
    } else {
      this.zone.run(() => this._updateDisplay())
    }
  }

  _updateDisplay () {
    const knownDevices = Object.values(this.measurements.measurementDevices.value)
    const pairedDevices = this.ble.devices.value.filter(x => x.stored)

    const ble = this.bleState

    const syncState = this.measurements.syncState.value
    let syncRecords = Object.values(syncState)
    let isUploading = syncRecords.find(x => x.status == 'sending')
    let hasUploadError = syncRecords.find(x => x.error != null)
    //this.state = null

    /*this.state = {
      status: 'idle', devices: [], title: ''
    }*/

    if (ble?.scanning) {
      let state: IBleDialogDisplay = {
        status: 'scanning', devices: []
      }
      let devices = ble.scanning.devices.map(x => {
        let claimedDevice = knownDevices.find(d => d.entity.name == x.name)
        let display: IDeviceDisplay = {
          image: deviceImage(agTypes.farmerDeviceTypes.cProBT.value),
          bleDevice: x, entity: claimedDevice?.entity || null,
          status: 'idle', devicePageUrl: null, lastResponse: new Date().getTime()
        }
        if (!display.bleDevice?.stored) {
          display.status = 'pairing'
          this.ble.storeDevice(x)
        }
        return display
      })
      state.devices = devices
      this.state = state

      // NOTE: since syncing is a manual operation by user, it's highly unlikely that it will find more than one device when scanning
      let pairedDevices = devices.filter(x => x.status == 'idle')
      if (pairedDevices.length > 0) {
        this.ble.stopScanning()
        pairedDevices[0].status = 'success'
        /*setTimeout(() => {
          this.updateDisplay()
        }, 3000)*/
        //this.dismiss()
      }
    } else if (this.devices != null) {
      this.devices.forEach(device => {
        let bleDevice = this.ble.devices.value.find(x => x.address == device.address)
        if (bleDevice != null && bleDevice.name) {
          let model = knownDevices.find(x => x.entity.name == bleDevice.name)
          let display: IDeviceDisplay = {
            image: deviceImage(agTypes.farmerDeviceTypes.cProBT.value),
            status: 'idle', bleDevice: bleDevice, entity: model?.entity,
            devicePageUrl: null, progress: null, lastResponse: 0
          }
          
          if (model?.entity.id.id) {
            display.devicePageUrl = 'device/' + model.entity.id.id
          }
          let isSuccess = device.task?.status == 'success' && device.status != 'reading'
          let isError = device.task?.status == 'error' && device.status != 'reading'
          
          if (isDeviceMeasuring(device)) {
            display.status = 'measuring'
            if (device.task.status == 'success') {
              let n = display.numUnsyncedMeasurements || 0
              display.numUnsyncedMeasurements = n + 1
            }
            display.lastResponse = new Date().getTime()
          } else if (isDeviceSyncing(device)) {
            display.status = 'syncing'
            let task = device.task
            let measurements = task.measurements || new Set()
            let completed = task.completed || new Set()
            //let allPending = Array.from(device.task.measurements).filter(x => !completed.has(x))
            let progress = completed.size / measurements.size

            device.task.measurements
            display.progress = progress
            if (device.task.status == 'success') {
              display.numUnsyncedMeasurements = 0
            }
            display.lastResponse = new Date().getTime()
          }

          
          
          if (isSuccess) {
            display.status = 'success'
          } else if (isError) {
            display.status = 'error'
            display.error = device.task?.error
          } 
          
          let current_display = this.state.devices.find(x => x.bleDevice?.address == device.address)
          
          if (current_display?.status == 'measuring' || current_display?.status == 'syncing') {
            if (device.status == 'disconnected') {
              display.status = 'error'
              display.error = 'device_disconnected'
            }
          }

          if (current_display?.status == 'pairing') {
            current_display.status = 'success'
          }

          //let isComplete = display.status == 'success' || display.status == 'error'
          
          // NOTE: if device-status cannot be found then keep the current status
          //       this is so the user can still see the last status until he closes the popover
          //console.log('state', {...current_display}, '->', {...display})
          if (current_display && display.status == 'idle' && current_display.status != 'idle') {
            display.status = current_display.status
            display.numUnsyncedMeasurements = current_display.numUnsyncedMeasurements
          }
          
          if (current_display) {
            Object.assign(current_display, display)
          } else { // if (display.status != 'unknown' && !isComplete) {
            this.state.devices.push(display)
          }
        }
        if (this.state.devices.length > 1) {
          this.state.devices = [this.state.devices.sort((a, b) => {
            return b.lastResponse - a.lastResponse
          })[0]]
        }
      })

      let devicesInProgress = this.state.devices.filter(x => x.status != 'idle')
      if (devicesInProgress.length > 0) {
        this.state.status = 'operating'
      } else {
        this.state.status = 'idle'
      }
    } else {
      this.state.status = 'idle'
    }

    /*if (this.state.status == 'idle' && isUploading) {
      this.state.status = 'uploading'
    }*/

    this.state = {...this.state}
  }

  async claimDevice (deviceName: string) {
    await this.dismiss()
  }

  async dismiss () {
    await this.ble.stopScanning()
    this.state = {
      status: 'idle', devices: []
    }
  }

}


@Component({
  selector: 'superpro-overview',
  template: `
<ion-content id="superpro-content">
    <ion-header>
        <ion-toolbar>
            <ion-buttons slot="start">
              <ion-back-button defaultHref="/"></ion-back-button>
            </ion-buttons>
            <ion-title mode="md">{{title}}</ion-title>
            <ion-buttons slot="end">
                <!--<ion-button *ngIf="isBluetoothDevice" (click)="showBluetoothStatus()">
                    <ion-icon [style.color]="bleState.statusColor" name="bluetooth-outline"></ion-icon>
                </ion-button>-->
                <ion-button (click)="openTimeWindowMenu($event)">
                  <ion-icon slot="icon-only" name="time-outline" ></ion-icon>
                </ion-button>
                <ion-button (click)="showMeasurementMap()">
                    <ion-icon name="map-sharp"></ion-icon>
                </ion-button>
                <ion-button (click)="showSettings()">
                    <ion-icon name="settings-outline"></ion-icon>
                </ion-button>
            </ion-buttons>
          </ion-toolbar>
    </ion-header>
    <ag-loading [loading]="loading" message="{{text.general.loading | translate}}" (retry)="resubscribe()"></ag-loading>
    <div *ngIf="isBluetoothDevice && isLoaded" class='bluetooth'>
      <!--<div class='ag-card' *ngIf="bleState.showDeviceStatus">
        <ion-label>{{bleState.deviceStatus}}</ion-label>
      </div>-->
      <div class='flex flex-row ag-card' *ngIf="bleState.notConnected">
        <ion-label class="flex device-status">{{bleState.deviceStatus}}</ion-label>
        <ion-button (click)="forceReconnect()">Connect</ion-button>
        <!--<ion-button disabled="true" *ngIf="!bleState.canForceConnect">{{bleState.deviceStatus}}</ion-button>-->
      </div>
      <div *ngIf="isLoaded && bleState.issue && pageMode != 'PAIR'" class='flex flex-row ag-card' style="padding: 15px">
        <div class="flex flex-start-center" [translate]="bleState.issue.title"></div>
        <!--<div *ngIf="bleState.issue.helpText" style="font-size: 14px;" [translate]="bleState.issue.helpText"></div>-->
        <div class="small-button" *ngIf="bleState.issue.resolve" (click)="bleState.issue.resolve()" [translate]="bleState.issue.buttonText">
          {{bleState.issue.buttonText | translate}}
        </div>
      </div>
    
    </div>
    
    <div class="measurement-list">
        <div [class.not_synced]="isSynced(measurement)" style="position: relative !important" (click)="openAddMeasurementDialog(measurement)" class="flex-column measurement-card ag-card flex-auto" *ngFor="let measurement of measurements" >
            <div class="flex flex-column flex-auto" > 
                <div class="flex-row flex-start-start moisture-row flex-auto">
                  <div class="flex-row moisture-row flex-auto">
                        <ion-icon class="icon" [ngStyle]="{fill: colors.minColor}" src="/assets/svg/iconset/ag-moisture.svg" ></ion-icon>  
                        <span class="moisture-value" >{{measurement.moisture_content | number:'1.1-1'}}%</span>
                    </div>
                    <div class="flex-row moisture-row flex-auto" style="margin-left: 10px" *ngIf="measurement.temperature != null">
                        <ion-icon class="icon" [ngStyle]="{fill: colors.maxColor}" src="/assets/svg/iconset/ag-thermometer.svg" ></ion-icon>  
                        <span class="moisture-value" >{{measurement.temperature | number:'1.1-1'}}°</span>
                    </div>
                </div>
                <div class="flex flex-row flex-auto" >
                    <div *ngIf="deviceHasCrop()" class="moisture-crop">{{measurement.crop_name | cropLabel | async}}</div>
                    
                    <div class="flex flex-column flex-end-end">
                        <div class="measurement-date">{{measurement.timestamp | sinceNow}}</div>
                    </div>
                </div>
            </div>
            <div style="position: absolute; right: 5px; top: 5px">
              <ion-icon *ngIf="measurement.ble_id" name="bluetooth-outline"></ion-icon>
              <ion-icon *ngIf="measurement.latitude && measurement.longitude" name="navigate-circle-outline"></ion-icon>
            </div>
        </div>
    </div>
    <div *ngIf="pageMode == 'PAIR'" style="pointer-events: none; display: flex; flex-direction: column; position: fixed; width: 100%; height: 100%; place-content: center;" slot="fixed" vertical="center" horizontal="center"  >
      <div class="pair-button" (click)="pairDevice(this.device.name)" [translate]="text.ble.pair_device"></div>
    </div>
    <ion-fab *ngIf="pageMode != 'CREATE'" vertical="bottom" horizontal="end"  slot="fixed" (click)="openAddMeasurementDialog()">
        <ion-fab-button color="primary">
            <ion-icon name="add"></ion-icon>
        </ion-fab-button>
    </ion-fab>
    <ion-fab *ngIf="pageMode == 'CREATE'" style="display: flex; flex-direction: column;" vertical="center" horizontal="center" slot="fixed" (click)="openAddMeasurementDialog()">
        <!--style="transform: translate(50%, 0%);" -->
        <ion-fab-button style="align-self: center;" color="primary">
            <ion-icon name="add"></ion-icon>
        </ion-fab-button>
        <!-- transform: translate(-15%, 0);-->
        <ion-button color="primary" style="text-transform: uppercase;" [translate]="text.general.add_measurement"></ion-button>
    </ion-fab>
</ion-content>

  `,
  styleUrls: ['super-pro.scss'],
  styles: `
  .pair-button {
    border-radius: 10px;
    font-size: 18px;
    align-self: center;
    color: white;
    text-align: center;
    background: rgba(237, 109, 5);
    text-transform: uppercase;
    padding: 8px 50px;
    pointer-events: fill;
    z-index: 9999;
  }
  `
})
export class SuperProOverviewComponent { 

  @Input() device: Device

  text = TEXT
  title: string
  _subscriptions: Subscription[] = []
  listen (s: Subscription) { this._subscriptions.push(s) }
  ngOnDestroy () { 
    this.subscription?.unsubscribe()
    this._subscriptions.map(s => s.unsubscribe()); this._subscriptions = [] 
  }
  
  constructor (
    private modalController: ModalController, 
    private route: Router,
    private measurementStore: MeasurementService,
    private agModal: AgModal,
    private popoverController: PopoverController,
    private ble: BluetoothService,
    private permissionService: PermissionService
  ) {}

  colors = agTypes.colors
  loading = new LoadingState()
  measurements: SAMoistureMeasurement[] = [];
  measurementsSubscriptionOptions
  cropNames
  latestData
  subscription: TimeseriesSubsciption
  isBluetoothDevice = false
  pageMode: 'LIST' | 'CREATE' | 'PAIR' = 'LIST'

  forceReconnect () {
    this.ble.forceReset()
  }

  async openTimeWindowMenu (event) {
    let popover = await this.popoverController.create({
      component: TimeQuerySettings,
      event: event,
      translucent: true
    });
    return popover.present();
  }

  deviceHasCrop () {
    return this.device.type != agTypes.farmerDeviceTypes.superproCombi.value
  }

  getDeviceStatusColor () {
    let status = this.bleState.deviceStatus
    //console.log('get status color', status, this.bleState?.bleReady)
    if (!this.bleState?.bleReady) {
      return this.colors.errorColor
    }
    if (status == 'ready' || status == 'connecting' || status == 'connected') {
      //return this.colors.disableColor
      return this.colors.minColor
    } else if (status == 'reading' || status == 'sending') {
      return this.colors.normalColor
    } else {
      //return this.colors.errorColor
      return this.colors.disableColor
    }
  }

  showBluetoothStatus () {

  }

  showMeasurementMap () {
    this.route.navigateByUrl('/device/' + this.device.id.id + '/map')
  }
  async showSettings () {
    let device = await firstValueFrom(this.measurementStore.getMeasurementDevice(this.device.id))
    let settings = clone(device.settings)
    if (!settings.label) {
      settings.label = this.device.label
    }

    const dialogRef = await this.modalController.create({
      component: SuperProSettingsComponent,
      componentProps: {settings: settings, entity: this.device}
    });
    const result = await this.agModal.openModal(dialogRef)
  }
  resubscribe () {
    // TODO: ..
  }
  /*async resubscribe () {
    if (this.subscription) this.subscription.unsubscribe()
    this.loading.loading(true)
    
    

    this.subscription = this.measurementStore.subscribe(
      {entityId: this.device.id}, 
      (measurements) => {
        this.measurements = measurements
        this.hasContent = measurements.length > 0
        this.loading.success()
      },
      (isLoading) => this.loading.loading(isLoading)
    )
  }*/
  
  isLoaded = false
  hasContent = false
  //status: IDeviceStatus<any> | null
  //state: IBluetoothState
  permissions: Record<string, IPermissionState>
  
  bleDevice: IDevice | null = null // TODO: rename to IBleDevice
  bleState: IBleDisplayState = {
    issue: null, deviceStatus: null, bleReady: false, statusColor: agTypes.colors.disableColor,
    notConnected: false, canForceConnect: false, timestamp: null
  }
  notSyncedIds: Set<string> = new Set()
  isSynced (measure: SAMoistureMeasurement) {
    return this.notSyncedIds.has(measure.ble_id)
  }

  gpsRequired = false
  async ngOnInit () {
    this.gpsRequired = await this.ble.isOldAndroid()
    this.isBluetoothDevice = [
      agTypes.farmerDeviceTypes.cPro.value,
      agTypes.farmerDeviceTypes.cProBT.value
    ].includes(this.device.type)
    this.cropNames = [];
    for (let property in agTypes.farmerCropNames) {
        this.cropNames.push({
            nameId: property,
            value: agTypes.farmerCropNames[property]
        });
    }
    this.loading.events.subscribe(x => {
      this.isLoaded = x.is_success()
      this.updatePage()
    })

    this.permissionService.state.subscribe(x => {
      this.permissions = x
      this.updatePage()
    })
    this.ble.state.subscribe(x => {
      this.updatePage()
    })
    this.ble.devices.subscribe(bleDevices => {
      this.updatePage()
    })
    this.ble.deviceStatus.subscribe(status => {
      this.updatePage()
    })
    this.measurementStore.syncState.subscribe(syncState => {
      let notSynced = new Set<string>()
      for (var k in syncState) {
        let state = syncState[k]
        if (state.error != null || state.status != 'complete') { // || (state.status != 'complete' && state.measurement.ble_id)) {
          if (state.measurement.ble_id) {
            notSynced.add(state.measurement.ble_id)
          }
        }
      }
      this.notSyncedIds = notSynced
    })

    this.listen(
      this.measurementStore.getMeasurementDevice(this.device.id).subscribe(ctrl => {
        //ctrl.load()
        /*ctrl.model.onUpdate.subscribe(x => {
          this.title = ctrl.entity.label || ctrl.entity.name
        })*/
        this.title = ctrl.model.settings.label || ctrl.entity.label || ctrl.entity.name
        
        this.listen(
          ctrl.model.load().subscribe(() => {
            this.title = ctrl.settings.label || ctrl.entity.name
          })
        )
        this.listen(
          ctrl.latestMeasurements().subscribe(measures => {
            this.measurements = measures
            this.hasContent = measures.length > 0
            this.updatePage()
          })
        )
        this.listen(
          ctrl.loading.events.subscribe(x => {
            this.loading.loading(x.is_loading())
          })
        )
        
      })
    )
    this.updatePage()
    //this.resubscribe()

    
  }

  updatePage () {
    if (this.isBluetoothDevice) {
      this.updateBluetoothState()
      
      if (this.isLoaded && this.bleDevice?.stored && !this.hasContent) {
        this.pageMode = 'CREATE'
      } else if (this.isLoaded && !this.hasContent && this.bleState.issue?.type == 'PAIR') {
        this.pageMode = 'PAIR'
      } else {
        this.pageMode = 'LIST'
      }
    } else {
      if (this.isLoaded && !this.hasContent) {
        this.pageMode = 'CREATE'
      } else {
        this.pageMode = 'LIST'
      }
    }
  }

  async enableBluetooth () {
    await this.ble.ensureBluetoothEnabled()
  }

  updateBluetoothState () {
    if (!this.isBluetoothDevice) return
    let newState: IBleDisplayState = {
      deviceStatus: null, issue: null, bleReady: false, statusColor: this.getDeviceStatusColor(),
      notConnected: false, canForceConnect: false, timestamp: null
    }
    this.bleDevice = this.ble.devices.value.find(x => x.name == this.device.name) || null
    
    //console.log('state', this.bleDevice?.address, this.ble.deviceStatus.value)
    this.ble.deviceStatus.value.forEach(x => {
      if (x.address == this.bleDevice?.address) {
        newState.deviceStatus = x.status
        newState.timestamp = x.timestamp
      }
    })

    let state = this.ble.state.value

    let permissions = this.permissionService.state.value
    
    if (!state.isReady) return
    let isPaired = this.bleDevice?.stored && this.bleDevice.address != null

    newState.bleReady = state.isInitialized && isPaired
    newState.statusColor = this.getDeviceStatusColor()    

    // first check that we have bluetooth access
    if (!permissions.BLUETOOTH.enabled) {
      newState.issue = {
        title: this.text.ble.not_allowed_title,
        helpText: this.text.ble.not_allowed_help,
        severity: 'error',
        buttonText: this.text.ble.allow,
        resolve: () => this.permissionService.requestPermission('BLUETOOTH', true)
      }
    } else if (state.isEnabled == false) {
      newState.issue = {
        title: this.text.ble.not_available_title,
        helpText: this.text.ble.not_available_help,
        severity: 'error',
        buttonText: 'Enable',
        resolve: () => this.enableBluetooth()
      }
    } else if (!isPaired) {
      if (this.gpsRequired && !permissions.GPS.enabled) {
        newState.issue = {
          title: this.text.ble.gps_not_allowed_title,
          helpText: this.text.ble.gps_not_allowed_help,
          severity: 'error',
          buttonText: this.text.ble.allow,
          resolve: () => this.permissionService.requestPermission('GPS', true)
        }
      } else {
        newState.issue = {
          type: 'PAIR',
          title: this.text.ble.not_paired_title,
          helpText: '', //this.text.ble.not_paired_help,
          buttonText: this.text.ble.pair_device,
          severity: 'error',
          resolve: () => this.pairDevice(this.device.name)
        }
      }
    } else if (!permissions.GPS.enabled) {
      newState.issue = {
        title: this.text.ble.gps_not_allowed_title,
        helpText: this.text.ble.gps_not_allowed_help,
        severity: 'error',
        buttonText: this.text.ble.allow,
        resolve: () => this.permissionService.requestPermission('GPS', true)
      }
    } else if (!permissions.GPS_BACKGROUND.enabled) {
      newState.issue = {
        title: this.text.ble.gps_bg_not_allowed_title,
        helpText: this.text.ble.gps_bg_not_allowed_help,
        severity: 'warn',
        buttonText: this.text.ble.allow,
        resolve: () => this.permissionService.requestPermission('GPS_BACKGROUND', true)
      }
    } else if (!permissions.BATTERY_OPTIMIZED.enabled) {
      newState.issue = {
        title: this.text.ble.battery_optimized_title,
        helpText: this.text.ble.battery_optimized_help,
        severity: 'warn',
        buttonText: this.text.ble.disable_battery_optimization,
        resolve: () => this.permissionService.requestPermission('BATTERY_OPTIMIZED', true)
      }
    }

    let aminute = 1000 * 10
    if (!newState.timestamp || (new Date().getTime() - newState.timestamp.getTime()) > aminute) {
      let connectedStatus: DeviceStatus[] = [
        "connected", "ready", "reading", "sending"
      ]
      newState.notConnected = this.ble.isReady() && isPaired && !connectedStatus.includes(newState.deviceStatus)
    }  
    this.bleState = newState
  }

  async pairDevice (deviceSerial: string) {
    await this.ble.startScanning(deviceSerial)
  }

  async openAddMeasurementDialog (measurement?: SAMoistureMeasurement) {
    const dialogRef = await this.modalController.create({
      component: AddMeasurementDialogComponent,
      componentProps: {entity: this.device, measurement: measurement}
    });
    // NOTE: for some reason, the measurement subscribtions will not always react to changes
    //       need to manually update the measurement value:
    const { data } = await this.agModal.openModal(dialogRef)
    if (data) {
      this.measurements = this.measurements.map(m => {
        if (m.timestamp == data.ts) return data
        return m
      })
      //this.resubscribe()
    }
  }

  isCustomer () {
    return true
  }
  getCropName (cropNameId) {
    if (this.cropNames && this.cropNames.length) {
      for (let i = 0; i < this.cropNames.length; i++) {
        if (this.cropNames[i].nameId === cropNameId) {
          return this.cropNames[i].value;
        }
      }
    }
    return "-";
  }
}
