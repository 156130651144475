import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';

import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { GlobalHttpInterceptor, RequestInterceptor } from './services/interceptors';
import { CommonModule } from '@angular/common';

import { AppRoutingModule, AuthGuard, NoAuthGuard } from './app-routing.module';
import { AdminPage, AppComponent, AppService, CustomErrorsHandler, SessionService } from './app.component';

import { TelemetryService, TelemetryWebsocketService } from './services/telemetry';
import { UnitService } from './services/unit';
import { reducers, metaReducers } from './state';
import { AGUserService } from './services/user.services';
import { AuthService } from './services/auth.service';
import { ConnectionService, FarmerService } from './services/device.service'
 
import { LeafletModule } from '@asymmetrik/ngx-leaflet';


import { 
  FarmerDevicesComponent, FarmerDeviceDetailComponent, PaymentCardOverlay, EntityGrid
} from './components/farmer-devices/farmer-devices';

import { AgPlotComponent, AgPlotTooltip, AgTooltipService, FlotDirective } from './components/ag-plot/ag-plot';
import { SpearOverviewComponent, SpearPageComponent, SpearSettingsComponent } from './components/cellular-spear/spear-overview';
import { DevicesMap, AgrologMap, DevicesMapPage, MeasurementMap } from './components/device-map/devices-map';
import { AddMeasurementDialogComponent, MeasurementMapComponent, SuperProSettingsComponent } from './components/super-pro/super-pro';
import { FormsModule } from '@angular/forms';
import { Store, StoreModule } from '@ngrx/store';
import { ToastService } from './services/toast.service';
import { LocalStorageService } from './services/local-storage.service';
import { MainMenu, MenuService, ProfileMenu, TimeQuerySettings } from './menus';

import { AttributeService, EntityService } from './services/entity.service';
import { LoginFormComponent } from './components/login-page/login-page';

import { AlarmPageComponent, EntityAlarmGroup } from './components/alarm-page/alarm-page';
import { TestPlot } from './components/test-telemetry/test-telemetry';
import { LocationService } from './services/location.service';

import { AlarmService } from './services/alarm.service';
import { AgConnectionDialog, AgLoadingComponent, AgSensorValue, AgTelemetryValue, AgTimeSince, AssetService, BatteryLevelComponent, CountrySelect, CropLabelPipe, FormatTimePipe, MapMarker, QrCodeGenerator, ReversePipe, SignalStrengthComponent, SinceNowPipe, SortLocale, TestComponents } from './components/base-components';
import { BillingPage } from './components/login-page/billing';
import { CheckoutPopover, InvoiceEstimate, PaymentService, PaymentSubscriptionForm, PaymentSuccessDialog } from './services/payment.service';

import { ProfilePageComponent, ProfileService } from './components/login-page/profile-page';
import { BuildingSensorMap, DeviceSelector, FarmerBuildingDevicesPage, FarmerBuildingPage, FarmerBuildingSettings } from './components/farmer-building/farmer-building';
import { BuildingDeviceItem, BuildingListPage, EditBuildingMapDialog, FarmerBuildingItem } from './components/farmer-building/farmer-building-list';

import { SwPush } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { MessagingService } from './services/messaging.service';

import { FarmerBuildingHistory, FarmerBuildingHistoryPage, FarmerBuildingSpearTable } from './components/farmer-building/farmer-building-history';
import { QRScanDirective } from './components/farmer-devices/farmer-device-scanner';
import { AddDeviceDialogComponent, CameraService, FarmerDeviceConfig } from './components/farmer-devices/farmer-create-device';
import { FarmerTypes } from './services/types.service';
import { FarmerMeasurementItem } from './components/super-pro/super-pro.item';
import { FarmerCellularSpearItem } from './components/cellular-spear/spear-item';
import { DeviceChart } from './components/farmer-devices/farmer-device-chart';

import {MissingTranslationHandler, MissingTranslationHandlerParams, TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import { initializeApp } from 'firebase/app'
import { UpgradeNotification, UpgradeService } from './services/upgrade.service';
import { CustomerActivateComponent, ResetPasswordComponent } from './components/login-page/activate-user';
import { AgModal } from './util';
import { getAnalytics } from "firebase/analytics";

import { TypeaheadComponent } from './components/basic/typeahead';

import { tap, filter, map } from 'rxjs/operators'
import { DeleteAccountPageComponent } from './components/login-page/delete-account-page';
import { CropCallibrationComponent } from './components/login-page/callibration';

import { DecimalPipe } from '@angular/common';
import { DEFAULT_TRANSLATIONS, TEXT } from './texts';
import { TestBuildingTelemetry } from './components/testing/test-building-telemetry';
import { TestBluetooth } from './components/testing/test-bluetooth';
import { LocationAccuracy } from '@awesome-cordova-plugins/location-accuracy/ngx';

import { LoadingService } from './services/loading.service';
import { BackgroundGeolocation } from '@awesome-cordova-plugins/background-geolocation/ngx';
import { BluetoothLE } from '@awesome-cordova-plugins/bluetooth-le/ngx';
import { PermissionErrorMessage, PermissionService } from './services/permissions.service';
import { SQLite } from '@awesome-cordova-plugins/sqlite/ngx';
import { MeasurementService } from './services/measurement.service';
import { BluetoothDialog, SuperProOverviewComponent } from './components/super-pro/super-pro-page';

import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { PhoneService } from './services/phone.service';

const firebaseConfig = {
  apiKey: "AIzaSyDdudhxeo_LOpU3VWLvdQRI4O2bqH5cSig",
  authDomain: "agrologfarmer.firebaseapp.com",
  projectId: "agrologfarmer",
  storageBucket: "agrologfarmer.appspot.com",
  messagingSenderId: "75365923035",
  appId: "1:75365923035:web:3b93927fcbe0684439b4fe",
  measurementId: "G-FK8KS2YNNN", 
  databaseURL: "https://agrologfarmer-default-rtdb.europe-west1.firebasedatabase.app",
};
function initializeFirebase () {
  const app = initializeApp(firebaseConfig)
  if (environment.analytics) {
    const analytics = getAnalytics(app);
  }
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "/assets/i18n/", ".json");
}


class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    return DEFAULT_TRANSLATIONS[params.key] || params.key
  }
}

export class CustomLoader implements TranslateLoader {
  constructor(private session: SessionService) {}

  getTranslation(lang: string): any {
    return this.session.messages.pipe(
      tap(x => console.error(' customer load messager', x)),
      filter(x => x.translations != null),
      map(x => x.translations)
    )
  }
}

@NgModule({
  declarations: [
    TestBuildingTelemetry, TestBluetooth,

    MeasurementMap, BluetoothDialog,

    AppComponent, AgLoadingComponent, AgSensorValue, BillingPage,
    QrCodeGenerator, AgConnectionDialog, ResetPasswordComponent, 
    PermissionErrorMessage, UpgradeNotification, CheckoutPopover,
    PaymentCardOverlay, EntityGrid, InvoiceEstimate, EntityAlarmGroup,
    BuildingDeviceItem, EditBuildingMapDialog, AdminPage,
    SinceNowPipe, CropLabelPipe, AgTimeSince, AgTelemetryValue, SortLocale,
    FarmerMeasurementItem, FarmerCellularSpearItem, PaymentSuccessDialog,
    FarmerDevicesComponent, FarmerDeviceDetailComponent, DeviceChart,
    AddMeasurementDialogComponent, MeasurementMapComponent,
    AddDeviceDialogComponent, TestPlot, LoginFormComponent,
    AgPlotComponent, FlotDirective, SpearOverviewComponent, SpearPageComponent,
    SpearSettingsComponent, AlarmPageComponent,
    DevicesMap, AgrologMap, SuperProOverviewComponent,
    SuperProSettingsComponent, AgPlotTooltip, CustomerActivateComponent,
    ProfilePageComponent, TestComponents, MapMarker,
    LoginFormComponent, TimeQuerySettings, MainMenu, ProfileMenu, DevicesMapPage,
    QRScanDirective, BatteryLevelComponent, SignalStrengthComponent,
    FarmerBuildingPage, BuildingSensorMap, DeviceSelector, BuildingListPage,
    FarmerBuildingItem, FarmerBuildingSettings, FarmerBuildingDevicesPage,
    ReversePipe, FarmerDeviceConfig, 
    FarmerBuildingHistoryPage, FarmerBuildingHistory, FarmerBuildingSpearTable,
    PaymentSubscriptionForm, FormatTimePipe,
    TypeaheadComponent, CountrySelect, DeleteAccountPageComponent,
    CropCallibrationComponent
  ],
  //entryComponents: [],
  imports: [
    BrowserModule, IonicModule.forRoot(), AppRoutingModule,
    CommonModule, HttpClientModule, // FlexLayoutModule,
    LeafletModule, FormsModule, 

    IonicStorageModule.forRoot(),
    //RecaptchaFormsModule, RecaptchaModule, 
    
    StoreModule.forRoot(reducers,
      { metaReducers,
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
          strictStateSerializability: true,
          strictActionSerializability: true
        }}
    ),

    TranslateModule.forRoot({
      missingTranslationHandler: {provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler},
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
      /*loader: {
        provide: TranslateLoader,
        useFactory: (service: SessionService) => new CustomLoader(service),
        deps: [SessionService],
      }*/
    })

    //ServiceWorkerModule.register(SERVICE_WORKER_FILE, { enabled: environment.production })
  ],
  providers: [
    // bluetooth
    LocationAccuracy, BackgroundGeolocation, BluetoothLE,
    LoadingService,
    Diagnostic,
    PhoneService,

    SQLite, MeasurementService,

    {
      provide: APP_INITIALIZER,
      useFactory: () => initializeFirebase,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: CustomErrorsHandler,
    },
    // StatusBar, SplashScreen,AndroidPermissions,Camera,UserAgent, Deeplinks,OpenNativeSettings,
    SessionService, DecimalPipe, ProfileService,
    PermissionService, CameraService,
    AuthGuard, NoAuthGuard,  AgModal,   //FCM,
    ConnectionService, //LocalNotifications,
    FarmerTypes, AssetService, //DeeplinksOriginal, 
    SwPush, MessagingService, 
    AppService,
    
    Title, AgTooltipService,
    
    PaymentService, 
    Geolocation, 
    LocalStorageService, //BarcodeScanner,
    TelemetryService, 
    UnitService, AuthService, ToastService, AttributeService,
    LocationService, EntityService,
    AGUserService, FarmerService, TelemetryWebsocketService, UnitService,
    MenuService, AlarmService, UpgradeService,
    Store, 
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    //{ provide: MyErrorHandler, useValue: createErrorHandler() },
    { provide: HTTP_INTERCEPTORS, useClass: GlobalHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    /*{
      provide: RECAPTCHA_BASE_URL,
      useValue: 'https://recaptcha.net/recaptcha/api.js'
    }*/
  ],
  bootstrap: [AppComponent]
})

export class AppModule {}
