import { Component, ElementRef, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import { FarmerService, ISignupParams } from "src/app/services/device.service";
import { AlertController, Platform } from "@ionic/angular";
import { isIOS, LoadingState } from "src/app/util";
import { LoginRequest } from "src/app/models/auth.model";
import { UpgradeService } from "src/app/services/upgrade.service";
import { MenuService } from "src/app/menus";
import { captureMessage } from "@sentry/angular";
import { TranslateService } from "@ngx-translate/core";
import { TEXT } from "src/app/texts";
import { AppService } from "src/app/app.component";
import { LANGUAGES } from "src/app/constant";

// Captcha v3
const SITE_KEY = '6Ldx_dEiAAAAAFC8fHFtkH9F_TS1TIwgg0VWTOhA'

declare var grecaptcha: any

interface IFormError {
  field: string, message: string, error?: boolean, params?: any
}

interface SignUpSelfRegistrationParams {
  signUpTextMessage?: string;
  captchaSiteKey?: string;
  activate?: boolean;
}

type SIGN_IN_MODE = 'LOGIN' | 'SIGNUP' | 'ACTIVATE'


@Component({
  selector: 'login-form',
  templateUrl: 'login-page.html',
  styleUrls: ['login-page.scss']
})
export class LoginFormComponent {

  @ViewChild('recaptcha') recaptcha: ElementRef<HTMLDivElement>

  passwordVisible = false

  loginForm: LoginRequest;
  signupForm: ISignupParams = {
    firstName: '', lastName: '', email: '', password: '', recaptchaResponse: '', tos: false
  };
  signUpParams: SignUpSelfRegistrationParams = {
    captchaSiteKey: SITE_KEY 
  };
  isFocus = false;
  mode: SIGN_IN_MODE = 'LOGIN';
  loading = new LoadingState()
  captchaLoaded = false
  text = TEXT

  constructor(
    private menu: MenuService,
    public upgrade: UpgradeService, // TODO: do not let template relie on public service
    private farmer: FarmerService,
    private auth: AuthService,
    private alertController: AlertController,
    private platform: Platform,
    private translate: TranslateService,
    private appService: AppService
  ) {}
  
  languages = LANGUAGES
  settings: {locale: string | null} = {locale: null}

  async updateLocale () {
    this.appService.setLocale(this.settings.locale)
  }

  recaptchaResolved (event) {
  }

  toggleTos () {
    this.signupForm.tos = !this.signupForm.tos
  }

  reload () {
    window.location.reload()
  }

  openHelp () {
    this.menu.openHelp()
  }

  setPasswordVisible (evt: MouseEvent, v: boolean) {
    evt.stopPropagation()
    evt.preventDefault()
    this.passwordVisible = v
    return false
  }

  submitCaptcha () {}

  async forgotPassword () {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: this.translate.instant(this.text.profile.forgot_password),
      message: this.translate.instant(this.text.profile.forgot_password_help),
      inputs: [
        {
          name: 'email',
          type: 'email',
          value: '',
          placeholder: this.translate.instant(this.text.general.email)
        }
      ],
      buttons: [
        {
          text: this.translate.instant(this.text.general.cancel),
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        }, {
          text: this.translate.instant(this.text.general.ok),
          handler: async (data: {email?: string}) => {
            let email = data.email?.toLowerCase()
            if (email) {
              try {
                await this.auth.sendResetPasswordLink(email).toPromise()
                let alertComplete = await this.alertController.create({
                  message: this.translate.instant(this.text.profile.forgot_password_success),
                  buttons: [
                    {
                      text: this.translate.instant(this.text.general.ok),
                      role: 'cancel'
                    }
                  ]
                });
                await alertComplete.present();
              } catch (err) {
                let message = this.translate.instant(this.text.profile.forgot_password_failed)
                // TODO: server messages are not translated
                if (err && err.error && err.error.message) {
                  message += ': ' + err.error.message
                }
                let alertComplete = await this.alertController.create({
                  message: message,
                  buttons: [
                    {
                      text: this.translate.instant(this.text.general.ok),
                      role: 'cancel'
                    }
                  ]
                });
                await alertComplete.present();
              }
            }
          }
        }
      ]
    });

    await alert.present();
  }
  
  isSignUp() {
    return true;
  }

  canLogin () {
    return this.loading.is_success() && this.loginForm.username && this.loginForm.password
  }
  canSignUp () {
    let errors = this.signupErrors()
    return this.loading.is_success() && errors.length == 0
  }
  signupErrors (): IFormError[] {
    const MIN_PASSWORD_LENGTH = 7
    let f = this.signupForm
    let result: IFormError[] = []
    if (!f.email) {
      result.push({field: 'email', message: this.text.profile.email_required})
    }
    if (!f.password || f.password.length < MIN_PASSWORD_LENGTH) {
      result.push({field: 'password', message: this.text.profile.password_too_short, params: {length: MIN_PASSWORD_LENGTH}})
    }
    if (!f.firstName) {
      result.push({field: 'firstName', message: this.text.profile.first_name_required})
    }
    if (!f.lastName) {
      result.push({field: 'lastName', message: this.text.profile.last_name_required})
    }
    if (!f.tos) {
      result.push({field: 'tos', message: this.text.profile.tos_required})
    }
    return result
  }

  switchMode (mode: SIGN_IN_MODE) {
    this.mode = mode
    if (mode == 'SIGNUP') {
      this.signupForm.email = this.loginForm.username
    }
    document.querySelector('ion-content').scrollToTop();
  }
  ngOnInit () {
    this.loginForm = {
      username: '', password: ''
    };
    this.signupForm = {
      firstName: '', lastName: '', email: '', password: '', recaptchaResponse: '', tos: false
    }
    this.settings.locale = this.appService.locale
    this.farmer.fetchSignupParams().then(params => {
      this.signUpParams = params;
      this.signUpParams.activate = this.signUpParams.captchaSiteKey !== null;
    });
    this.loading.success()
  }

  captureMessage = ''
  ngAfterViewInit () {
    grecaptcha.ready(() => {
      this.captureMessage = 'ready, loading..'
      this.initializeCaptcha()
    })
    this.ensureCaptchaLoaded() // HACK, not sure if works
  }
  ensureCaptchaLoaded () {
    setTimeout(() => {
      if (!this.recaptchaId || !this.captchaLoaded) {
        this.initializeCaptcha()
        this.ensureCaptchaLoaded()
      }
    }, 2000)
  }
  recaptchaId
  initializeCaptcha () {
    if (this.captchaLoaded || this.recaptchaId) return
    if (!this.recaptcha?.nativeElement || !grecaptcha) {
      console.warn('no captcha element', grecaptcha)
      window.setTimeout(() => this.initializeCaptcha, 1000)
    } else {
      this.recaptchaId = grecaptcha.render(this.recaptcha.nativeElement, { 
        'sitekey': SITE_KEY,
        'callback': this.onRecaptchaCallback,
        'size': "invisible"
      });
      this.captchaLoaded = true
    }
  }
  onRecaptchaCallback (token: string) {}
  resetRecaptcha () {
    this.signupForm.recaptchaResponse = ''
    grecaptcha.reset(this.recaptchaId)
  }

  onFocus() {
    if (!isIOS(this.platform)) {
      this.isFocus = true;
    }
  }
  onFocusOut() {
    this.isFocus = false;
  }

  async login () {
    this.loading.loading(true)
    try {
      await this.auth.login(this.loginForm).toPromise()
      this.loginNote = ''
      this.loginForm = {username: '', password: ''}
    } catch (err) {
      let message = this.translate.instant(this.text.profile.login_failed)
      let buttons: any[] = [
        {
          text: this.translate.instant(this.text.general.ok), role: 'cancel'
        }
      ]
      // TODO: server messages are not translated
      if (err && err.error && err.error.message) {
        message += ': ' + err.error.message + '.'
      }
      // "Invalid username or password" - invalid username
      // "User account is not active" - not active
      if (err.error?.message == "User account is not active") { //err.error?.errorCode == 10) {
        message += this.translate.instant(this.text.profile.account_not_activated_help)
        buttons.push({
          text: this.translate.instant(this.text.profile.resend_activation_link),
          handler: async (data) => {
            try {
              await this.farmer.sendActivationLink(this.loginForm.username)
              let alertComplete = await this.alertController.create({ 
                message: this.translate.instant(this.text.profile.send_activation_link_success, {email: this.loginForm.username}),
                buttons: [
                  {
                    text: this.translate.instant(this.text.general.ok),
                    role: 'cancel'
                  }
                ]
              });
              await alertComplete.present();
            } catch (err) {
              let message = this.translate.instant(this.text.profile.send_activation_link_failed)
              if (err && err.error && err.error.message) {
                message += ': ' + err.error.message
              }
              let alertComplete = await this.alertController.create({
                message: message,
                buttons: [
                  {
                    text: this.translate.instant(this.text.general.ok),
                    role: 'cancel'
                  }
                ]
              });
              await alertComplete.present();
            }
          }
        })
      }
      let alertComplete = await this.alertController.create({
        message: message,
        buttons: buttons
      });
      let result = await alertComplete.present();
    } finally {
      this.loading.success()
      //this.recaptchaComponent.reset();
    }
  }


  async signup () {
    
    try {
      this.loading.loading(true)
      if (!this.signupForm.recaptchaResponse) {
        let token = await grecaptcha.execute()
        this.signupForm.recaptchaResponse = token
      }
      //this.signupForm.password = randomPassword()
      let resp = await this.farmer.signup(this.signupForm)
      this.loginForm.username = this.signupForm.email.trim().toLowerCase()
      this.signupForm = {
        firstName: '', lastName: '', email: '', password: '', recaptchaResponse: '', tos: false
      }
      //this.loading.success()
      this.alertActivate()
    } catch (err) {
      captureMessage('user failed to signup', {extra: {'error': err, 'signup-email': this.signupForm.email}, level: 'info'})      
    } finally {
      this.resetRecaptcha()
      this.loading.success()
    }
  }

  loginNote = ''
  async alertActivate () {
    this.loginNote = this.translate.instant(this.text.profile.account_not_activated_help)
    let activatePrompt = await this.alertController.create({
      cssClass: 'bold-alert',
      header: this.translate.instant(this.text.profile.activate_your_account),
      message: this.translate.instant(this.text.profile.account_not_activated_help),
      buttons: [
        {
          text: this.translate.instant(this.text.general.ok),
          handler: () => {
            this.switchMode('LOGIN')
          }
        }
      ]
    });

    await activatePrompt.present();
  }
}


